import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import { Link as MaterialLink } from "@material-ui/core";
import MenuCustom from "./MenuCustom";

import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import CodeIcon from '@material-ui/icons/Code';
import TimelineIcon from '@material-ui/icons/Timeline';
import SchoolIcon from '@material-ui/icons/School';
import GamesIcon from '@material-ui/icons/Games';
import PetsIcon from '@material-ui/icons/Pets';
import FlightIcon from '@material-ui/icons/Flight';

import { AuthContext } from "../context/auth";

import UserProfile from "../components/modals/UserProfile";
import AccessListModal from "../components/modals/AccessListModal";

import { DRONE_RC_ITEMS } from "./store/drone_rc_store";

//const logo = require("../images/logo.png");
//import logo from '../images/logo.png';
import logo from '../images/locorobo-logo.svg'

const styles = theme => ({
  appBar: {
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.grey["100"]}`,
    backgroundColor: theme.palette.primary["light"],//"white"
    color: "white",
    height: window.navBarOffset,
  },
  inline: {
    display: "inline"
  },
  userOptions: {
    //justify: 'flex-end',
    maringLeft: 'auto',
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // Ensure space between elements
  },
  tabContainer: {
    overflowX: 'auto', // Enable horizontal scrolling for the tab container
    whiteSpace: 'nowrap', // Prevent tabs from wrapping to the next line
    flexGrow: 1, // Allow the tab container to grow and take available space
    color: "white",
    marginLeft: 32,
    display: 'flex', // Use flexbox to manage layout
  },
  tabItem: {
    minWidth: "auto"
  },
  iconItem: {
    paddingTop: 7,
    minWidth: "auto",
    flexShrink: 0, // Prevent the iconItem from shrinking
  },
  scrollButtons: {
    display: 'flex', // Use flexbox to manage layout
    alignItems: 'center', // Vertically center the scroll buttons
    justifyContent: 'center', // Horizontally center the scroll buttons
    '&:hover': {
      backgroundColor: theme.palette.action.hover, // Custom hover effect
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem', // Custom size for the scroll button icon
    },
  },
});

var components = {
  "HomeIcon": HomeIcon,
  "CodeIcon": CodeIcon,
  "TimelineIcon": TimelineIcon,
  "SchoolIcon": SchoolIcon,
  "GamesIcon": GamesIcon,
  "DogIcon": PetsIcon,
  "FlightIcon": FlightIcon,
};



class Topbar extends Component {

  static contextType = AuthContext;

  constructor(props, context) {
    super(props);

    //
    this.userProfileElement = React.createRef();
    this.userAccessElement = React.createRef();
  }

  state = {
    value: 0,
    menuDrawer: false,
    userMenu: false,
    userAnchor: null,
  };

  handleChange = (event, value) => {
    this.setState({ value });
    //if (value == 2) {
    //  window.open("https://learn-locorobo.com/", "_blank", "noopener noreferrer");
    //}
  };

  mobileMenuOpen = event => {
    this.setState({ menuDrawer: true });
  };

  mobileMenuClose = event => {
    this.setState({ menuDrawer: false });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleUserMenuBtn = (event) => {
    
    this.setState( {userMenu: true, userAnchor: event.currentTarget} );
  };
  
  handleUserMenuClose = () => {

    this.setState( {userMenu: false} );
  };

  handleUserLogout = () => {
    this.context.AuthInstance.logout();
    this.setState( {userMenu: false} );
    this.props.history.replace('/login');
  };

  handleUserProfile = () => {
    this.userProfileElement.current.handleOpen();
    this.setState( {userMenu: false} );
  };

  handleUserAccessList = () => {
    this.userAccessElement.current.handleOpen();
    this.setState( {userMenu: false} );
  };


  current = () => {
    const { currentPath } = this.props;

    // Check if the current path is "/"
    if (currentPath === "/") {
      return 0;
    }

    // Find the index of the item in MenuCustom with the matching pathname
    const index = MenuCustom.findIndex(item => item.pathname === currentPath);

    // Return the index if found, otherwise return -1 or a default value
    return index !== -1 ? index : 0;
  };

  render() {
    const { classes } = this.props;

    return (
      <AppBar position="static" color="default" className={classes.appBar}>
        <Toolbar className={classes.flex}>
          <Box className={classes.iconItem}>
            <img src={logo} style={{width:'150px', height: '50px', }} alt="LocoRobo Logo" />
          </Box>
          <div className={classes.tabContainer}>
            <Tabs
              value={this.current()}
              indicatorColor="secondary"
              textColor="secondary"
              onChange={this.handleChange}
              variant="scrollable"
              scrollButtons="auto"
              classes={{ scrollButtons: classes.scrollButtons }}
            >
              {MenuCustom.map((item, index) => {

                const ItemImage = components[item.image];
                
                if (item.conditional) {
                  const hasItem = item.options.some(ai => this.context.AuthInstance.userAccess.includes(ai));
                  if (!hasItem) {
                      return null;
                  }
                }

                return (
                  <Tab
                    key={index}
                    component={item.external ? MaterialLink : Link}
                    href={item.external ? item.pathname : null}
                    to={
                      item.external
                        ? null
                        : {
                            pathname: item.pathname,
                            search: this.props.location.search
                          }
                    }
                    target={item.external ? "_blank": null}
                    rel = {item.external ? "noopener noreferrer": null}
                    classes={{ root: classes.tabItem }}
                    label={item.label}
                    icon={<ItemImage/>}
                  />
                );
              })}
            </Tabs>
          </div>
          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="secondary"                
            className={classes.userOptions}
            onClick={this.handleUserMenuBtn}
            style={{textTransform: "none"}}            
          >
            <AccountCircleIcon fontSize="large" />{this.context.AuthInstance.userName}
            <ExpandMoreIcon />
          </IconButton>
          <Menu
            id="customized-menu"
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            anchorEl={this.state.userAnchor}
            open={this.state.userMenu}
            onClose={this.handleUserMenuClose}
          >
            {this.context.AuthInstance.profile_access === true && (
              <MenuItem onClick={this.handleUserProfile}>Profile</MenuItem>
            )}
            <MenuItem onClick={this.handleUserAccessList}>Check Access</MenuItem>
            <MenuItem onClick={this.handleUserLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
        <UserProfile
          ref = {this.userProfileElement}
        />
        <AccessListModal
          ref = {this.userAccessElement}
        />
      </AppBar> 
    );
  }
}

export default withRouter(withStyles(styles)(Topbar));
