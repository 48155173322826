import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../context/auth";

import CssBaseline from "@material-ui/core/CssBaseline";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Topbar from "./Topbar";
import GeneralMessage from "./drawers/GeneralMessage";
import DroneCanvas from "./DroneCanvas";
import TextField from "@material-ui/core/TextField";
import LCInputsModal from "./modals/LCInputsModal";


const FRAME_INTV = 500; // Update the drone position

// Material-UI CSS-type Style Specifications
const styles = (theme) => {
    const actionButton = {
        justify: "center",
        margin: theme.spacing(1),
        color: theme.palette.primary["contrastText"],
        backgroundColor: "green",
        borderRadius: '25px',
        '&:hover': {
            backgroundColor: "darkgreen", // Change to a darker green or any other color
        },
    };

    return {
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.grey["100"],
            overflow: "hidden",
            backgroundSize: "cover",
            backgroundPosition: "0 400px",
            paddingBottom: 200
        },
        accessDiv: {
            display: 'flex',
            maxHeight: `calc(100vh - ${window.navBarOffset})`, 
            minHeight: `calc(100vh - ${window.navBarOffset})`,
        },
        lftUpButton: {
            ...actionButton,
            width: '200px',
        },
        leftFlightButtons: {
            ...actionButton,
            width: '200px',
        },
        leftSection: {
            padding: theme.spacing(2), // Add padding for internal spacing
            backgroundColor: '#303030'
        },
        sectionBox: {  
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            display: 'flex', // Use Flexbox
            flexDirection: 'column', // Arrange items in a column
            justifyContent: 'center', // Center items vertically
            alignItems: 'center', // Center items horizontally
            boxSizing: 'border-box', // Ensure padding and border are included in the element's total width and height
        },
        rightSection: {
            flex: '1', // Default size: take the remaining space
            backgroundColor: '#ffffff', // Optional: Add a background color for visibility
            display: 'flex',
            flexDirection: 'column',
        },
        infoSection: {
            padding: theme.spacing(2),
            backgroundColor: '#e4e4e4', // Optional: Add a background color for visibility
            flex: '0 0 auto', // Do not grow or shrink
        },
        canvasContainer: {
            flex: '1', // Take the remaining space
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        loadingIndicator: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        tabbedText: {
            whiteSpace: 'pre', // Preserve whitespace and tabs
        },
        gotoInput: {
            color: "white",
            width: '30px', // Set a fixed width for the input items
            margin: theme.spacing(1), // Optional: Add margin for spacing
            '& .MuiInputBase-input': {
                color: 'white', // Change text color to white
                textAlign: 'center', // Center the text
            },
        },
        subsection: {
            //border: '1px solid #ccc',
            borderRadius: '4px',
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            boxSizing: 'border-box', // Ensure padding and border are included in the element's total width and height
        },
        centeredDiv: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
    };
};


class DroneView extends Component {
  
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.droneCanvasRef = React.createRef();

        this.LCInputsModalRef = React.createRef();
        this.MsgElement = React.createRef();
        
        this.locoCraze = null;
        this.dataIntvId = null;
    }
    state = {
        hasAccess: false,
        isCanvasReady: false,
        loading: true,
        isConnected: false,
        isFlying: false,
        pos: {
            x: 0,
            y: 0,
            z: 0
        },
        att: {
            roll: 0,
            pitch: 0,
            yaw: 0
        },
        gotoX: 0,
        gotoY: 0,
        gotoZ: 0,
        radioDetailsSet: false,
        hdrLoaded: false,
        modelLoaded: false,
    }

    componentDidMount() {
        
        this.checkAccess();
    }

    componentWillUnmount() {
        // check if flying and land/disconnect
        if (this.state.isFlying) {
        
        }

        // check if connected and disconnect
        if (this.state.isConnected) {

        }

        // clear intervals
        if (this.dataIntvId) {
            clearInterval(this.dataIntvId);
        }
    }

    handleCanvasReady = () => {
        this.setState({ isCanvasReady: true, loading: false });
    };

    checkAccess = () => {

        const hasAccess = this.context.AuthInstance.userAccess.includes("LocoCraze");

        this.setState({
            hasAccess: hasAccess
        });

        if (hasAccess) {

            // Create a shorter reference to window.shared.LocoCrazeObj
            this.locoCraze = window.shared.LocoCrazeObj;

        }

    };

    handleRadioInfo = () => {
        this.LCInputsModalRef.current.handleOpen(this.context.AuthInstance);
    }

    handleLCInputs = async () => { // Only gets here when not "cancel"/close-only in modal
        this.setState({ radioDetailsSet: true });
    }

    handleConnection = async () => {

        // reset position and angle values in state
        this.setState({
            pos: {
                x: 0,
                y: 0,
                z: 0
            },
            att: {
                roll: 0,
                pitch: 0,
                yaw: 0
            }
        });

        // if connected, disconnect
        if (this.state.isConnected) {
            
            if (this.state.dataIntvId) {
                clearInterval(this.state.dataIntvId);
                this.setState({ dataIntvId: null });
            }

            if (this.state.isFlying) {
                try {
                    await this.locoCraze.drone_land();
                } catch (err) {
    
                }
            }
    
            try {
                await this.locoCraze.disconnect();
            } catch (err) {
                //
            }
        
        } else {
            // Connection process
            const hexAddr = this.context.AuthInstance.loadedState.LocoCraze.address;
            const channel = this.context.AuthInstance.loadedState.LocoCraze.channel;
    
            // Open connect message modal
            this.MsgElement.current.handleOpenStay('blue', 'Connecting...');

            const res = await this.locoCraze.connect(hexAddr, channel);
    
            try {
                if (res) {
                    this.MsgElement.current.handleClose();
                } else {
                    this.MsgElement.current.handleOpen('red', 'Connection Failed', 3000);
                    await this.locoCraze.disconnect();
                    return;
                }
            } catch (err) {
                this.MsgElement.current.handleOpen('red', 'Connection Failed', 3000);
                return
            };
        }

        this.setState({
            isConnected: !this.state.isConnected
        });

    };

    trackLogData = () => {
        const { x, y, z, roll, pitch, yaw } = this.locoCraze;
        //console.log('x: ', x.toFixed(2), 'y: ', y.toFixed(2), 'z: ', z.toFixed(2));
        //console.log('roll: ', roll, 'pitch: ', pitch, 'yaw: ', yaw);
        this.droneCanvasRef.current.updateQuadcopter(y, z, x, roll, pitch, yaw);
        // update state
        this.setState({
            pos: {
                x: x,
                y: y,
                z: z
            },
            att: {
                roll: roll,
                pitch: pitch,
                yaw: yaw
            }
        });
    };

    resetDronePositionAndOrientation = () => {
        this.setState({
          pos: { x: 0, y: 0, z: 0 },
          att: { roll: 0, pitch: 0, yaw: 0 },
        });
        this.droneCanvasRef.current.updateQuadcopter(0, 0, 0, 0, 0, 0);
    };

    handleFlight = async () => {

        if (this.state.isFlying) {
            try {
                // Land
                await this.locoCraze.drone_land();
            } catch (err) {
                // land failed message
                this.MsgElement.current.handleOpen('red', 'Landing Error', 3000);
            }

            this.resetDronePositionAndOrientation();
            
            if (this.state.dataIntvId) {
                clearInterval(this.state.dataIntvId);
                this.setState({ dataIntvId: null });
            }
        } else {
            const interval = setInterval(this.trackLogData, FRAME_INTV);
            this.setState({ dataIntvId: interval });

            // Takeoff
            try {
                await this.locoCraze.drone_takeoff();
            } catch (err) {
                // takeoff failed message
                this.MsgElement.current.handleOpen('red', 'Takeoff Error', 3000);
                return;
            }
        }

        this.setState({ isFlying: !this.state.isFlying });
    };

    testLoop = () => {
        
        // create array of x, y, z
        let positions = [];
        for (let i = 0; i < 100; i++) {
            positions.push([0, (i / 100) * 5, 0]);
        }

        // update the drone position with droneCanvasRef every second for 10 seconds
        let i = 0;
        let interval = setInterval(() => {
            this.droneCanvasRef.current.updateQuadcopter(
                positions[i][0], positions[i][1], positions[i][2],
                0,0,0
            );
            i++;
            if (i === 100) {
                clearInterval(interval);
            }
        }, 100);

    }

    handleIn = (event) => {
        this.setState( {[event.target.name]: event.target.value} );
    };

    handleInOnBlur = (event, name) => {

        if (!this.state.hasOwnProperty(name)) {
            return
        }

        let m = this.state[name];

        if (name.includes('goto')) {
            // Validate input using a regular expression
            const isValid = /^[0-9]+$/.test(m);

            if (!isValid) {
                m = "0";
            } else {
                // Convert m to a number
                const parsedValue = parseInt(m, 10);

                // Check if the value is a valid integer
                if (Number.isNaN(parsedValue)) {
                    m = "0";
                } else {
                    m = parsedValue.toString();
                }
            }
            this.setState({ [name]: m });
        }

    };

    handleGoTo = async () => {

        // get x, y, and z values from input fields in state
        const x = parseFloat(this.state.gotoX);
        const y = parseFloat(this.state.gotoY);
        const z = parseFloat(this.state.gotoZ);

        const target = [x, y, z];
        await this.locoCraze.drone_go(target);

    };

    handleHDRLoad = () => {
        //console.log('HDR texture loaded in DroneView');
        this.setState({ hdrLoaded: true, modelLoaded: true });
    };
    
    handleModelLoad = () => {
        //console.log('GLTF model loaded in DroneView');
        this.setState({ modelLoaded: true });
    };

    renderInfoSection = (classes) => {
        const { hdrLoaded, modelLoaded } = this.state;
    
        if (!hdrLoaded) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={24} style={{ marginRight: 8 }} />
              <Typography variant="body1" className={this.props.classes.tabbedText}>
                Loading Textures...
              </Typography>
            </div>
          );
        } else if (hdrLoaded && !modelLoaded) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={24} style={{ marginRight: 8 }} />
              <Typography variant="body1" className={this.props.classes.tabbedText}>
                Loading Models...
              </Typography>
            </div>
          );
        } else {
          return (
            <Typography variant="body1" className={classes.tabbedText}>
            Position (cm): ({this.state.pos.x.toFixed(1)}, {this.state.pos.y.toFixed(1)}, {this.state.pos.z.toFixed(1)})
            {'\t'}Orientation (deg): Roll: {this.state.att.roll.toFixed(1)}, Pitch: {this.state.att.pitch.toFixed(1)}, Yaw: {this.state.att.yaw.toFixed(1)}
            </Typography>
          );
        }
      };


    
    render() {
        const { classes } = this.props;
        const { 
            loading, hasAccess, isFlying, 
            isConnected, radioDetailsSet, 
            modelLoaded 
        } = this.state;

        // For TopBar Route Highlight
        const currentPath = this.props.location.pathname;

        // What to Display - Selectable Views Based on activeStep
        return (
            <React.Fragment>
            <CssBaseline />
            <Topbar currentPath={currentPath} />
            <div>
            {!hasAccess ? ( // Advertisement
            <div>


            </div>
            ) : ( // Real Content
            <div className={classes.accessDiv}>
                <Grid container className={classes.accessDiv}>
                    <Grid item xs={3} className={classes.leftSection}>
                        <Box className={classes.sectionBox}>
                            <Button
                                className={classes.lftUpButton}
                                onClick={this.handleRadioInfo}
                                disabled={loading || !modelLoaded}
                                >
                                Set Radio Info
                            </Button>                        
                            <Button
                                className={classes.lftUpButton}
                                onClick={this.handleConnection}
                                disabled={loading || !radioDetailsSet}
                                >
                                {isConnected ? 'Disconnect' : 'Connect'}
                            </Button>
                        </Box>
                        <Box className={classes.sectionBox}>
                            <Button
                                className={classes.leftFlightButtons}
                                onClick={this.handleFlight}
                                disabled={!isConnected}
                                >
                                {isFlying ? 'Land' : 'Takeoff'}
                            </Button>
                            <Box className={classes.subsection}>
                                <Button
                                    className={classes.leftFlightButtons}
                                    onClick={this.handleGoTo}
                                    disabled={!isFlying}
                                    >
                                    Go To
                                </Button>
                                <div className={classes.centeredDiv}>
                                    <TextField
                                        className={classes.gotoInput}
                                        name="gotoX"
                                        value = {this.state.gotoX}
                                        onChange={this.handleIn}
                                        onBlur = {(e) => this.handleInOnBlur(e, 'gotoX')}
                                    />
                                    <TextField
                                        className={classes.gotoInput}
                                        name="gotoY"
                                        value = {this.state.gotoY}
                                        onChange={this.handleIn}
                                        onBlur = {(e) => this.handleInOnBlur(e, 'gotoY')}
                                    />
                                    <TextField
                                        className={classes.gotoInput}
                                        name="gotoZ"
                                        value = {this.state.gotoZ}
                                        onChange={this.handleIn}
                                        onBlur = {(e) => this.handleInOnBlur(e, 'gotoZ')}
                                    />
                                </div>
                            </Box>
                        </Box>

                    </Grid>
                    <Grid item xs={9} className={classes.rightSection}>
                        <div className={classes.rightSection}>
                            <div className={classes.infoSection}>
                                {this.renderInfoSection(classes)}
                            </div>
                            <div className={classes.canvasContainer}>
                                <DroneCanvas
                                    ref={this.droneCanvasRef}
                                    onReady={this.handleCanvasReady}                                
                                    onHDRLoad={this.handleHDRLoad}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            )}




            </div>
            <LCInputsModal
                ref={this.LCInputsModalRef}
                onClose={this.handleLCInputs}
            />
            <GeneralMessage
                ref = {this.MsgElement}
                onClose = {() => void 0}
            />
            </React.Fragment>
        );
    }


};

export default withRouter(withStyles(styles)(DroneView));
