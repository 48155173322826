// Child Component of: 
// --- Program

// Import React and Material-UI Modules
import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';

import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { AuthContext } from "../context/auth";
import UnshareProgram from "./drawers/UnshareProgram";
import GeneralMessage from "./drawers/GeneralMessage";

import SharedSelfSubList from "./SharedSelfSubList";
import ProgramSearch from "./ProgramSearch";

// Material-UI CSS-type Style Specifications
const styles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: "white",
      color: theme.palette.primary["light"],
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "0 400px",
      paddingBottom: 200,
      margin: theme.spacing(0),
    },
    listItemText: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: 'black',
    },
    listItemTextSecondary: {
        fontSize: '20px',
        color: 'black',
    }
});



const TYPE_COLOR_MAP = {
    LocoXtreme: '#C5F1FF',
    LocoDrone: '#FFD5D4',
    LocoDroneT: '#FFD5D4',
    PythonOnly: '#FFFFFF',
    LocoWear: '#F49AC2',
    LocoArm: '#FFFFCE',
    LocoArmS: '#FFFFCE',
    LocoArmM: '#FFFFCE',
    LocoArmMS: '#FFFFCE',
    LocoIoT: '#DCFFF5',
    LocoArmMini: '#FFFFCE',
};


// ProgramList
class ProgramList extends Component {

    static contextType = AuthContext;

    constructor (props) {
        super(props)
        this.filterElement =  React.createRef();
    }

    // Class state - hard-coded values right now for certain items, TODO: reference database for program names/types/information
    state = {
        amount: 0,
        period: 1,
        start: 1,
        monthlyPayment: 1,
        monthlyInterest: 1,
        data: 1,
        loading: 0,
        unshareElement: React.createRef(),
        denyshareElement: React.createRef(),
        acceptshareElement: React.createRef(),
        i: 0,
        isOpenNew: false,
        isOpenAccept: false,
        isOpenGraded: false,
        newStateArr: [],
        acceptedStateArr: [],
        gradedStateArr: [],
        newStateCount: "",
        acceptedStateCount: "",
        gradedStateCount: "",
        programList: [],
        isLoading: true,
        listFilter: "",
        typeFilter: "All",
    };    

    componentDidMount() {
        this.updateSharedArrays()
        this.context.AuthInstance.getProgramList().then(res => {
            this.setState( {programList: this.context.AuthInstance.programList} )
            this.setState( {isLoading: false} )
        });
    }

    updateSharedArrays = () => {
        return this.context.AuthInstance.getSharedProgramList().then(() => {
            //this.props.listRefresh(2);
            const sharedLen = this.context.AuthInstance.sharedProgramList.length;
            let temp_newStateArr = [];
            let temp_acceptedStateArr = [];
            let temp_gradedStateArr = [];
            for (let u = 0; u < sharedLen; u++) {
    
                const shareState = this.context.AuthInstance.sharedProgramList[u].state;
                if (shareState === 'new') {
                    temp_newStateArr.push({...this.context.AuthInstance.sharedProgramList[u]});        
                }
    
                if (shareState === 'accepted') {                    
                    temp_acceptedStateArr.push({...this.context.AuthInstance.sharedProgramList[u]});
                }
    
                if (shareState === 'graded') {
                    temp_gradedStateArr.push({...this.context.AuthInstance.sharedProgramList[u]});
                }
            }
            this.setState( {
                newStateCount: temp_newStateArr.length.toString(), 
                acceptedStateCount: temp_acceptedStateArr.length.toString(),
                gradedStateCount: temp_gradedStateArr.length.toString(), 
                newStateArr: temp_newStateArr,
                acceptedStateArr: temp_acceptedStateArr,
                gradedStateArr: temp_gradedStateArr,
            });
        });
    }
    
    handleSelection = (index) => () => {

        //let selection = this.context.AuthInstance.programList[index];
        let selection = this.state.programList[index];

        let progDetails = {type: "load", selection: selection, hardware: selection.hardware}
        this.props.onCreateProgram(progDetails);
    };


    handleDenyShare = (i, shareState) => {
        this.setState( {i: i, shareState: shareState});
        this.state.denyshareElement.current.handleOpen('green', "Shared Program Denied", 1000);
    }

    handleAcceptShare = (i) => {
        this.setState( {i: i});
        this.state.acceptshareElement.current.handleOpen('green', "Shared Program Accepted", 1000);
    }   

    checkAcceptShare = () => {
        
        //const uri = this.context.AuthInstance.sharedProgramList[this.state.i].resource_uri;
        const uri = this.state.newStateArr[this.state.i].resource_uri;
        const stateData = 'accepted';
        return this.context.AuthInstance.saveSharedProgram(uri, stateData).then(() => {
            this.updateSharedArrays()
            setTimeout(this.refreshSharedList, 100);
        });
    }

    checkDenyShare = (event) => {
        let uri = null;
        if (this.state.shareState === 'new') {
            uri = this.state.newStateArr[this.state.i].resource_uri;
        } else if (this.state.shareState === 'accepted') {
            uri = this.state.acceptedStateArr[this.state.i].resource_uri;
        } else {
            uri = this.state.gradedStateArr[this.state.i].resource_uri;
        }
        const stateData = 'denied';
        return this.context.AuthInstance.saveSharedProgram(uri, stateData).then(() => {
            this.updateSharedArrays()
            setTimeout(this.refreshSharedList, 100);
        });
    }



    handleUnshare = (event) => {
        if (event === "OK") {
            const uri = this.context.AuthInstance.sharingProgramList[this.state.i].resource_uri;
            return this.context.AuthInstance.deleteSharingProgram(uri).then(() => {
                setTimeout(this.refreshSharingList, 100);
            });
        }
    }

    refreshSharingList = () => {
        return this.context.AuthInstance.getSharingProgramList().then(() => {
            this.props.listRefresh(1);
        });
    }

    refreshSharedList = () => {
        return this.context.AuthInstance.getSharedProgramList().then(() => {
            this.props.listRefresh(2);
        });
    }

    handleDeleteSharing = (i) => {
        this.setState( {i: i});

        this.state.unshareElement.current.handleOpen();
    }

    handleOpenShare = (i, shareState) => {

        //const refType =  (this.context.AuthInstance.sharedProgramList[i].copied_program === null) ? 'shared_program' : 'copied_program';
        //const selection = this.context.AuthInstance.sharedProgramList[i][refType];
        //selection.resource_uri = this.context.AuthInstance.sharedProgramList[i].resource_uri;
        let refType = null;
        let selection = null;
        if (shareState === 'accepted') {
            refType =  (this.state.acceptedStateArr[i].copied_program === null) ? 'shared_program' : 'copied_program';
            selection = this.state.acceptedStateArr[i][refType];
            selection['shared_from'] = this.state.acceptedStateArr[i]['shared_from'];
            selection.resource_uri = this.state.acceptedStateArr[i].resource_uri;
        } else {
            refType =  (this.state.gradedStateArr[i].copied_program === null) ? 'shared_program' : 'copied_program';
            selection = this.state.gradedStateArr[i][refType];
            selection['shared_from'] = this.state.gradedStateArr[i]['shared_from'];
            selection.resource_uri = this.state.gradedStateArr[i].resource_uri;
        }
        selection['shareState'] = shareState;
        
        let progDetails = {type: "load", selection: selection, hardware: selection.hardware, shared: true, sharedIndex: i};
        
        this.props.onCreateProgram(progDetails);
    }

    handleClickNew = () => {

        this.setState( {isOpenNew: !this.state.isOpenNew} );

    }

    handleClickAccepted = () => {

        this.setState( {isOpenAccept: !this.state.isOpenAccept} );

    }

    
    handleClickGraded = () => {

        this.setState( {isOpenGraded: !this.state.isOpenGraded} );

    }

    applyFilter = (filter_str, type_str) => {
        this.setState( {listFilter: filter_str, typeFilter: type_str} )

        if (this.props.programListType === 'Programs shared with me') {

            let newCount = 0;
            let acceptedCount = 0;
            let gradedCount = 0;

            for (let i in this.state.newStateArr) {
                let labelId = ''
                let value = this.state.newStateArr[i]
                if (value.shared_program !== undefined && value.shared_program !== null)
                    labelId = value.shared_program.name.toLowerCase()
                else if (value.copied_program !== undefined && value.copied_program !== null)
                    labelId = value.copied_program.name.toLowerCase()

                if (((filter_str !== '') && (labelId.toLowerCase().includes(filter_str.toLowerCase()))) || (filter_str === '')) {
                    newCount += 1
                }
            }
            for (let i in this.state.acceptedStateArr) {   
                let labelId = ''
                let value = this.state.acceptedStateArr[i]
                if (value.shared_program !== undefined && value.shared_program !== null)
                    labelId = value.shared_program.name.toLowerCase()
                else if (value.copied_program !== undefined && value.copied_program !== null)
                    labelId = value.copied_program.name.toLowerCase()

                if (((filter_str !== '') && (labelId.toLowerCase().includes(filter_str.toLowerCase()))) || (filter_str === '')) {
                    acceptedCount += 1
                }
            }
            for (let i in this.state.gradedStateArr) {
                let labelId = ''
                let value = this.state.gradedStateArr[i]
                if (value.shared_program !== undefined && value.shared_program !== null)
                    labelId = value.shared_program.name.toLowerCase()
                else if (value.copied_program !== undefined && value.copied_program !== null)
                    labelId = value.copied_program.name.toLowerCase()

                if (((filter_str !== '') && (labelId.includes(filter_str.toLowerCase()))) || (filter_str === '')) {
                    gradedCount += 1
                }
            }
            this.setState({
                newStateCount: newCount,
                acceptedStateCount: acceptedCount,
                gradedStateCount: gradedCount,
            })
        }
    }


    refreshFilter = () => {
        this.filterElement.current.clearFilter()
    }

    // Takes input data, returns what to display
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        let myProgCount = 0;

        // What to Display - List of Programs from "state" information
        return (
            <React.Fragment>
                <ProgramSearch 
                    updateSearch = {this.applyFilter}
                    ref = {this.filterElement}
                />
                <Paper elevation={0} 
                style={{maxHeight: `calc(100vh - ${window.navBarOffset} - ${window.programBarOffset} - ${window.extraOffset})`, 
                        overflow: 'auto'}}>
                <List className={classes.root} >
                {this.state.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                {this.props.programListType === 'My Programs' && this.state.programList.map((value, i) => {
                    const labelId = `${value.name}`.toLowerCase()
                    const valuec = `${value.language}`;
                    const valuen = `${value.name}`
                    let valueb;
                    if (valuec === 'python') {
                        valueb = 'Python'
                    } else {
                        valueb = valuec.toUpperCase();
                    }
                    valueb = `${value.hardware}, ` + valueb

                    let typeFlag = 2;
                    if (this.state.typeFilter !== 'All') {
                        if (value.hardware !== this.state.typeFilter) {
                            typeFlag = 0;
                        } else {
                            typeFlag = 1;
                        }
                    }
                    if (((this.state.listFilter !== '') && 
                        (typeFlag !== 0) && 
                        (labelId.includes(this.state.listFilter.toLowerCase()))) ||
                        ((this.state.listFilter === '') && (typeFlag !== 0))) {
                        myProgCount++
                        return (
                            <ListItem key={i} role={undefined} dense button onClick={this.handleSelection(i)}
                                style = {{ backgroundColor: myProgCount % 2 ? 'white': '#dae2f1',
                                    borderRadius:10, margin: 10,
                                    justifyContent: 'flex-start',
                                    display: 'flex',
                                    alignItems: 'flex-start'
                                }}
                            >
                                <ListItemText classes={{primary:classes.listItemText}} id={labelId} primary={valuen} />
                                <ListItemSecondaryAction
                                    style = {{left: '65%'}}
                                >
                                    <Typography className={classes.listItemTextSecondary}>
                                        {valueb}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItem>  
                        );
                    }
                })}
                {this.props.programListType === 'Programs I am sharing' && (
                                        
                    this.context.AuthInstance.sharingProgramList.map((value, i) => {
                    
                        //const refName = (value.copied_program === null) ? 'shared_program' : 'copied_program';
                        const messageTxt = 'Shared to ' + value.shared_to;
                        let timeDate = value.created_at;
                        const dateOff = timeDate.indexOf('T');
                        timeDate = timeDate.substring(0, dateOff);
                        const timeDateList = timeDate.split('-');
                        const dateMsg = timeDateList[1] + '/' + timeDateList[2] + '/' + timeDateList[0];

                        let shareState = value.state;
                        shareState = shareState.charAt(0).toUpperCase() + shareState.slice(1);

                        const labelId = value.name.toLowerCase()
                        if (((this.state.listFilter !== '') && (labelId.includes(this.state.listFilter.toLowerCase()))) || (this.state.listFilter === '')) {
                            return (
                                <Card key = {i}>
                                    <Grid
                                        spacing = {1}
                                        justifyContent="center"
                                        direction="row"
                                        container
                                        className={classes.grid}
                                    >
                                        <Grid item xs={9}>
                                            <Typography className={classes.listItemText}>
                                                {value.name}
                                            </Typography>
                                            <Typography className={classes.listItemTextSecondary}>
                                                {dateMsg}
                                            </Typography>
                                            <Typography className={classes.listItemTextSecondary}>
                                                {messageTxt}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div>
                                                <Typography className={classes.listItemTextSecondary}>
                                                    {shareState}
                                                </Typography>
                                            </div>
                                            <div>
                                                <IconButton
                                                    name="closeBtn"                
                                                    color="primary"
                                                    variant="contained"
                                                    className={classes.actionButton}
                                                    style={{color:"red"}}
                                                    onClick={() => this.handleDeleteSharing(i)}
                                                >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                    </Grid>                                    
                                </Card>
                            );
                        }
                    }
                ))}
                {this.props.programListType === 'Programs shared with me' && (
                    <React.Fragment>
                        <ListItem button onClick={this.handleClickNew}>
                        <ListItemText 
                            disableTypography
                            primary={<Typography variant="h6" style={{  }}>New ({this.state.newStateCount}) </Typography>}
                        />
                            {this.state.isOpenNew ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.isOpenNew} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {this.state.newStateArr.map((value, i) => {
                                    let labelId = ''
                                    if (value.shared_program !== undefined && value.shared_program !== null)
                                        labelId = value.shared_program.name.toLowerCase()
                                    else if (value.copied_program !== undefined && value.copied_program !== null)
                                        labelId = value.copied_program.name.toLowerCase()

                                    if (((this.state.listFilter !== '') && (labelId.includes(this.state.listFilter.toLowerCase()))) || (this.state.listFilter === '')) {
                                        return (
                                            <SharedSelfSubList
                                                key = {'cnew' + i.toString()}
                                                value = {value}
                                                keyName = {'new' + i.toString()}
                                                keyIndx = {i}
                                                handleOpenShare = {this.handleOpenShare}
                                                handleAcceptShare = {this.handleAcceptShare}
                                                handleDenyShare = {this.handleDenyShare}
                                            />
                                        );
                                    }
                                })}
                            </List>
                        </Collapse>
                        <ListItem button onClick={this.handleClickAccepted}>
                        <ListItemText 
                            disableTypography
                            primary={<Typography variant="h6" style={{  }}>Accepted ({this.state.acceptedStateCount}) </Typography>}
                        />
                            {this.state.isOpenAccept ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={this.state.isOpenAccept} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {this.state.acceptedStateArr.map((value, i) => {
                                    let labelId = ''
                                    if (value.shared_program !== undefined && value.shared_program !== null)
                                        labelId = value.shared_program.name.toLowerCase()
                                    else if (value.copied_program !== undefined && value.copied_program !== null)
                                        labelId = value.copied_program.name.toLowerCase()
                                    if (((this.state.listFilter !== '') && (labelId.includes(this.state.listFilter.toLowerCase()))) || (this.state.listFilter === '')) {
                                        return (
                                            <SharedSelfSubList
                                                key = {'caccept' + i.toString()}
                                                value = {value}
                                                keyName = {'accept' + i.toString()}
                                                keyIndx = {i}
                                                handleOpenShare = {this.handleOpenShare}
                                                handleAcceptShare = {this.handleAcceptShare}
                                                handleDenyShare = {this.handleDenyShare}
                                            />
                                        );
                                    }
                                })}
                            </List>
                        </Collapse> 
                        {this.context.AuthInstance.account_type !== 'student' && (
                            <React.Fragment>
                                <ListItem button onClick={this.handleClickGraded}>
                                <ListItemText 
                                    disableTypography
                                    primary={<Typography variant="h6" style={{  }}>Graded ({this.state.gradedStateCount}) </Typography>}
                                />
                                    {this.state.isOpenGraded ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state.isOpenGraded} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {this.state.gradedStateArr.map((value, i) => {
                                            let labelId = ''
                                            if (value.shared_program !== undefined && value.shared_program !== null)
                                                labelId = value.shared_program.name.toLowerCase()
                                            else if (value.copied_program !== undefined && value.copied_program !== null)
                                                labelId = value.copied_program.name.toLowerCase()
                                            if (((this.state.listFilter !== '') && (labelId.includes(this.state.listFilter.toLowerCase()))) || (this.state.listFilter === '')) {
                                                return (
                                                    <SharedSelfSubList
                                                        key = {'cgraded' + i.toString()}
                                                        value = {value}
                                                        keyName = {'graded' + i.toString()}
                                                        keyIndx = {i}
                                                        handleOpenShare = {this.handleOpenShare}
                                                        handleAcceptShare = {this.handleAcceptShare}
                                                        handleDenyShare = {this.handleDenyShare}
                                                    />
                                                );
                                            }
                                        })}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                </List>
                </Paper>
                <UnshareProgram
                    ref={this.state.unshareElement}
                    onClose={this.handleUnshare}
                />
                <GeneralMessage
                    ref={this.state.denyshareElement}
                    onClose={this.checkDenyShare}
                />
                <GeneralMessage
                    ref={this.state.acceptshareElement}
                    onClose={this.checkAcceptShare}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ProgramList)