import { LocoCraze } from '../libraries/LocoCraze/LocoCraze';

// This file is from an online tutorial
import decode from 'jwt-decode';


const classMapping = {
    'LocoCraze': LocoCraze,
    // Add other class mappings here
};

export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        // Local DB
        //this.domain = 'http://127.0.0.1:8000/api/token/';
        //this.apiServer = 'http://127.0.0.1:8000/api/';
        //this.userdomain = 'http://127.0.0.1:8000/api/v1/user/';
        // Server DB
        this.domain = 'https://api-dev.locoroboapp.com/api/token/';
        this.apiServer = 'https://api-dev.locoroboapp.com/api/';
        this.userdomain = 'https://api-dev.locoroboapp.com/api/v1/user/';
        this.fetch = this.fetch.bind(this); // React binding stuff
        this.login = this.login.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.authTokens = null;
        this.userName = null;
        this.fn = "_";
        this.ln = "_";
        this.account_type = 'student';
        this.teacher_name = '';
        this.profile_access = false;
        this.pk = null;
        this.userAccess = [];
        this.programList = [];
        this.sharedProgramList = [];
        this.sharingProgramList = [];
        this.students = [];
        this.is_superuser = false;
        this.is_staff = false;
        this.loadedState = {};
    };

    login(username, password) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}`, {
            method: 'POST',
            body: JSON.stringify({
                username,
                password
            })
        }).then(res => {
            this.setTokens(res) // Setting the token in localStorage
            this.authTokens = res.access;
            this.userName = username;
            return Promise.resolve(res);
        })
    };

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getAccessToken() // Getting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
        //return !this.isTokenExpired(token);
    }

    validRefresh() {
        const token = this.getRefreshToken() // Getting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    getProgramList() {
        return this.handleRefresh().then(() => {
            return this.fetch(`${this.apiServer}v1/program/`, {
                method: 'GET',
            }).then(res => {
                // ? thing supposed to use filter thing in db that isn't supported in Djagno 3.0+, so cheating
                this.programList = [];
                for (let i = 0; i < res.length; i++) {
                    if (res[i].deleted === false) {                       
                        if (!this.domain.includes('127.0.0.1')) {
                            res[i].resource_uri = res[i].resource_uri.replace('http', 'https');
                        }
                        if (res[i].resource_uri.includes('httpss')) {
                            res[i].resource_uri = res[i].resource_uri.replace('httpss', 'https');
                        }
                        this.programList.push(res[i]);
                    }
                }
            });
        });
    }

    getSharedProgramList() {
        return this.handleRefresh().then(() => {
            return this.fetch(`${this.apiServer}v1/shared-programs/`, {
                method: 'GET',
            }).then(res => {
                this.sharedProgramList = [];
                for (let i = 0; i < res.length; i++) {                       
                    if (!this.domain.includes('127.0.0.1')) {
                        res[i].resource_uri = res[i].resource_uri.replace('http', 'https');
                    }
                    if (res[i].resource_uri.includes('httpss')) {
                        res[i].resource_uri = res[i].resource_uri.replace('httpss', 'https');
                    }
                    if (res[i].state !== 'denied') {
                        this.sharedProgramList.push(res[i]);
                    }
                }
            });
        });
    }

    getSharingProgramList() {
        return this.handleRefresh().then(() => {
            return this.fetch(`${this.apiServer}v1/sharing-programs/`, {
                method: 'GET',
            }).then(res => {
                this.sharingProgramList = [];
                for (let i = 0; i < res.length; i++) {      
                    const refType =  (res[i].copied_program === null) ? 'shared_program' : 'copied_program';
                    if (!this.domain.includes('127.0.0.1')) {
                        res[i].resource_uri = res[i].resource_uri.replace('http', 'https');
                    }
                    if (!this.domain.includes('127.0.0.1')) {
                        res[i][refType] = res[i][refType].replace('http', 'https');
                    }
                    if (res[i].resource_uri.includes('httpss')) {
                        res[i].resource_uri = res[i].resource_uri.replace('httpss', 'https');
                    }
                    if (res[i][refType].includes('httpss')) {
                        res[i][refType] = res[i][refType].replace('httpss', 'https');
                    }
                    if (res[i].state !== 'denied') {
                        this.sharingProgramList.push(res[i]);
                    }
                }
                return this.getSharingProgramInfo().then(() => {
                });
            });
        });
    }

    getSharingProgramInfo = async () => {
        return this.handleRefresh().then( async () => {
            for (let i = 0; i < this.sharingProgramList.length; i++) {
                const refType = (this.sharingProgramList[i].copied_program === null) ? 'shared_program' : 'copied_program';
                const progUri = this.sharingProgramList[i][refType];
                
                let res = await this.fetch(progUri, {
                    method: 'GET',
                });
                this.sharingProgramList[i].name = res.name;
                this.sharingProgramList[i].revision = res.revision;
                /*
                return this.fetch(progUri, {
                    method: 'GET',
                }).then(res => {
                    this.sharingProgramList[i].name = res.name;
                    this.sharingProgramList[i].revision = res.revision;
                });*/
            }
        });
    }

    createSharingProgram = async (data) => {

        return this.handleRefresh().then(() => {
            return this.fetch(`${this.apiServer}v1/sharing-programs/`, {
                method: 'POST',
                body: JSON.stringify(
                    data,
                )
            }).then(res => {
                return Promise.resolve(res);
            });
        });
    }
        

    saveSharingProgram() {

    }

    deleteSharingProgram(uri) {
        return this.handleRefresh().then(() => {
            return this.fetch(uri, {
                method: 'DELETE',
            }).then(res => {
                return Promise.resolve(res);
            });
        });
    }


    saveSharedProgram(uri, stateData, gradeData) {
        const data = {
            state: stateData,
            grade: gradeData,
        };
        return this.handleRefresh().then(() => {
            return this.fetch(uri, {
                method: 'PATCH',
                body: JSON.stringify(
                    data,
                )
            }).then(res => {
                return Promise.resolve(res);
            });
        });
    }

    saveUserProfile(fn, ln) {

        const data = {
            first_name: fn,
            last_name: ln,
        };
        const addr = this.userdomain + this.pk + '/';
        return this.handleRefresh().then(() => {
            return this.fetch(addr, {
                method: 'PATCH',
                body: JSON.stringify(
                    data,
                )
            }).then(res => {
                this.fn = fn;
                this.ln = ln;
                return Promise.resolve(res);
            });
        });
    }



    createProgram(name, editor, language, instructions, hardware) {

        language = language.toLowerCase();

        const fname = this.fn;
        const lname = this.ln;

        return this.handleRefresh().then(() => {
            return this.fetch(`${this.apiServer}v1/program/`, {
                method: 'POST',
                body: JSON.stringify({
                    name,
                    fname,
                    lname,
                    editor,
                    language,
                    instructions,
                    hardware,
                })
            }).then(res => {
                if (!this.domain.includes('127.0.0.1')) {
                    res.resource_uri = res.resource_uri.replace('http', 'https');
                }
                if (res.resource_uri.includes('httpss')) {
                    res.resource_uri = res.resource_uri.replace('httpss', 'https');
                }
                    
                return Promise.resolve(res);
            });
        });
    }

    saveProgram(uri, data) {
        
        return this.handleRefresh().then(() => {
            return this.fetch(uri, {
                method: 'PATCH',
                body: JSON.stringify(
                    data,
                )
            }).then(res => {
                return Promise.resolve(res);
            });
        });
    }

    deleteProgram(uri, data) {

        return this.handleRefresh().then(() => {
            return this.fetch(uri, {
                method: 'PATCH',
                body: JSON.stringify(
                    data,
                )
            }).then(res => {
                return Promise.resolve(res);
            });
        });
    }


    getProductAccess() {
        
        return this.handleRefresh().then(() => {    
            return this.fetch(`${this.userdomain}`, {
                method: 'GET',
            }).then(res => {
                
                this.userName = res.username;
                this.pk = res.pk.toString();
                this.is_superuser = res.is_superuser;
                this.is_staff = res.is_staff;
                
                // for each True, add key to array
                let hardwareAccess = [];
                
                for (const [key, value] of Object.entries(res.access)) {
                    if (key === 'profile_access') {
                        this.profile_access = value;
                    } else if (key === 'account_type') {
                        this.account_type = value;
                    } else if (key === 'teacher_name') {
                        this.teacher_name = value;
                    } else if ((value === true) && 
                                (key !== 'programming_access') && 
                                (key !== 'DroneSim') && 
                                (key !== 'LDTJava') && 
                                (key !== 'LDTLocalPy')) {
                        hardwareAccess.push(key);
                    } else if (key === 'programming_access') {
                        hardwareAccess.push('PythonOnly');
                    } else if (key === 'students') {
                        this.students = value.split(",");
                    }
                }
                // check hardwareAccess for share instance creations
                const sharedNames = Object.keys(classMapping);
                
                // check if any sharedNames are in hardwareAccess
                for (let i = 0; i < sharedNames.length; i++) {
                    if (hardwareAccess.includes(sharedNames[i])) {
                        // create object in window.shared matching sharedNames[i] class 
                        //window.shared[sharedNames[i]] = new sharedNames[i]();
                        const className = sharedNames[i];
                        const ClassConstructor = classMapping[className];
                        window.shared[className + 'Obj'] = new ClassConstructor();
                    }
                }

                if (this.profile_access === true) {
                    if (res['first_name'].length !== 0) {
                        this.fn = res.first_name;
                    }
                    if (res['last_name'].length !== 0) {
                        this.ln = res.last_name;
                    }
                }
                
                this.userAccess = hardwareAccess;
                
                return Promise.resolve(res);
            })
        }).catch((e) => { // catch is new
            console.log(e)
            return Promise.resolve("error");
        });
    }



    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setTokens(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('accesss_token', idToken.access);
        localStorage.setItem('refresh_token', idToken.refresh);
    };

    getAccessToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('accesss_token');
    }

    getRefreshToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('refresh_token');
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('accesss_token');
        localStorage.removeItem('refresh_token');
        this.authTokens = null;
        this.userName = null;
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getAccessToken());
    }


    handleRefresh() {
        if (this.loggedIn() === false) {
            return this.checkRefresh().then(res => {
                this.authTokens = res.access;
                localStorage.setItem('accesss_token', res.access);
                return Promise.resolve(res);
            }).catch(() => {
                try {
                    this.logout();
                    setTimeout(window.location.reload(true), 100);
                } catch {}
            });
        } else {
            return Promise.resolve("done");
        }
    }


    checkRefresh() {

        let refresh = this.getRefreshToken();

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        let options = {
            method:'POST',
            body: JSON.stringify({
                refresh,
            }),
        };
        let url = `${this.domain}refresh/`;
        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            //headers['Authorization'] = 'Bearer ' + this.getAccessToken()
            headers['Authorization'] = 'Bearer ' + this.getAccessToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => {
                if (response.status !== 204) {
                    return response.json();
                }
            })
    }

    _checkStatus = (response) => {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response;
        } else {
            if (response.status === 403) {
                this.logout();
            } else {
                var error = new Error(response.statusText)
                console.log(response);
                error.response = response
                //throw error
            }
        }
    }
}
