import { CRTPPacket, CRTPPort } from "./LCExtra"

// Channels used for the logging port
const CHAN_INFO = 0
const CHAN_READ = 1
const CHAN_WRITE = 2

// Commands used when accessing the Settings port
const CMD_INFO_VER = 0
const CMD_INFO_NBR = 1
const CMD_INFO_DETAILS = 2

export class Memory {

    constructor(crazyflie) {
        this.cf = crazyflie;

        this.mems = []
        this.nbr_of_mems = 0;
    }

    /**
     * Fetch the memory with the supplied id
     * @param {number} id - The id of the memory to fetch
     * @returns {Mem|null} - The memory with the supplied id, or null if not found
     */
    get_mem = (id) => {
        for (let m of this.mems) {
            if (m.id === id) {
                return m;
            }
        }
        return null;
    }

    /**
     * Fetch all the memories of the supplied type
     * @param {string} type - The type of the memories to fetch
     * @returns {Mem[]} - An array of memories of the supplied type
     */
    get_mems = (type) => {
        let ret = [];
        for (let m of this.mems) {
            if (m.type === type) {
                ret.push(m);
            }
        }
        return ret;
    }


    refresh = async() => {

        let pk = new CRTPPacket();
        pk.set_header(CRTPPort.MEM, CHAN_INFO);
        pk.data = new Uint8Array([CMD_INFO_NBR]);

        let inPacket = await this.cf.sendPacketAndWaitForResponse({
            pk: pk,
            port: CRTPPort.MEM,
            channel: CHAN_INFO,
            timeout: 3000,
            resend: true
        });

        await this.handlePacket(inPacket);
        //console.log(this.nbr_of_mems);
        let fetch_id = 0;
        if (this.nbr_of_mems > 0) {
            //self.nbr_of_mems - 1 >= self._fetch_id
            while (this.nbr_of_mems - 1 >= fetch_id) {
                let detailPacket = new CRTPPacket();
                detailPacket.set_header(CRTPPort.MEM, CHAN_INFO);
                detailPacket.data = new Uint8Array([CMD_INFO_DETAILS, fetch_id]);

                let inPk = await this.cf.sendPacketAndWaitForResponse({
                    pk: detailPacket,
                    port: CRTPPort.MEM,
                    channel: CHAN_INFO,
                    timeout: 3000,
                    resend: true
                });
                //console.log(inPk);

                //mem_id = await this.handlePacket(inPk);
                //fetch_id = mem_id + 1;
                
                let cmd = inPk.data[0];
                let payload = inPk.data.slice(1);
                if (cmd === CMD_INFO_DETAILS) {
                    const mem_resp = await this.handleCmdInfoDetails(payload);

                    if (mem_resp.mem_id === fetch_id) {
                        if (mem_resp.mem_instance !== null) {
                            this.mems.push(mem_resp.mem_instance);
                        }
                        fetch_id += 1;
                    }
                }
            }
            console.log("finished loop")


        } else {
            // says no memory available, that's bad
            console.error("No memory available");
        }

    };
    
    handlePacket = async(packet) => {

        let chan = packet.channel;
        let cmd = packet.data[0];
        let payload = packet.data.slice(1);
        //console.log(chan, cmd, payload);
        if (chan === CHAN_INFO) {
            await this.handleInfo(cmd, payload);
        } else if (chan === CHAN_WRITE) {
            await this.handleWrite(cmd, payload);
        } else if (chan === CHAN_READ) {
            await this.handleRead(cmd, payload);
        }
    };

    handleInfo = async(cmd, payload) => {
        if (cmd === CMD_INFO_NBR) {
            this.nbr_of_mems = payload[0];
        } else if (cmd === CMD_INFO_DETAILS) {
            //this.handleCmdInfoDetails(payload);
        }
    };

    handleCmdInfoDetails = async(payload) => {
        let mem = null;
        // Id - 1 byte
        let mem_id = payload[0];
        // Type - 1 byte
        let mem_type = payload[1];
        // Size 4 bytes (as addr)
        let mem_size = new DataView(payload.buffer).getUint32(2, true); // true for little-endian
        // Addr (only valid for 1-wire?)
        //let mem_addr_raw = Array.from(payload.slice(6, 14));
        //let mem_addr = mem_addr_raw.map(m => m.toString(16).toUpperCase().padStart(2, '0')).join('');

        if (mem_type === MemoryElement.TYPE_I2C) {
            mem = new I2CElement(mem_id, mem_type, mem_size, this, this.cf);
        }

        return { 
            mem_id: mem_id,
            mem_instance: mem,
        };
    };

    handleWrite = async(cmd, payload) => {

    };

    handleRead = async(cmd, payload) => {

    };

    
};


class ReadRequest {
    /**
     * Class used to handle memory reads that will split up the read in multiple
     * packets if necessary
     */
    static MAX_DATA_LENGTH = 20;

    constructor(mem, addr, length, cf) {
        /** Initialize the object with good defaults */
        this.mem = mem;
        this.addr = addr;
        this._bytes_left = length;
        this.data = new Uint8Array();
        this.cf = cf;

        this._current_addr = addr;
    }

    start = async () => {
        /** Start the fetching of the data */
        await this._request_new_chunk();
    }

    resend() {
        console.debug('Sending write again...');
        this._request_new_chunk();
    }

    _request_new_chunk = async() => {
        /** Called to request a new chunk of data to be read from the Crazyflie */
        // Figure out the length of the next request
        let new_len = this._bytes_left;
        if (new_len > ReadRequest.MAX_DATA_LENGTH) {
            new_len = ReadRequest.MAX_DATA_LENGTH;
        }

        console.log(`Requesting new chunk of ${new_len} bytes at 0x${this._current_addr.toString(16).toUpperCase()}`);
        // Request the data for the next address
        let pk = new CRTPPacket();
        pk.set_header(CRTPPort.MEM, CHAN_READ);
        let buffer = new ArrayBuffer(6);
        let view = new DataView(buffer);
        view.setUint8(0, this.mem.id);
        view.setUint32(1, this._current_addr, true); // true for little-endian
        view.setUint8(5, new_len);
        pk.data = new Uint8Array(buffer);
        //let reply = Array.from(pk.data.slice(0, -1));
        
        const inPacket = await this.cf.sendPacketAndWaitForResponse({
            pk: pk,
            port: CRTPPort.MEM,
            channel: CHAN_READ,
            timeout: 3000,
            resend: true
        });
        return inPacket;
    }

    add_data(addr, data) {
        /** Callback when data is received from the Crazyflie */
        let data_len = data.length;
        if (addr !== this._current_addr) {
            console.warn('Address did not match when adding data to read request!');
            return;
        }

        // Add the data and calculate the next address to fetch
        let newData = new Uint8Array(this.data.length + data_len);
        newData.set(this.data);
        newData.set(data, this.data.length);
        this.data = newData;
        this._bytes_left -= data_len;
        this._current_addr += data_len;

        if (this._bytes_left > 0) {
            this._request_new_chunk();
            return false;
        } else {
            return true;
        }
    }
}

class WriteRequest {
    /**
     * Class used to handle memory writes that will split up the write in multiple
     * packets if necessary
     */
    static MAX_DATA_LENGTH = 25;

    constructor(mem, addr, data, cf, progress_cb = null) {
        /** Initialize the object with good defaults */
        this.mem = mem;
        this.addr = addr;
        this._bytes_left = data.length;
        this._write_len = this._bytes_left;
        this._data = data;
        this.data = new Uint8Array();
        this.cf = cf;
        this._progress_cb = progress_cb;
        this._progress = -1;

        this._current_addr = addr;

        this._sent_packet = null;
        this._sent_reply = null;

        this._addr_add = 0;
    }

    start() {
        /** Start the fetching of the data */
        this._write_new_chunk();
    }

    resend() {
        console.debug('Sending write again...');
        this.cf.send_packet(this._sent_packet, { expected_reply: this._sent_reply, timeout: 1 });
    }

    _write_new_chunk = async() => {
        /** Called to request a new chunk of data to be written to the Crazyflie */
        // Figure out the length of the next request
        let new_len = this._data.length;
        if (new_len > WriteRequest.MAX_DATA_LENGTH) {
            new_len = WriteRequest.MAX_DATA_LENGTH;
        }

        console.log(`Writing new chunk of ${new_len} bytes at 0x${this._current_addr.toString(16).toUpperCase()}`);

        const data = this._data.slice(0, new_len);
        this._data = this._data.slice(new_len);

        const pk = new CRTPPacket();
        pk.set_header(CRTPPort.MEM, CHAN_WRITE);
        const headerBuffer = new ArrayBuffer(5);
        const headerView = new DataView(headerBuffer);
        headerView.setUint8(0, this.mem.id);
        headerView.setUint32(1, this._current_addr, true); // true for little-endian
        pk.data = new Uint8Array(headerBuffer);

        // Create a tuple used for matching the reply using id and address
        const reply = Array.from(pk.data);
        this._sent_reply = reply;

        // Add the data
        const dataBuffer = new Uint8Array(data);
        pk.data = new Uint8Array([...pk.data, ...dataBuffer]);
        this._sent_packet = pk;
        //this.cf.send_packet(pk, { expected_reply: reply, timeout: 1 });

        const inPacket = await this.cf.sendPacketAndWaitForResponse({
            pk: pk,
            port: CRTPPort.MEM,
            channel: CHAN_WRITE,
            timeout: 3000,
            resend: false,
        })

        return inPacket;
        //this._addr_add = data.length;
        //this._bytes_left -= this._addr_add;
    }
}





const EEPROM_TOKEN = [48, 120, 66, 67];


class I2CElement {

    constructor(id, type, size, mem_handler, cf) {
        this.id = id;
        this.cf = cf;
        this.type = type;
        this.size = size;
        this.mem_handler = mem_handler;
        this.elements = {}
        this.valid = false

        this.dataV0 = null;
    }

    write_data = async() => {
        const radio_address = this.elements['radio_address'];
        const upper_bits = Math.floor(radio_address / Math.pow(2, 32)); // Extract the upper 8 bits
        const lower_bits = radio_address & 0xFFFFFFFF; // Extract the lower 32 bits
        const data = [
            0x01,
            this.elements['radio_channel'],
            this.elements['radio_speed'],
            this.elements['pitch_trim'],
            this.elements['roll_trim'],        
            upper_bits,
            lower_bits
        ];

        //<BBBffBI
        const buffer = new ArrayBuffer(19); // 3 bytes for BBB, 2 * 4 bytes for ff, 1 byte for B, 4 bytes for I
        const view = new DataView(buffer);
    
        view.setUint8(0, data[0]);
        view.setUint8(1, data[1]);
        view.setUint8(2, data[2]);
        view.setFloat32(3, data[3], true); // true for little-endian
        view.setFloat32(7, data[4], true); // true for little-endian
        view.setUint8(11, data[5]);
        view.setUint32(12, data[6], true); // true for little-endian
    
        let image = new Uint8Array(buffer);

        const _checksum256 = (st) => {
            return st.reduce((x, y) => x + y, 0) % 256;
        };

        image = new Uint8Array([...EEPROM_TOKEN, ...image]);

        // Calculate the checksum and append it to the image
        const checksum = _checksum256(image);
        const newImage = new Uint8Array(image.length + 1);
        newImage.set(image);
        newImage[image.length] = checksum;

        image = newImage;

        // self.mem_handler.write(self, 0x00, struct.unpack('B' * len(image), image))

        // addr = 0x00, data = struct.unpack('B' * len(image), image)
        //def write(self, memory, addr, data, flush_queue=False, progress_cb=None)
            // wreq = _WriteRequest(memory, addr, data, self.cf, progress_cb)

            //wreq.start()
        const addr = 0x00;

        const wreq = new WriteRequest(this, addr, image, this.cf);
        const inPacket = await wreq._write_new_chunk();

        console.log(inPacket);

        const chanRes = inPacket.channel
        //const cmd = inPacket.data[0]// is the mem id

        if (chanRes !== CHAN_WRITE) {
            console.error('Invalid Response Type');
            return false;
        }
        const payloadRes = inPacket.data.slice(1);

        // Combine payload[0:5] into a single buffer
        const bufferRes = new ArrayBuffer(5);
        const viewRes = new DataView(bufferRes);

        for (let i = 0; i < 5; i++) {
            viewRes.setUint8(i, payloadRes[i]);
        }

        // Unpack the combined buffer
        const addrRes = viewRes.getUint32(0, true); // true for little-endian
        const statusRes = viewRes.getUint8(4);

        if (statusRes === 0) {
            console.log('Data written to memory');
            return true;
        } else {
            console.error("Failed to write data to memory");
            return false;
        }

    };

    update = async() => {

        // Start reading the header
        let res = await this.read(0, 16);
        if (res !== 0) {
            console.error('Failed to read memory header');
            return false;
        }
        res = await this.read(16, 5);
        while (res === -2) {
            res = await this.read(16, 5);
        }
        if (res === -1) {
            console.error('Failed to read radio address');
            return false;
        }
        return this.elements;
    }

    read = async(address, length) => {

        const rreq = new ReadRequest(this, address, length, this.cf);
        const inPacket = await rreq._request_new_chunk();
        
        const chan = inPacket.channel
        //const cmd = inPacket.data[0]// is the mem id

        if (chan !== CHAN_READ) {
            console.error('Invalid Response Type');
            return false;
        }
        const payload = inPacket.data.slice(1);

        // Combine payload[0:5] into a single buffer
        const buffer = new ArrayBuffer(5);
        const view = new DataView(buffer);

        for (let i = 0; i < 5; i++) {
            view.setUint8(i, payload[i]);
        }

        // Unpack the combined buffer
        const addr = view.getUint32(0, true); // true for little-endian
        const status = view.getUint8(4);

        if (address !== addr) {
            return -2;
        }

        // Unpack the remaining data
        //const data = Array.from(payload.slice(5));
        //console.log(addr, status, data);
        
        if (status === 0) {
            const subdata = payload.slice(5);
            const res = await this.new_data(addr, subdata);

            if (res) {
                return 0;
            } else {
                console.error('Failed to read data from memory');
                return -1;
            }

        } else {
            console.error('Failed to read data from memory');
            return -1;
        }

    }


    new_data = async(address, data) => {

        //if (mem.id === this.id) {
        if (address === 0) {
            if (data.slice(0, 4).every((value, index) => value === EEPROM_TOKEN[index])) {
                console.debug(`Got new data: ${data}`);
                const view = new DataView(data.buffer, data.byteOffset, data.byteLength);
                this.elements = {
                    version: view.getUint8(4),
                    radio_channel: view.getUint8(5),
                    radio_speed: view.getUint8(6),
                    pitch_trim: view.getFloat32(7, true), // true for little-endian
                    roll_trim: view.getFloat32(11, true) // true for little-endian
                };
                console.log(this.elements);
                if (this.elements['version'] === 0) {
                    //done = True
                } else if (this.elements['version'] === 1) {
                    this.datav0 = data
                }
            }
        } else if (address === 16) {
            const buffer = new ArrayBuffer(5);
            const view = new DataView(buffer);
            
            // Combine self.datav0[15:16] and data[0:4] into a single buffer
            view.setUint8(0, this.datav0[15]);
            for (let i = 0; i < 4; i++) {
                view.setUint8(i + 1, data[i]);
            }
            
            // Unpack the combined buffer
            const radio_address_upper = view.getUint8(0);
            const radio_address_lower = view.getUint32(1, true); // true for little-endian
            
            // Combine the upper and lower parts to form the full radio address
            this.elements['radio_address'] = (radio_address_upper * Math.pow(2, 32)) + radio_address_lower;
            console.log(this.elements['radio_address']);
        } else {
            return false;
        }
        return true;
        //}
    }

};


export class MemoryElement {
    static TYPE_I2C = 0
    static TYPE_1W = 1
    static TYPE_DRIVER_LED = 0x10
    static TYPE_LOCO = 0x11
    static TYPE_TRAJ = 0x12
    static TYPE_LOCO2 = 0x13
    static TYPE_LH = 0x14
    static TYPE_MEMORY_TESTER = 0x15
    static TYPE_DRIVER_LEDTIMING = 0x17
    static TYPE_APP = 0x18
    static TYPE_DECK_MEMORY = 0x19
    static TYPE_DECK_MULTIRANGER = 0x1A
    static TYPE_DECK_PAA3905 = 0x1B
}


