// Child Component of: 
// --- ProgramGUI

// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

// Custom Component Imports
import LocoDroneBlocks from './LocoDrone/LocoDroneBlocks';
import LocoDroneTBlocks from './LocoDroneT/LocoDroneTBlocks';
import LocoDroneTTBlocks from './LocoDroneTT/LocoDroneTTBlocks';
import LocoXtremeBlocks from './LocoXtreme/LocoXtremeBlocks';
import LocoScoutBlocks from './LocoScout/LocoScoutBlocks';
import LocoCrazeBlocks from './LocoCraze/LocoCrazeBlocks';

// Allows Dynamic Import Based on Hardware Type
var components = {
    "LocoDroneBlocks": LocoDroneBlocks,
    "LocoDroneTBlocks": LocoDroneTBlocks,
    "LocoDroneTTBlocks": LocoDroneTTBlocks,
    "LocoXtremeBlocks": LocoXtremeBlocks,
    "LocoScoutBlocks": LocoScoutBlocks,
    "LocoCrazeBlocks": LocoCrazeBlocks,
};


// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    paper: {
      width: 515,//600
      //height: 200,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
      marginTop: theme.spacing(2),
    },
    actionButton: {
      justify: "right",
      marginTop: theme.spacing(2),
      color: "red",
    },
    media: {
        height: 0,
        paddingTop: '100%', // 
        marginTop:'30',
    },  
    updown: {
        //height: 140,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justify: 'space-between',
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
    },
    right: {
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        color: 'white',
        size: 'large',
    }
  });
  
  // Component Class - ProgramDisplay
  class BaseBlock extends React.Component {
    
    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);

        this.handleSubState = this.handleSubState.bind(this);

        this.handleDelete = this.handleDelete.bind(this);
        this.handleMoveUp = this.handleMoveUp.bind(this);
        this.handleMoveDown = this.handleMoveDown.bind(this);
    }

    state = {
        subBlock: React.createRef(),
        isDisabled: this.props.isDisabled,
        isFixed: false,
        id: this.props.id,
        type: this.props.blockType.split(".")[0],
        subState: {
            title: '',
            height: 200,  //0
            color: 'white',
            code: [],
            control: {},
        },
    };
        
    // Allows SubBlock to Update BaseBlock and For BaseBlock to Update ProgramGUI's blocksList (If Allowed)
    handleSubState = (stateInfo) => {
        this.setState( {subState: stateInfo} )
        if (this.state.isDisabled === false) {
            let blockInfo = {
                name: this.state.type,
                code: stateInfo.code,
                control: stateInfo.control,
                id: stateInfo.id,
            };
            this.props.handleUpdateState(blockInfo);
        }
    }

    // Used By ProgramGUI to Update SubBlock
    refreshType = (blockListI) => {
        this.state.subBlock.current.componentRefresh(blockListI);
    }
   
    // Delete Current Block from Parent's BlockList
    handleDelete = () => {
        this.props.handleDeleteBlock(this.state.id);
    }

    // Move Current Block Up (If Applicable) in Parent's BlockList
    handleMoveUp = () => {
        this.props.handleMoveBlockUp(this.state.id);
    }

    // Move Current Block Down (If Applicable) in Parent's BlockList
    handleMoveDown = () => {
        this.props.handleMoveBlockDown(this.state.id);
    }
    
    setFixedState = (isFixed) => {
        this.setState({ isFixed });
    };

    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        // Dynamic Component Selection for SubBlock Based on Hardware Selection
        const BlockHardware = components[`${this.props.hardwareSelection}Blocks`];

        // For Visual Indentation
        const marginLOffset = -1.0 * (this.props.cardWidth / 2) + this.props.indentationLevel* (this.props.cardWidth / 15);
        return (
            <Card 
                className={classes.paper}
                width={this.props.cardWidth}                
                style={{backgroundColor: this.state.subState.color, marginLeft: `calc(50% + ${marginLOffset}px)`, borderRadius: 20, height: this.state.subState.height}}
            >
                <Grid 
                    container 
                    spacing={1}
                    direction="row"
                >
                <Grid item xs={2}>
                    <div className={classes.updown} style={{height: this.state.subState.height - 60}}>
                        {!this.state.isFixed && (
                            <IconButton 
                                className={classes.icon}
                                onClick={this.handleMoveUp}
                                disabled={this.state.isDisabled}
                            >
                                <ArrowDropUpIcon fontSize="large"/>
                            </IconButton>
                        )}
                    </div>
                    <div className={classes.updown}>
                        {!this.state.isFixed && (
                            <IconButton
                                className={classes.icon}
                                onClick={this.handleMoveDown}
                                disabled={this.state.isDisabled || this.state.isFixed}
                            >
                                <ArrowDropDownIcon fontSize="large"/>
                            </IconButton>
                        )}
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <BlockHardware 
                        ref={this.state.subBlock}
                        control={this.props.control}
                        id={this.props.id}
                        i={this.props.i}
                        isDisabled={this.state.isDisabled}
                        blockType = {this.state.type}
                        handleSubState= {this.handleSubState}
                        setFixedState={this.setFixedState}
                    />
                </Grid>
                <Grid item xs={2}>
                    {!this.state.isFixed && (
                        <IconButton
                            className={classes.icon}
                            onClick={this.handleDelete}
                            disabled={this.state.isDisabled}
                        >
                            <CloseIcon fontSize="large"/>
                        </IconButton>
                    )}
                </Grid>
                </Grid>
            </Card>
        );
    }

}

export default withStyles(styles)(BaseBlock);
