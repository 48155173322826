// Child Component of: 
// --- BaseBlock

// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import Slider from '@material-ui/core/Slider';


import logoNote from '../../../images/blocks/LocoXtreme/bnote.svg'
import logoSong from '../../../images/blocks/LocoXtreme/bsong.svg'
import logoPause from '../../../images/blocks/LocoXtreme/bpause.svg'
import logoDrive from '../../../images/blocks/LocoXtreme/bdrive.svg'
import logoMove from '../../../images/blocks/LocoXtreme/bmove.svg'
import logoRotate from '../../../images/blocks/LocoXtreme/brotate.svg'
import logoLoop from '../../../images/blocks/LocoXtreme/bloop.svg'


function componentToHex(c) {
    let hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}
function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}


// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    paper: {
      width: 515,//600
      height: 200,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
    },
    grid: {
        justify: 'flex-start',
    },
    gridLightStates: {
        justify: 'center',
        alignItems: 'center'
    },
    titleText: {
        color: "white",
        margin: theme.spacing(1),
    },
    inputField: {
        width: "100%",
        color: "white",
        marginTop: theme.spacing(0.6),
        marginLeft: theme.spacing(1),
    },
    regularText: {
        color: "white",
        margin: theme.spacing(1),
    },
    textButton: {
        color: "white",
        margin: theme.spacing(0),
        padding: theme.spacing(0.5),
        border: '1px solid #000',
    },
    textSelect: {
        color: "white",
        marginTop: theme.spacing(0.4),        
    },
    lightIndices: {
        borderRadius: '70%',
        width: '25px',
        height: '25px',
    }
});

// Component Class - ProgramDisplay
class LocoXtremeBlocks extends React.Component {

    state = {
        LXBlocksOptions: {
            inote: 
            {
                id: 0,
                title: 'Note',
                height: 225, 
                color: '#ec4078',
                in1Width: '30px',
                in1Type: 'float',
                in1Scale: 1000,
                control: {in1: 0, sel1: 'Note.C', sel1Txt: 'C', sel2: '4', sel2Txt: '4', btn1: 0, btn1Txt: 'wait', },
                codeRef(ctrl) {
                    let codeArray = [`# Play Note ${ctrl.sel1Txt} at Octave ${ctrl.sel2Txt} for ${Number(ctrl.in1) * 1000} milliseconds and ${ctrl.btn1Txt}`, `locoxtreme.play_note(${ctrl.sel1}, ${ctrl.sel2}, ${Number(ctrl.in1) * 1000}, ${ctrl.btn1})`];
                    if (ctrl.btn1Txt === 'wait') {
                        codeArray.unshift('locoxtreme.setup_wait(WT.SONG)');
                        codeArray.unshift('# Setup Sound-Based Wait');
                    }
                    return codeArray;
                },
                text: ['Play Note ', 'at Octave ', 'for ', 'seconds and ', 'until the note is over to continue'],
                sel1Options: ['C', 'C Sharp', 'D', 'E Flat', 'E', 'F', 'F Sharp', 'G', 'G Sharp', 'A', 'B Flat', 'B'],
                sel2Options: ['0', '1', '2', '3', '4', '5', '6', '7', '8'],
                btn1Option1: 'wait',
                btn1Option2: 'do not wait',
                sel1map: {'C': 'Note.C', 'C Sharp': 'Note.C_SHARP', 'D': 'Note.D', 'E': 'Note.E', 'E Flat': 'Note.E_FLAT', 'F': 'Note.F', 
                            'F Sharp': 'Note.F_SHARP', 'G': 'Note.G', 'G Sharp': 'Note.G_SHARP', 'A': 'Note.A', 'B Flat': 
                            'Note.B_FLAT', 'B': 'Note.B'},
                sel2map: {'0': '0', '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6', '7': '7', '8': '8'},
                btn1map: {'wait': 1, 'do not wait': 0},
                blockImg: logoNote,
            },
            isong: {
                id: 0,
                title: 'Song',
                height: 200, 
                color: '#ec4078',
                in1Width: '30px',
                in1Type: 'int',
                in1Scale: 1000,
                control: {in1: 0, sel1: 'Song.STAR_WARS', sel1Txt: 'Star Wars', btn1: 0, btn1Txt: 'wait', },
                codeRef(ctrl) {
                    let codeArray = [`# Play ${ctrl.sel1Txt} and ${ctrl.btn1Txt}`, `locoxtreme.play_song(${ctrl.sel1}, ${ctrl.btn1})`];
                    if (ctrl.btn1Txt === 'wait') {
                        codeArray.unshift('locoxtreme.setup_wait(WT.SONG)');
                        codeArray.unshift('# Setup Sound-Based Wait');
                    }
                    return codeArray;
                },
                text: ['Play ', 'and ', 'until the song is over to continue'],
                sel1Options: ['Star Wars', 'Rocky', 'Eye Of The Tiger', 'Mario', 'Pokemon'],
                btn1Option1: 'wait',
                btn1Option2: 'do not wait',
                sel1map: {'Star Wars': 'Song.STAR_WARS', 'Rocky': 'Song.ROCKY', 'Eye Of The Tiger': 'Song.EYE_OF_THE_TIGER', 'Mario': 'Song.MARIO', 'Pokemon': 'Song.POKEMON'},
                btn1map: {'wait': 1, 'do not wait': 0},
                blockImg: logoSong,
            },
            ilights_rgb: {
                id: 0,
                title: 'Lights RGB',
                height: 260,
                color: '#34495e',
                control: {
                    btn1: [255, 255, 255], btn2: [255, 255, 255], btn3: [255, 255, 255], btn4: [255, 255, 255], 
                    btn5: [255, 255, 255], btn6: [255, 255, 255], btn7: [255, 255, 255], btn8: [255, 255, 255],
                    mode: 'Normal', modeIndex: 0, modeUnits: '', 
                    btn9: 0, btn9Text: 'Set',
                    btn10: 0, btn10Text: 'until temperature is',
                    btn11: 0, btn11Text: 'greater than',
                    in1: 0,
                },
                codeRef(ctrl) {
                    let codeArray = [];
                    if (ctrl.mode === 'Temperature') {
                        //codeArray.push(`${ctrl.btn9}`) // Set flag
                        // Initial Reading
                        codeArray.push('')
                        codeArray.push('# Check Temperature Reading (C)')
                        codeArray.push('temp = locoxtreme.get_sensor_value(Data.TEMPERATURE)')
                        
                        // If Behavior
                        if (ctrl.btn10 === 'if') {                            
                            codeArray.push('')
                            codeArray.push(`# Check If Temperature Is ${ctrl.btn11} ${ctrl.in1} (C)`)
                            codeArray.push(`if (temp ${ctrl.btn11} ${ctrl.in1}):`)
                            if (ctrl.btn9 === 'set = False') {
                                codeArray.push(`    pass`)
                                codeArray.push(`else:`)
                            }
                            //
                            codeArray.push(`    # Set RGB Lights`)
                            codeArray.push(`    locoxtreme.set_light(0, ${ctrl.btn1[0]}, ${ctrl.btn1[1]}, ${ctrl.btn1[2]})`)
                            codeArray.push(`    locoxtreme.set_light(1, ${ctrl.btn2[0]}, ${ctrl.btn2[1]}, ${ctrl.btn2[2]})`)
                            codeArray.push(`    locoxtreme.set_light(2, ${ctrl.btn3[0]}, ${ctrl.btn3[1]}, ${ctrl.btn3[2]})`)
                            codeArray.push(`    locoxtreme.set_light(3, ${ctrl.btn4[0]}, ${ctrl.btn4[1]}, ${ctrl.btn4[2]})`)
                            codeArray.push(`    locoxtreme.set_light(4, ${ctrl.btn5[0]}, ${ctrl.btn5[1]}, ${ctrl.btn5[2]})`)
                            codeArray.push(`    locoxtreme.set_light(5, ${ctrl.btn6[0]}, ${ctrl.btn6[1]}, ${ctrl.btn6[2]})`)
                            codeArray.push(`    locoxtreme.set_light(6, ${ctrl.btn7[0]}, ${ctrl.btn7[1]}, ${ctrl.btn7[2]})`)
                            codeArray.push(`    locoxtreme.set_light(7, ${ctrl.btn8[0]}, ${ctrl.btn8[1]}, ${ctrl.btn8[2]})`)
                            codeArray.push(`    `)
                            codeArray.push(`    # Sync RGB Lights`)
                            codeArray.push(`    locoxtreme.sync_lights()`)
                            
                        } 
                        else { // While Behavior
                            if (ctrl.btn9 === 'set = True') {
                                codeArray.push(`# Set RGB Lights`)
                                codeArray.push(`locoxtreme.set_light(0, ${ctrl.btn1[0]}, ${ctrl.btn1[1]}, ${ctrl.btn1[2]})`)
                                codeArray.push(`locoxtreme.set_light(1, ${ctrl.btn2[0]}, ${ctrl.btn2[1]}, ${ctrl.btn2[2]})`)
                                codeArray.push(`locoxtreme.set_light(2, ${ctrl.btn3[0]}, ${ctrl.btn3[1]}, ${ctrl.btn3[2]})`)
                                codeArray.push(`locoxtreme.set_light(3, ${ctrl.btn4[0]}, ${ctrl.btn4[1]}, ${ctrl.btn4[2]})`)
                                codeArray.push(`locoxtreme.set_light(4, ${ctrl.btn5[0]}, ${ctrl.btn5[1]}, ${ctrl.btn5[2]})`)
                                codeArray.push(`locoxtreme.set_light(5, ${ctrl.btn6[0]}, ${ctrl.btn6[1]}, ${ctrl.btn6[2]})`)
                                codeArray.push(`locoxtreme.set_light(6, ${ctrl.btn7[0]}, ${ctrl.btn7[1]}, ${ctrl.btn7[2]})`)
                                codeArray.push(`locoxtreme.set_light(7, ${ctrl.btn8[0]}, ${ctrl.btn8[1]}, ${ctrl.btn8[2]})`)
                                codeArray.push(``)
                                codeArray.push(`# Sync RGB Lights`)
                                codeArray.push(`locoxtreme.sync_lights()`)
                            }
                            // While loop
                            codeArray.push(``)
                            codeArray.push(`# Run While the Temperature Is Not ${ctrl.btn11} ${ctrl.in1} (C)`)
                            codeArray.push(`while not (temp ${ctrl.btn11} ${ctrl.in1}):`)
                            codeArray.push('    time.sleep(0.2)')
                            codeArray.push('    temp = locoxtreme.get_sensor_value(Data.TEMPERATURE)')
                            //
                            if (ctrl.btn9 === 'set = False') {
                                codeArray.push(`# Set RGB Lights`)
                                codeArray.push(`locoxtreme.set_light(0, ${ctrl.btn1[0]}, ${ctrl.btn1[1]}, ${ctrl.btn1[2]})`)
                                codeArray.push(`locoxtreme.set_light(1, ${ctrl.btn2[0]}, ${ctrl.btn2[1]}, ${ctrl.btn2[2]})`)
                                codeArray.push(`locoxtreme.set_light(2, ${ctrl.btn3[0]}, ${ctrl.btn3[1]}, ${ctrl.btn3[2]})`)
                                codeArray.push(`locoxtreme.set_light(3, ${ctrl.btn4[0]}, ${ctrl.btn4[1]}, ${ctrl.btn4[2]})`)
                                codeArray.push(`locoxtreme.set_light(4, ${ctrl.btn5[0]}, ${ctrl.btn5[1]}, ${ctrl.btn5[2]})`)
                                codeArray.push(`locoxtreme.set_light(5, ${ctrl.btn6[0]}, ${ctrl.btn6[1]}, ${ctrl.btn6[2]})`)
                                codeArray.push(`locoxtreme.set_light(6, ${ctrl.btn7[0]}, ${ctrl.btn7[1]}, ${ctrl.btn7[2]})`)
                                codeArray.push(`locoxtreme.set_light(7, ${ctrl.btn8[0]}, ${ctrl.btn8[1]}, ${ctrl.btn8[2]})`)
                                codeArray.push(``)
                                codeArray.push(`# Sync RGB Lights`)
                                codeArray.push(`locoxtreme.sync_lights()`)
                            }
                        }                           
                    } else { // Normal Mode
                        codeArray.push(`# Set RGB Lights`)
                        codeArray.push(`locoxtreme.set_light(0, ${ctrl.btn1[0]}, ${ctrl.btn1[1]}, ${ctrl.btn1[2]})`)
                        codeArray.push(`locoxtreme.set_light(1, ${ctrl.btn2[0]}, ${ctrl.btn2[1]}, ${ctrl.btn2[2]})`)
                        codeArray.push(`locoxtreme.set_light(2, ${ctrl.btn3[0]}, ${ctrl.btn3[1]}, ${ctrl.btn3[2]})`)
                        codeArray.push(`locoxtreme.set_light(3, ${ctrl.btn4[0]}, ${ctrl.btn4[1]}, ${ctrl.btn4[2]})`)
                        codeArray.push(`locoxtreme.set_light(4, ${ctrl.btn5[0]}, ${ctrl.btn5[1]}, ${ctrl.btn5[2]})`)
                        codeArray.push(`locoxtreme.set_light(5, ${ctrl.btn6[0]}, ${ctrl.btn6[1]}, ${ctrl.btn6[2]})`)
                        codeArray.push(`locoxtreme.set_light(6, ${ctrl.btn7[0]}, ${ctrl.btn7[1]}, ${ctrl.btn7[2]})`)
                        codeArray.push(`locoxtreme.set_light(7, ${ctrl.btn8[0]}, ${ctrl.btn8[1]}, ${ctrl.btn8[2]})`)
                        codeArray.push(``)
                        codeArray.push(`# Sync RGB Lights`)
                        codeArray.push(`locoxtreme.sync_lights()`)
                    }
                    return codeArray
                },
                text: {
                    'Normal': [''], 
                    'Temperature': ['lights', 'C'],
                },
                modeOptions: ['Normal', 'Temperature'],
                modeUnits: {'Normal': '', 'Temperature': 'C'},
                modeIndices: 2,
                btn9Option1: 'Set',
                btn9Option2: 'Do not set',
                btn9map: {'Set': 'set = True', 'Do not set': 'set = False'},
                btn10Option1: 'until temperature is',
                btn10Option2: 'if temperature is',
                btn10map: {'until temperature is': 'while', 'if temperature is': 'if'},
                btn11Option1: 'greater than',
                btn11Option2: 'less than',
                btn11map: {'greater than': '>', 'less than': '<'},
                in1Width: '35px',
                in1Scale: 1000,    
                heightMode: {'Normal': 260, 'Temperature': 370}
            },
            ipause: {
                id: 0,
                title: 'Pause',
                height: 200, 
                color: '#fbc22d',
                in1Width: '35px',
                in1Scale: 1000,
                in2Width: '35px',
                in2Scale: 1000,
                control: {
                    in1: 0, 
                    btn1: 0, btn1Txt: 'greater than', 
                    in2: 0,
                    btn3: 0, btn3Txt: 'greater than',
                    mode: 'Time', modeIndex: 0, modeUnits: 'seconds', imageState1: 0,
                },
                codeRef(ctrl) {
                    let codeArray = [];
                    if (ctrl.mode === 'Time') {
                        codeArray.push(`# Set up Time-based Wait for ${ctrl.in1} seconds`);
                        codeArray.push(`locoxtreme.setup_wait(WT.TIME, ${ctrl.in1})`);
                        codeArray.push('locoxtreme.move(0, 0, 0, 0, True)');
                    } else if (ctrl.mode === 'Ultrasonic') {
                        codeArray.push(`# setup ultrasonic sensor-based wait for ${ctrl.in1} centimeters`);
                        codeArray.push(`locoxtreme.setup_wait(${ctrl.btn1}, ${ctrl.in1})`);
                        codeArray.push('locoxtreme.move(0, 0, 0, 0, True)');
                    } else if (ctrl.mode === 'Temperature') {
                        // Sensor Reading
                        codeArray.push('')
                        codeArray.push('# Check Temperature Reading (C)')
                        codeArray.push('temp = locoxtreme.get_sensor_value(Data.TEMPERATURE)')
                        // Conditional Logic
                        codeArray.push('')
                        codeArray.push(`# Check If Temperature Is ${ctrl.btn3} ${ctrl.in2} (C)`)
                        codeArray.push(`if (temp ${ctrl.btn3} ${ctrl.in2}):`)
                        // Time-Based Pause
                        codeArray.push(`    # Set up Time-based Wait for ${ctrl.in1} seconds`);
                        codeArray.push(`    locoxtreme.setup_wait(WT.TIME, ${ctrl.in1})`);
                        codeArray.push('    locoxtreme.move(0, 0, 0, 0, True)');
                    }

                    return codeArray;
                },
                text: {
                    'Time': ['Pause for ', 'seconds'], 
                    'Ultrasonic': ['Pause until object is ', ' CM away'],
                    'Temperature': ['Pause for ', 'seconds', 'if Temperature is ', 'C'],
                },
                btn1Option1: 'greater than',
                btn1Option2: 'less than',
                btn1map: {'greater than': 'WT.ULTRASONIC_GREATER_THAN', 'less than': 'WT.ULTRASONIC_LESS_THAN'},
                btn2map: {'greater than': 'WT.ULTRASONIC_GREATER_THAN', 'less than': 'WT.ULTRASONIC_LESS_THAN'},
                btn3Option1: 'greater than',
                btn3Option2: 'less than',
                btn3map: {'greater than': '>', 'less than': '<'},
                modeOptions: ['Time', 'Ultrasonic', 'Temperature'],
                modeUnits: {'Time': 'seconds', 'Ultrasonic': 'CM'},
                modeIndices: 3,
                hasImage: '',
                blockImg: logoPause,
            },
            idrive: {
                id: 0,
                title: 'Drive',
                height: 200, 
                color: '#49b050',
                in1Width: '35px',
                in1Scale: 1000,
                in2Width: '35px',
                in2Scale: 1000,
                control: {
                    in1: 0, 
                    btn1: 0, btn1Txt: 'Forward', 
                    btn2: 0, btn2Txt: "greater than", 
                    mode: 'Time', modeIndex: 0, modeUnits: 'seconds',
                    imageState1: 0,
                    in2: 0,
                    btn3: 0, btn3Txt: 'greater than',
                    btn4: 0, btn4Txt: 'seconds',
                },
                codeRef(ctrl) {
                    let codeArray = [];                    
                    if (ctrl.mode === 'Time') {
                        codeArray.push(`# Drive ${ctrl.btn1Txt} at full speed`)
                        codeArray.push(`# Time-based wait for ${ctrl.in1} ${ctrl.modeUnits}`)
                        codeArray.push(`locoxtreme.setup_wait(WT.TIME, ${ctrl.in1})`)
                        codeArray.push(`locoxtreme.move(${ctrl.btn1}, ${ctrl.btn1}, 1, 1, True)`);
                    } else if (ctrl.mode === 'Ultrasonic') {
                        codeArray.push(`# Drive ${ctrl.btn1Txt} at full speed`)
                        codeArray.push(`# Ultrasonic-based wait for ${ctrl.in1} ${ctrl.modeUnits}`)
                        codeArray.push(`locoxtreme.setup_wait(${ctrl.btn2}, ${ctrl.in1})`)
                        codeArray.push(`locoxtreme.move(${ctrl.btn1}, ${ctrl.btn1}, 1, 1, True)`);
                    } else if (ctrl.mode === 'Encoders') {
                        codeArray.push(`# Drive ${ctrl.btn1Txt} at full speed`)
                        codeArray.push(`# Distance-based wait for ${ctrl.in1} ${ctrl.modeUnits}`)
                        codeArray.push(`locoxtreme.setup_wait(WT.DISTANCE, ${ctrl.in1})`)
                        codeArray.push(`locoxtreme.move(${ctrl.btn1}, ${ctrl.btn1}, 1, 1, True)`);
                    } else  if (ctrl.mode === 'Temperature') {
                        // Sensor Reading
                        codeArray.push('')
                        codeArray.push('# Check Temperature Reading (C)')
                        codeArray.push('temp = locoxtreme.get_sensor_value(Data.TEMPERATURE)')
                        // Conditional Logic
                        codeArray.push('')
                        codeArray.push(`# Check If Temperature Is ${ctrl.btn3} ${ctrl.in2} (C)`)
                        codeArray.push(`if (temp ${ctrl.btn3} ${ctrl.in2}):`)
                        if (ctrl.btn4 === true) {
                            codeArray.push(`    # Drive ${ctrl.btn1Txt} at full speed`)
                            codeArray.push(`    # Time-based wait for ${ctrl.in1} seconds`)
                            codeArray.push(`    locoxtreme.setup_wait(WT.TIME, ${ctrl.in1})`)
                            codeArray.push(`    locoxtreme.move(${ctrl.btn1}, ${ctrl.btn1}, 1, 1, True)`);
                        } else {
                            codeArray.push(`    # Drive ${ctrl.btn1Txt} at full speed`)
                            codeArray.push(`    # Distance-based wait for ${ctrl.in1} cm`)
                            codeArray.push(`    locoxtreme.setup_wait(WT.DISTANCE, ${ctrl.in1})`)
                            codeArray.push(`    locoxtreme.move(${ctrl.btn1}, ${ctrl.btn1}, 1, 1, True)`);
                        }
                    }
                    return codeArray;
                },
                text: {'Time': ['Drive', ' for', ' seconds'],
                       'Ultrasonic': ['Drive', ' until object is', 'CM away'], 
                       'Encoders': ['Drive', ' for', 'CM'],
                       'Temperature': ['Drive', ' for', 'if Temperature is ', 'C'],
                    },
                btn1Option1: 'Forward',
                btn1Option2: 'Backward',
                btn1map: {'Forward': 'MD.FORWARD', 'Backward': 'MD.BACKWARD'},
                btn2Option1: 'greater than',
                btn2Option2: 'less than',
                btn2map: {'greater than': 'WT.ULTRASONIC_GREATER_THAN', 'less than': 'WT.ULTRASONIC_LESS_THAN'},
                btn3Option1: 'greater than',
                btn3Option2: 'less than',
                btn3map: {'greater than': '>', 'less than': '<'},
                btn4Option1: 'seconds',
                btn4Option2: 'cm',
                btn4map: {'seconds': true, 'cm': false},
                modeOptions: ['Time', 'Ultrasonic', 'Encoders', 'Temperature'],
                modeUnits: {
                    'Time': 'seconds', 
                    'Ultrasonic': 'CM', 
                    'Encoders': 'CM'
                },
                modeIndices: 4,
                hasImage: '',
                blockImg: logoDrive,
                imageControl1: 'btn1',
            },
            irotate: {
                id: 0,
                title: 'Rotate',
                height: 200, 
                color: '#49b050',
                in1Width: '35px',
                in1Scale: 1000,
                in2Width: '35px',
                in2Scale: 1000,
                control: {
                    in1: 0, 
                    btn1: 'right', btn1Txt: 'Clockwise (CW)', 
                    btn2: 0, btn2Txt: "greater than", 
                    in2: 0,
                    btn3: 0, btn3Txt: 'greater than',
                    btn4: 0, btn4Txt: 'seconds',
                    mode: 'Time', modeIndex: 0, modeUnits: 'seconds', imageState1: 0,},
                codeRef(ctrl) {
                    let codeArray = [];                    
                    if (ctrl.mode === 'Time') {
                        codeArray.push(`# Rotate ${ctrl.btn1Txt} at full speed`)
                        codeArray.push(`# Time-based wait for ${ctrl.in1} ${ctrl.modeUnits}`)
                        codeArray.push(`locoxtreme.setup_wait(WT.TIME, ${ctrl.in1})`)
                        if (ctrl.btn1 === 'right') {
                            codeArray.push(`locoxtreme.move(MD.FORWARD, MD.BACKWARD, 1, 1, True)`);
                        } else {
                            codeArray.push(`locoxtreme.move(MD.BACKWARD, MD.FORWARD, 1, 1, True)`);
                        }
                    } else if (ctrl.mode === 'Ultrasonic') {
                        codeArray.push(`# Rotate ${ctrl.btn1Txt} at full speed`)
                        codeArray.push(`# Ultrasonic-based wait for ${ctrl.in1} ${ctrl.modeUnits}`)
                        codeArray.push(`locoxtreme.setup_wait(${ctrl.btn2}, ${ctrl.in1})`)
                        if (ctrl.btn1 === 'right') {
                            codeArray.push(`locoxtreme.move(MD.FORWARD, MD.BACKWARD, 1, 1, True)`);
                        } else {
                            codeArray.push(`locoxtreme.move(MD.BACKWARD, MD.FORWARD, 1, 1, True)`);
                        }
                    } else if (ctrl.mode === 'Encoders') {
                        codeArray.push(`# Rotate ${ctrl.btn1Txt} at full speed`)
                        codeArray.push(`# Angle-based wait for ${ctrl.in1} ${ctrl.modeUnits}`)
                        codeArray.push(`locoxtreme.setup_wait(WT.ROTATION, ${ctrl.in1})`)
                        if (ctrl.btn1 === 'right') {
                            codeArray.push(`locoxtreme.move(MD.FORWARD, MD.BACKWARD, 1, 1, True)`);
                        } else {
                            codeArray.push(`locoxtreme.move(MD.BACKWARD, MD.FORWARD, 1, 1, True)`);
                        }
                    } else if (ctrl.mode === 'Temperature') {
                        // Sensor Reading
                        codeArray.push('')
                        codeArray.push('# Check Temperature Reading (C)')
                        codeArray.push('temp = locoxtreme.get_sensor_value(Data.TEMPERATURE)')
                        // Conditional Logic
                        codeArray.push('')
                        codeArray.push(`# Check If Temperature Is ${ctrl.btn3} ${ctrl.in2} (C)`)
                        codeArray.push(`if (temp ${ctrl.btn3} ${ctrl.in2}):`)
                        if (ctrl.btn4 === true) {
                            codeArray.push(`    # Time-based wait for ${ctrl.in1} seconds`)
                            codeArray.push(`    locoxtreme.setup_wait(WT.TIME, ${ctrl.in1})`)
                            if (ctrl.btn1 === 'right') {
                                codeArray.push(`    locoxtreme.move(MD.FORWARD, MD.BACKWARD, 1, 1, True)`);
                            } else {
                                codeArray.push(`    locoxtreme.move(MD.BACKWARD, MD.FORWARD, 1, 1, True)`);
                            }
                        } else {
                            codeArray.push(`    # Angle-based wait for ${ctrl.in1} degrees`)
                            codeArray.push(`    locoxtreme.setup_wait(WT.ROTATION, ${ctrl.in1})`)
                            if (ctrl.btn1 === 'right') {
                                codeArray.push(`    locoxtreme.move(MD.FORWARD, MD.BACKWARD, 1, 1, True)`);
                            } else {
                                codeArray.push(`    locoxtreme.move(MD.BACKWARD, MD.FORWARD, 1, 1, True)`);
                            }
                        }
                    }
                    return codeArray;
                },  
                text: {
                    'Time': ['Rotate', ' for', ' seconds'], 
                    'Ultrasonic': ['Rotate', ' until object is', 'CM away'], 
                    'Encoders': ['Rotate', ' for', 'degrees'],
                    'Temperature': ['Rotate', ' for', 'if Temperature is ', 'C'],
                },
                btn1Option1: 'Clockwise (CW)',
                btn1Option2: 'Counter-CW',
                btn1map: {'Clockwise (CW)': 'right', 'Counter-CW': 'left'},
                btn2Option1: 'greater than',
                btn2Option2: 'less than',
                btn2map: {'greater than': 'WT.ULTRASONIC_GREATER_THAN', 'less than': 'WT.ULTRASONIC_LESS_THAN'},
                btn3Option1: 'greater than',
                btn3Option2: 'less than',
                btn3map: {'greater than': '>', 'less than': '<'},
                btn4Option1: 'seconds',
                btn4Option2: 'degrees',
                btn4map: {'seconds': true, 'degrees': false},
                modeOptions: ['Time', 'Ultrasonic', 'Encoders', 'Temperature'],
                modeUnits: {'Time': 'seconds', 'Ultrasonic': 'CM', 'Encoders': 'degrees'},
                modeIndices: 4,
                hasImage: 'Y',
                blockImg: logoRotate,
                imageControl1: 'btn1',
                heightMode: {'Time': 200, 'Ultrasonic': 200, 'Encoders': 200, 'Temperature': 250}
            },
            imove: {
                id: 0,
                title: 'Move',
                height: 310, 
                color: '#13988a',
                in1Width: '32px',
                in2Width: '32px',
                in4Width: '32px',
                in1Scale: 0.01,
                in2Scale: 0.01,
                in3Scale: 1000,
                in4Scale: 1,
                control: {
                    in1: 0, in2: 0, in3: 0, in4: 0,
                    btn1: 0, btn1Txt: 'Forward', btn2: 0, btn2Txt: 'Forward', 
                    btn3: 0, btn3Txt: "greater than", btn4: 0, btn4Txt: 'greater than',
                    mode: 'Time', modeIndex: 0, modeUnits: 'seconds', imageState1: 0, imageState2: 0,
                },
                codeRef(ctrl) {
                    let codeArray = [];                    
                    codeArray.push(`# Left Motor: ${ctrl.btn1Txt} at ${Number(ctrl.in1)}% power`)
                    codeArray.push(`# Right Motor: ${ctrl.btn2Txt} at ${Number(ctrl.in2)}% power`)

                    if (ctrl.mode === 'Time') {
                        codeArray.push(`# Time-based wait for ${ctrl.in3} seconds`)
                        codeArray.push(`locoxtreme.setup_wait(WT.TIME, ${ctrl.in3})`)
                        codeArray.push(`locoxtreme.move(${ctrl.btn1}, ${ctrl.btn2}, ${Number(ctrl.in1) / 100.0}, ${Number(ctrl.in2) / 100.0}, True)`);
                    } else if (ctrl.mode === 'Ultrasonic') {
                        codeArray.push(`# Ultrasonic-based wait for ${ctrl.in3} CM`)
                        codeArray.push(`locoxtreme.setup_wait(${ctrl.btn3}, ${ctrl.in3})`)
                        codeArray.push(`locoxtreme.move(${ctrl.btn1}, ${ctrl.btn2}, ${Number(ctrl.in1) / 100.0}, ${Number(ctrl.in2) / 100.0}, True)`);
                    } else if (ctrl.mode === 'Temperature') {
                        // Sensor Reading
                        codeArray.push('')
                        codeArray.push('# Check Temperature Reading (C)')
                        codeArray.push('temp = locoxtreme.get_sensor_value(Data.TEMPERATURE)')
                        // Conditional Logic
                        codeArray.push('')
                        codeArray.push(`# Check If Temperature Is ${ctrl.btn4} ${ctrl.in4} (C)`)
                        codeArray.push(`if (temp ${ctrl.btn4} ${ctrl.in4}):`)
                        // Move Logic
                        codeArray.push(`    # Time-based wait for ${ctrl.in3} seconds`)
                        codeArray.push(`    locoxtreme.setup_wait(WT.TIME, ${ctrl.in3})`)
                        codeArray.push(`    locoxtreme.move(${ctrl.btn1}, ${ctrl.btn2}, ${Number(ctrl.in1) / 100.0}, ${Number(ctrl.in2) / 100.0}, True)`);
                    }
                    
                    return codeArray;
                },
                text: {
                    'Time': ['Left motor', ' at', '% power', 'Right motor', 'at', '% power', 'for', 'seconds'], 
                    'Ultrasonic': ['Left motor', ' at', '% power', 'Right motor', 'at', '% power', 'until object is ', 'CM away'],
                    'Temperature': ['Left motor', ' at', '% power', 'Right motor', 'at', '% power', 'for', 'seconds', 'if Temperature is ', 'C'],
                },
                btn1Option1: 'Forward',
                btn1Option2: 'Backward',
                btn1map: {'Forward': 'MD.FORWARD', 'Backward': 'MD.BACKWARD'},
                btn2Option1: 'Forward',
                btn2Option2: 'Backward',
                btn2map: {'Forward': 'MD.FORWARD', 'Backward': 'MD.BACKWARD'},
                btn3Option1: 'greater than',
                btn3Option2: 'less than',
                btn3map: {'greater than': 'WT.ULTRASONIC_GREATER_THAN', 'less than': 'WT.ULTRASONIC_LESS_THAN'},
                btn4Option1: 'greater than',
                btn4Option2: 'less than',
                btn4map: {'greater than': '>', 'less than': '<'},
                in1Limits: 100,
                in2Limits: 100,
                modeOptions: ['Time', 'Ultrasonic', 'Temperature'],
                modeUnits: {'Time': 'seconds', 'Ultrasonic': 'CM',},
                modeIndices: 3,
                hasImage: '',
                blockImg: logoMove,
                imageControl1: 'btn1',
                imageControl2: 'btn2',
                heightMode: {'Time': 310, 'Ultrasonic': 310, 'Temperature': 370}
            },
            iloop:
            {
                id: 0,
                title: 'Loop',
                height: 200, 
                color: '#8057c1',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Loop for ${ctrl.in1} times`, `for i in range(${ctrl.in1}):`]
                },
                text: ['Repeat ', 'times'],
                code: [],
                in1Width: '70px',
                in1Type: 'int',
                text1: 'Repeat',
                text2: 'times',
                blockImg: logoLoop,
            },
            iloopend:
            {        
                id: 0,
                title: 'Loop End',
                height: 200, 
                color: '#8057c1',
                control: {},
                code: [],
            },

        },
        renderType: this.props.blockType,
        btn1: 0, btn2: 0, btn3: 0, btn4: 0,
        //colorsMap: {0: 'btn8', 1: 'btn6', 2: 'btn4', 3: 'btn2', 4: 'btn1', 5: 'btn3', 6: 'btn5', 7: 'btn7'},
        colorsMap: {0: 'btn1', 1: 'btn8', 2: 'btn2', 3: 'btn7', 4: 'btn3', 5: 'btn6', 6: 'btn4', 7: 'btn5'},
        circleGrids: [0, 1, 2, 3, 4, 5, 6, 7],
        circleGridWidths: [12, 6, 6, 6, 6, 6, 6, 12],
        circleStates: [0, 0, 0, 0, 0, 0, 0, 0],
        colorBorderOpts: {0: '#000', 1: '#FFF'},
        colorMarginsL: ['0px', '92px', '0px', '66px', '0px', '92px', '0px', '0px', ],
        colorMarginsR: ['0px', '0px', '92px', '0px', '66px', '0px', '92px', '0px', ],
        colorsPosShift: ['11px', '0px', '0px', '0px', '0px', '0px', '0px', '-11px', ],
        circleColor: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
        btn1Txt: '',
        btn2Txt: '',
        btn3Txt: '',
        btn4Txt: '',
        btn9Txt: '',
        btn10Txt: '',
        btn11Txt: '',
        sel1Txt: '',
        sel2Txt: '',
        sel1: 0,
        sel2: 0,
        in1: 0,
        in2: 0,
        in3: 0,
        in4: 0,
        slid1: 255,
        slid2: 255,
        slid3: 255,
        mode: '',
    }


    componentDidMount() {
        let updates = {control: this.props.control};
        this.componentRefresh(updates);
    }

    componentRefresh = (updates) => {
        let tempRenderType = this.state.renderType;
        
        if ('name' in updates) {
            tempRenderType = updates.name;
            this.setState( {renderType: updates.name} );
        }

        let tempLXBlocks = this.state.LXBlocksOptions;
        
        tempLXBlocks[tempRenderType].id = this.props.id
        if ((typeof updates.control !== "undefined") && (Object.keys(updates.control).length !== 0)) {
            let keyNames = Object.keys(updates.control);
            
            keyNames.forEach((val, i) => {
                tempLXBlocks[tempRenderType].control[val] = updates.control[val];
                this.setState( {[val]: updates.control[val]} );
                if (tempLXBlocks[tempRenderType].hasOwnProperty('codeRef')) {
                    tempLXBlocks[tempRenderType].code = tempLXBlocks[tempRenderType].codeRef(tempLXBlocks[tempRenderType].control);
                }
            });
           
            if (tempRenderType === 'ilights_rgb') {
                let tempColors = this.state.circleColor;
                for (let i = 0; i < tempColors.length; i++) {
                    let r = tempLXBlocks[tempRenderType].control[this.state.colorsMap[i]][0];
                    let g = tempLXBlocks[tempRenderType].control[this.state.colorsMap[i]][1];
                    let b = tempLXBlocks[tempRenderType].control[this.state.colorsMap[i]][2];
                    tempColors[i] = rgbToHex(r, g, b);
                }
                this.setState( {circleColor: tempColors} );
            }

            // Find Difference if Any
            const sourceKeys = Object.keys(tempLXBlocks[tempRenderType].control);
            let difference = sourceKeys.filter(x => !keyNames.includes(x));
            // 
            tempLXBlocks = this.componentVerCorrect(tempLXBlocks, difference)

        } else {
            if ('sel1' in tempLXBlocks[tempRenderType].control) {
                this.setState( {sel1Txt: tempLXBlocks[tempRenderType].control.sel1Txt} );
            }
            if ('sel2' in tempLXBlocks[tempRenderType].control) {
                this.setState( {sel2Txt: tempLXBlocks[tempRenderType].control.sel2Txt} );
            }

            if ('btn1' in tempLXBlocks[tempRenderType].control) {
                
                if (tempRenderType !== 'ilights_rgb') {
                    tempLXBlocks[tempRenderType].control.btn1 = tempLXBlocks[tempRenderType].btn1map[tempLXBlocks[tempRenderType].btn1Option1];
                    tempLXBlocks[tempRenderType].control.btn1Txt = tempLXBlocks[tempRenderType].btn1Option1;
                    this.setState( {btn1Txt: tempLXBlocks[tempRenderType].control.btn1Txt} );
                }
            }
            if ('btn2' in tempLXBlocks[tempRenderType].control) {
                
                if (tempRenderType !== 'ilights_rgb') {
                    tempLXBlocks[tempRenderType].control.btn2 = tempLXBlocks[tempRenderType].btn2map[tempLXBlocks[tempRenderType].btn2Option1];
                    tempLXBlocks[tempRenderType].control.btn2Txt = tempLXBlocks[tempRenderType].btn2Option1;
                    this.setState( {btn2Txt: tempLXBlocks[tempRenderType].control.btn2Txt} );
                }
            }
            if ('btn3' in tempLXBlocks[tempRenderType].control) {
                
                if (tempRenderType !== 'ilights_rgb') {
                    tempLXBlocks[tempRenderType].control.btn3 = tempLXBlocks[tempRenderType].btn3map[tempLXBlocks[tempRenderType].btn3Option1];
                    tempLXBlocks[tempRenderType].control.btn3Txt = tempLXBlocks[tempRenderType].btn3Option1;
                    this.setState( {btn3Txt: tempLXBlocks[tempRenderType].control.btn3Txt} );
                }
            }
            if ('btn4' in tempLXBlocks[tempRenderType].control) {
                
                if (tempRenderType !== 'ilights_rgb') {
                    tempLXBlocks[tempRenderType].control.btn4 = tempLXBlocks[tempRenderType].btn4map[tempLXBlocks[tempRenderType].btn4Option1];
                    tempLXBlocks[tempRenderType].control.btn4Txt = tempLXBlocks[tempRenderType].btn4Option1;
                    this.setState( {btn4Txt: tempLXBlocks[tempRenderType].control.btn4Txt} );
                }
            }

            if ('btn9' in tempLXBlocks[tempRenderType].control) {
                tempLXBlocks[tempRenderType].control.btn9 = tempLXBlocks[tempRenderType].btn9map[tempLXBlocks[tempRenderType].btn9Option1];
                tempLXBlocks[tempRenderType].control.btn9Txt = tempLXBlocks[tempRenderType].btn9Option1;
                this.setState( {btn9Txt: tempLXBlocks[tempRenderType].control.btn9Txt} );
            }
            if ('btn10' in tempLXBlocks[tempRenderType].control) {
                tempLXBlocks[tempRenderType].control.btn10 = tempLXBlocks[tempRenderType].btn10map[tempLXBlocks[tempRenderType].btn10Option1];
                tempLXBlocks[tempRenderType].control.btn10Txt = tempLXBlocks[tempRenderType].btn10Option1;
                this.setState( {btn10Txt: tempLXBlocks[tempRenderType].control.btn10Txt} );
            }
            if ('btn11' in tempLXBlocks[tempRenderType].control) {
                tempLXBlocks[tempRenderType].control.btn11 = tempLXBlocks[tempRenderType].btn11map[tempLXBlocks[tempRenderType].btn11Option1];
                tempLXBlocks[tempRenderType].control.btn11Txt = tempLXBlocks[tempRenderType].btn11Option1;
                this.setState( {btn11Txt: tempLXBlocks[tempRenderType].control.btn11Txt} );
            }

            if ('mode' in tempLXBlocks[tempRenderType].control) {
                this.setState( {mode: tempLXBlocks[tempRenderType].control.mode} );
            }

            /////******/
            
            if (tempLXBlocks[tempRenderType].hasOwnProperty('codeRef')) {
                tempLXBlocks[tempRenderType].code = tempLXBlocks[tempRenderType].codeRef(tempLXBlocks[tempRenderType].control);
            }

        }

        if (tempLXBlocks[tempRenderType].hasOwnProperty('heightMode')) {
            tempLXBlocks[tempRenderType].height = 
                tempLXBlocks[tempRenderType].heightMode[tempLXBlocks[tempRenderType].control.mode]
        }

        this.setState( {LXBlocksOptions: tempLXBlocks} );
        this.props.handleSubState(tempLXBlocks[tempRenderType]);

    }

    componentVerCorrect = (tempLXBlocks, control) => {

        if (control.indexOf('sel1') > -1) {
            this.setState( {sel1Txt: tempLXBlocks[this.state.renderType].control.sel1Txt} );
        }
        if (control.indexOf('sel2') > -1) {
            this.setState( {sel2Txt: tempLXBlocks[this.state.renderType].control.sel2Txt} );
        }
        
        if (control.indexOf('btn1') > -1) {    
            if (this.state.renderType !== 'ilights_rgb') {
                tempLXBlocks[this.state.renderType].control.btn1 = tempLXBlocks[this.state.renderType].btn1map[tempLXBlocks[this.state.renderType].btn1Option1];
                tempLXBlocks[this.state.renderType].control.btn1Txt = tempLXBlocks[this.state.renderType].btn1Option1;
                this.setState( {btn1Txt: tempLXBlocks[this.state.renderType].control.btn1Txt} );
            }
        }
        if (control.indexOf('btn2') > -1) {
            if (this.state.renderType !== 'ilights_rgb') {
                tempLXBlocks[this.state.renderType].control.btn2 = tempLXBlocks[this.state.renderType].btn2map[tempLXBlocks[this.state.renderType].btn2Option1];
                tempLXBlocks[this.state.renderType].control.btn2Txt = tempLXBlocks[this.state.renderType].btn2Option1;
                this.setState( {btn2Txt: tempLXBlocks[this.state.renderType].control.btn2Txt} );
            }
        }
        if (control.indexOf('btn3') > -1) {
            if (this.state.renderType !== 'ilights_rgb') {
                tempLXBlocks[this.state.renderType].control.btn3 = tempLXBlocks[this.state.renderType].btn3map[tempLXBlocks[this.state.renderType].btn3Option1];
                tempLXBlocks[this.state.renderType].control.btn3Txt = tempLXBlocks[this.state.renderType].btn3Option1;
                this.setState( {btn3Txt: tempLXBlocks[this.state.renderType].control.btn3Txt} );
            }
        }
        if (control.indexOf('btn4') > -1) {
            if (this.state.renderType !== 'ilights_rgb') {
                tempLXBlocks[this.state.renderType].control.btn4 = tempLXBlocks[this.state.renderType].btn4map[tempLXBlocks[this.state.renderType].btn4Option1];
                tempLXBlocks[this.state.renderType].control.btn4Txt = tempLXBlocks[this.state.renderType].btn4Option1;
                this.setState( {btn4Txt: tempLXBlocks[this.state.renderType].control.btn4Txt} );
            }
        }
        if (control.indexOf('btn9') > -1) {
            tempLXBlocks[this.state.renderType].control.btn9 = tempLXBlocks[this.state.renderType].btn9map[tempLXBlocks[this.state.renderType].btn9Option1];
            tempLXBlocks[this.state.renderType].control.btn9Txt = tempLXBlocks[this.state.renderType].btn9Option1;
            this.setState( {btn9Txt: tempLXBlocks[this.state.renderType].control.btn9Txt} );
        }
        if (control.indexOf('btn10') > -1) {
            tempLXBlocks[this.state.renderType].control.btn10 = tempLXBlocks[this.state.renderType].btn10map[tempLXBlocks[this.state.renderType].btn10Option1];
            tempLXBlocks[this.state.renderType].control.btn10Txt = tempLXBlocks[this.state.renderType].btn10Option1;
            this.setState( {btn10Txt: tempLXBlocks[this.state.renderType].control.btn10Txt} );
        }
        if (control.indexOf('btn11') > -1) {
            tempLXBlocks[this.state.renderType].control.btn11 = tempLXBlocks[this.state.renderType].btn11map[tempLXBlocks[this.state.renderType].btn11Option1];
            tempLXBlocks[this.state.renderType].control.btn11Txt = tempLXBlocks[this.state.renderType].btn11Option1;
            this.setState( {btn11Txt: tempLXBlocks[this.state.renderType].control.btn11Txt} );
        }

        if (control.indexOf('mode') > -1) {
            this.setState( {mode: tempLXBlocks[this.state.renderType].control.mode} );
        }

        if (tempLXBlocks[this.state.renderType].hasOwnProperty('codeRef')) {
            tempLXBlocks[this.state.renderType].code = tempLXBlocks[this.state.renderType].codeRef(tempLXBlocks[this.state.renderType].control);
        }
        return tempLXBlocks
    }

    handleIn = (event) => {

        let hName = event.target.name;
        let limStr = hName + 'Limits';
        let hType = hName + 'Type';
        let hScale = hName + 'Scale';

        let m = event.target.value;
        if (typeof m[1] !== 'undefined') {
            if (m[1] !== ".") {
                m = m.replace(/^0+/, '');
                if (m === "") {
                    m = "0"
                }
            }
        }
        // remove any letters and special characters from the string
        m = m.replace(/[^0-9.]/g, '');

        let tempLXBlocks = this.state.LXBlocksOptions;
        if (!isNaN(parseFloat(m)) && isFinite(m)) {
            let n = Number(m);
            
            
            if (limStr in tempLXBlocks[this.state.renderType]) {
                if (n > tempLXBlocks[this.state.renderType][limStr]) {
                    n = tempLXBlocks[this.state.renderType][limStr];
                    m = tempLXBlocks[this.state.renderType][limStr];
                }
            }

            /*
            if (hScale in tempLXBlocks[this.state.renderType]) {
                n = n * tempLXBlocks[this.state.renderType][hScale];
            }
            */

            if (tempLXBlocks[this.state.renderType][hType] === 'int') {
                tempLXBlocks[this.state.renderType].control[hName] = Math.floor(n);
                tempLXBlocks[this.state.renderType].code = tempLXBlocks[this.state.renderType].codeRef(tempLXBlocks[this.state.renderType].control);
            } else {
                tempLXBlocks[this.state.renderType].control[hName] = n;
                tempLXBlocks[this.state.renderType].code = tempLXBlocks[this.state.renderType].codeRef(tempLXBlocks[this.state.renderType].control);
            }
            this.setState( {[event.target.name]: m} );
        } else {
            tempLXBlocks[this.state.renderType].control[hName] = 0;
            tempLXBlocks[this.state.renderType].code = tempLXBlocks[this.state.renderType].codeRef(tempLXBlocks[this.state.renderType].control);
            this.setState( {[event.target.name]: 0} );
        }
        this.setState( {LXBlocksOptions: tempLXBlocks} );
        this.props.handleSubState(tempLXBlocks[this.state.renderType]);
    }


    handleSelect = (event) => {

        let hName = event.target.name;
        let hText = hName + 'Txt';
        let hMap = hName + 'map';

        let tempLXBlocks = this.state.LXBlocksOptions;
        if (hMap in tempLXBlocks[this.state.renderType]) {
            tempLXBlocks[this.state.renderType].control[hName] = tempLXBlocks[this.state.renderType][hMap][event.target.value];                
            tempLXBlocks[this.state.renderType].control[hText] = event.target.value;

            this.setState( {[hText]: tempLXBlocks[this.state.renderType].control[hText]} );
        }
        
        tempLXBlocks[this.state.renderType].code = tempLXBlocks[this.state.renderType].codeRef(tempLXBlocks[this.state.renderType].control);
        
        this.setState( {LXBlocksOptions: tempLXBlocks} );
        this.props.handleSubState(tempLXBlocks[this.state.renderType]);
    }


    handleBtn = (event, nameStr) => {
        
        let hName = nameStr;
        let hText = hName + 'Txt';
        let hMap = hName + 'map';
        let hOpt1 = hName + 'Option1';
        let hOpt2 = hName + 'Option2';

        let tempLXBlocks = this.state.LXBlocksOptions;

        if (hMap in tempLXBlocks[this.state.renderType]) {
            if (tempLXBlocks[this.state.renderType].control[hText] === tempLXBlocks[this.state.renderType][hOpt1]) {
                tempLXBlocks[this.state.renderType].control[hName] = tempLXBlocks[this.state.renderType][hMap][tempLXBlocks[this.state.renderType][hOpt2]];
                tempLXBlocks[this.state.renderType].control[hText] = tempLXBlocks[this.state.renderType][hOpt2];
            } else {
                tempLXBlocks[this.state.renderType].control[hName] = tempLXBlocks[this.state.renderType][hMap][tempLXBlocks[this.state.renderType][hOpt1]];
                tempLXBlocks[this.state.renderType].control[hText] = tempLXBlocks[this.state.renderType][hOpt1];
            }                    
            this.setState( {[hText]: tempLXBlocks[this.state.renderType].control[hText]} );
        }

        tempLXBlocks[this.state.renderType].code = tempLXBlocks[this.state.renderType].codeRef(tempLXBlocks[this.state.renderType].control);
        
        if ('imageState1' in tempLXBlocks[this.state.renderType].control) {
            if (tempLXBlocks[this.state.renderType].imageControl1 === hName) {
                tempLXBlocks[this.state.renderType].control.imageState1 = 180 - tempLXBlocks[this.state.renderType].control.imageState1;
            }
        }
        if ('imageState2' in tempLXBlocks[this.state.renderType].control) {
            if (tempLXBlocks[this.state.renderType].imageControl2 === hName) {
                tempLXBlocks[this.state.renderType].control.imageState2 = 180 - tempLXBlocks[this.state.renderType].control.imageState2;
            }
        }

        this.setState( {LXBlocksOptions: tempLXBlocks} );
        this.props.handleSubState(tempLXBlocks[this.state.renderType]);
    }


    handleSlider = (event, value, name) => {
        
        this.setState({[name]: value});
        
        let tempLXBlocks = this.state.LXBlocksOptions;
        
        let tempColors = this.state.circleColor;
        for (let i = 0; i < this.state.circleStates.length; i++) {
            if (this.state.circleStates[i] === 0) {

                tempColors[i] = rgbToHex(this.state.slid1, this.state.slid2, this.state.slid3)
                tempLXBlocks[this.state.renderType].control[this.state.colorsMap[i]][0] = this.state.slid1;
                tempLXBlocks[this.state.renderType].control[this.state.colorsMap[i]][1] = this.state.slid2;
                tempLXBlocks[this.state.renderType].control[this.state.colorsMap[i]][2] = this.state.slid3;
            }
        }
        this.setState( {circleColor: tempColors} );

        tempLXBlocks[this.state.renderType].code = tempLXBlocks[this.state.renderType].codeRef(tempLXBlocks[this.state.renderType].control);
        
        this.setState( {LXBlocksOptions: tempLXBlocks} );
        this.props.handleSubState(tempLXBlocks[this.state.renderType]);
    }

    handleColorState = (event, val) => {
        let tempStates = this.state.circleStates;
        tempStates[val] = 1 - tempStates[val];
        this.setState( {circleStates: tempStates} );
    }


    handleMode = (event, name) => {

        let tempLXBlocks = this.state.LXBlocksOptions;

        tempLXBlocks[this.state.renderType].control.modeIndex++;
        if (tempLXBlocks[this.state.renderType].control.modeIndex >= tempLXBlocks[this.state.renderType].modeIndices) {
            tempLXBlocks[this.state.renderType].control.modeIndex = 0;
        }

        tempLXBlocks[this.state.renderType].control.mode = tempLXBlocks[this.state.renderType].modeOptions[tempLXBlocks[this.state.renderType].control.modeIndex];

        tempLXBlocks[this.state.renderType].control.modeUnits = tempLXBlocks[this.state.renderType].modeUnits[tempLXBlocks[this.state.renderType].control.mode];

        tempLXBlocks[this.state.renderType].code = tempLXBlocks[this.state.renderType].codeRef(tempLXBlocks[this.state.renderType].control);

        if (tempLXBlocks[this.state.renderType].hasOwnProperty('heightMode')) {
            tempLXBlocks[this.state.renderType].height = 
                tempLXBlocks[this.state.renderType].heightMode[tempLXBlocks[this.state.renderType].control.mode]
        }

        this.setState( {LXBlocksOptions: tempLXBlocks, mode: tempLXBlocks[this.state.renderType].control.mode} );
        this.props.handleSubState(tempLXBlocks[this.state.renderType]);
    }




    
    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Box 
                    
                    style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                >
                    <Typography 
                        className={classes.titleText}
                        variant='h4'
                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                    >
                        {this.state.LXBlocksOptions[this.state.renderType].title}
                    </Typography>
                    {this.state.renderType === 'inote' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs={3}>
                            <img 
                                src={this.state.LXBlocksOptions[this.state.renderType].blockImg} 
                                style={{
                                    transform: `translate(-20px)`, 
                                    width:'110px', height: '110px', 
                                    position: 'relative',
                                }} 
                                alt={this.state.LXBlocksOptions[this.state.renderType].title} 
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs={12}>
                                <Grid 
                                    container 
                                    spacing={0}
                                    className={classes.grid}
                                    direction="row"
                                >
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Select
                                        className={classes.textSelect}
                                        style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                        onClick={this.handleSelect}
                                        disabled={this.props.isDisabled}
                                        name='sel1'
                                        value={this.state.sel1Txt}
                                    >
                                    {this.state.LXBlocksOptions[this.state.renderType].sel1Options.map((val) => {
                                        return (
                                            <MenuItem 
                                                key = {'sel1' + val} 
                                                value = {val}
                                                style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color, color: "white"}}
                                            >{val}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[1]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Select
                                            className={classes.textSelect}
                                            style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                            onClick={this.handleSelect}
                                            disabled={this.props.isDisabled}
                                            name='sel2'
                                            value={this.state.sel2Txt}
                                        >
                                        {this.state.LXBlocksOptions[this.state.renderType].sel2Options.map((val) => {
                                            return (
                                                <MenuItem 
                                                    key = {'sel2' + val} 
                                                    value = {val}
                                                    style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color, color: "white"}}
                                                >{val}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[2]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Input 
                                        className={classes.inputField}
                                        name='in1'
                                        style={{width: this.state.LXBlocksOptions[this.state.renderType].in1Width}}
                                        onChange = {this.handleIn}
                                        value={this.state.in1}
                                        disabled={this.props.isDisabled}
                                    />
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[3]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button
                                        className={classes.textButton}
                                        style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                        variant="contained"
                                        onClick={(e) => this.handleBtn(e, 'btn1')}
                                        disabled={this.props.isDisabled}
                                    >
                                        {this.state.btn1Txt}
                                    </Button>
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[4]}
                                    </Typography>
                                </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    )}
                    {this.state.renderType === 'isong' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs={3}>
                            <img 
                                src={this.state.LXBlocksOptions[this.state.renderType].blockImg} 
                                style={{
                                    transform: `translate(-20px)`, 
                                    width:'110px', height: '110px', 
                                    position: 'relative',
                                }} 
                                alt={this.state.LXBlocksOptions[this.state.renderType].title} 
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs={12}>
                                <Grid 
                                    container 
                                    spacing={0}
                                    className={classes.grid}
                                    direction="row"
                                >
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Select
                                        className={classes.textSelect}
                                        style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                        onClick={this.handleSelect}
                                        disabled={this.props.isDisabled}
                                        name='sel1'
                                        value={this.state.sel1Txt}
                                    >
                                    {this.state.LXBlocksOptions[this.state.renderType].sel1Options.map((val) => {
                                        return (
                                            <MenuItem 
                                                key = {'sel1' + val} 
                                                value = {val}
                                                style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color, color: "white"}}
                                            >{val}</MenuItem>
                                        );
                                    })}
                                    </Select>
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[1]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button
                                        className={classes.textButton}
                                        style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                        variant="contained"
                                        onClick={(e) => this.handleBtn(e, 'btn1')}
                                        disabled={this.props.isDisabled}
                                    >
                                        {this.state.btn1Txt}
                                    </Button>
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[2]}
                                    </Typography>
                                </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    )}
                    {(this.state.renderType === 'ipause' || this.state.renderType === 'idrive' || this.state.renderType === 'irotate') && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs={3}>
                            <img 
                                src={this.state.LXBlocksOptions[this.state.renderType].blockImg} 
                                style={{
                                    transform: `translate(-20px) rotate${this.state.LXBlocksOptions[this.state.renderType].hasImage}(${this.state.LXBlocksOptions[this.state.renderType].control.imageState1}deg)`, 
                                    width:'110px', height: '110px', 
                                    position: 'relative',
                                }} 
                                alt={this.state.LXBlocksOptions[this.state.renderType].title} 
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs={12} style={{position:'relative', top:'-35px',}}>
                                <Box display="flex" flexDirection="row-reverse">
                                <Button
                                    className={classes.textButton}
                                    style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                    variant="contained"
                                    onClick={(e) => this.handleMode(e, 'mode')}
                                    disabled={this.props.isDisabled}
                                >
                                    {this.state.mode}
                                </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{position:'relative', top:'-25px',}}>
                                <Grid 
                                    container 
                                    spacing={0}
                                    direction="row"
                                >
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][0]}
                                    </Typography>
                                </Grid>
                                {(this.state.renderType === 'idrive' || this.state.renderType === 'irotate') && (
                                    <Grid item xs="auto">
                                        <Button
                                            className={classes.textButton}
                                            style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                            variant="contained"
                                            onClick={(e) => this.handleBtn(e, 'btn1')}
                                            disabled={this.props.isDisabled}
                                        >
                                            {this.state.btn1Txt}
                                        </Button>
                                    </Grid>
                                )}
                                {(this.state.renderType === 'idrive' || this.state.renderType === 'irotate') && (
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][1]}
                                    </Typography>
                                </Grid>
                                )}
                                {this.state.LXBlocksOptions[this.state.renderType].control.mode === 'Ultrasonic' && (
                                    <Grid item xs="auto">
                                        {(this.state.renderType === 'ipause') && (
                                        <Button
                                            className={classes.textButton}
                                            style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                            variant="contained"
                                            onClick={(e) => this.handleBtn(e, 'btn1')}
                                            disabled={this.props.isDisabled}
                                        >
                                            {this.state.btn1Txt}
                                        </Button>
                                        )}
                                        {(this.state.renderType === 'idrive' || this.state.renderType === 'irotate') && (
                                        <Button
                                            className={classes.textButton}
                                            style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                            variant="contained"
                                            onClick={(e) => this.handleBtn(e, 'btn2')}
                                            disabled={this.props.isDisabled}
                                        >
                                            {this.state.btn2Txt}
                                        </Button>
                                        )}
                                    </Grid>
                                )}
                                <Grid item xs="auto">
                                    <Input 
                                        className={classes.inputField}
                                        name='in1'
                                        style={{width: this.state.LXBlocksOptions[this.state.renderType].in1Width}}
                                        onChange = {this.handleIn}
                                        value={this.state.in1}
                                        disabled={this.props.isDisabled}
                                    />
                                </Grid>
                                {this.state.LXBlocksOptions[this.state.renderType].control.mode === 'Temperature' && (this.state.renderType !== 'ipause') && (
                                    <Grid item xs="auto">
                                        <Button
                                            className={classes.textButton}
                                            style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                            variant="contained"
                                            onClick={(e) => this.handleBtn(e, 'btn4')}
                                            disabled={this.props.isDisabled}
                                        >
                                            {this.state.btn4Txt}
                                        </Button>
                                    </Grid>
                                )}
                                {this.state.LXBlocksOptions[this.state.renderType].control.mode === 'Temperature' && (this.state.renderType === 'ipause') && (
                                    <Grid item xs="auto">
                                        <Typography 
                                            className={classes.regularText}
                                            style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                        >
                                            {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][1]}
                                        </Typography>
                                    </Grid>
                                )}
                                {this.state.LXBlocksOptions[this.state.renderType].control.mode === 'Temperature' && (this.state.renderType === 'ipause') && (
                                    <Grid item xs="auto">
                                        <Typography 
                                            className={classes.regularText}
                                            style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                        >
                                            {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][2]}
                                        </Typography>
                                    </Grid>
                                )}
                                {(this.state.renderType === 'idrive' || this.state.renderType === 'irotate') && (
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][2]}
                                    </Typography>
                                </Grid>
                                )}
                                {this.state.LXBlocksOptions[this.state.renderType].control.mode === 'Temperature' && (
                                    <Grid item xs="auto">
                                        <Button
                                            className={classes.textButton}
                                            style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                            variant="contained"
                                            onClick={(e) => this.handleBtn(e, 'btn3')}
                                            disabled={this.props.isDisabled}
                                        >
                                            {this.state.btn3Txt}
                                        </Button>
                                        <Input 
                                            className={classes.inputField}
                                            name='in2'
                                            style={{width: this.state.LXBlocksOptions[this.state.renderType].in2Width}}
                                            onChange = {this.handleIn}
                                            value={this.state.in2}
                                            disabled={this.props.isDisabled}
                                        />
                                    </Grid>
                                )}
                                {this.state.LXBlocksOptions[this.state.renderType].control.mode === 'Temperature' && (
                                    <Grid item xs="auto">
                                        <Typography 
                                            className={classes.regularText}
                                            style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                        >
                                            {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][3]}
                                        </Typography>
                                    </Grid>
                                )}
                                {this.state.LXBlocksOptions[this.state.renderType].control.mode !== 'Temperature' && this.state.renderType === 'ipause' && (
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][1]}
                                    </Typography>
                                </Grid>
                                )}
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    )}
                    {this.state.renderType === 'ilights_rgb' && (
                        <Grid 
                          container 
                          spacing={0}
                          className={classes.grid}
                          direction="row"
                        >
                        <Grid item xs={12} style={{position:'relative', top:'-35px',}}>
                            <Box display="flex" flexDirection="row-reverse">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={(e) => this.handleMode(e, 'mode')}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.mode}
                            </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid 
                                container 
                                spacing={0}
                                align = "center" justifyContent = "center"
                                direction="row"
                            >
                            {this.state.circleGrids.map((val) => {
                                return (
                                    <Grid 
                                        item xs={this.state.circleGridWidths[val]} 
                                        key = {'gc' + val}
                                    >
                                        <ListItem
                                            className = {classes.lightIndices}
                                            key = {'c' + val.toString()}
                                            style = {{
                                                border: '2px solid' + this.state.colorBorderOpts[this.state.circleStates[val]],
                                                marginLeft: this.state.colorMarginsL[val],
                                                marginRight: this.state.colorMarginsR[val],
                                                position: "relative",
                                                top: this.state.colorsPosShift[val],
                                                backgroundColor: this.state.circleColor[val],
                                            }}
                                            onClick={(e) => this.handleColorState(e, val)}
                                        >
                                        </ListItem>
                                    </Grid>
                                )
                            })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid 
                                container 
                                spacing={0}
                                justifyContent = "space-around"
                                className={classes.grid}
                                direction="row"
                            >
                            <Grid item xs={3}>
                                <Slider
                                    defaultValue={255}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    value={this.state.slid1}
                                    style={{color:'red'}}
                                    step={1}
                                    min={0}
                                    max={255}
                                    disabled={this.props.isDisabled}
                                    onChange={(event, value) => this.handleSlider(event, value, 'slid1')}
                                    onChangeCommitted={(event, value) => this.handleSlider(event, value, 'slid1')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Slider
                                    defaultValue={255}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    value={this.state.slid2}
                                    style={{color:'green'}}
                                    step={1}
                                    min={0}
                                    max={255}
                                    disabled={this.props.isDisabled}
                                    onChange={(event, value) => this.handleSlider(event, value, 'slid2')}
                                    onChangeCommitted={(event, value) => this.handleSlider(event, value, 'slid2')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Slider
                                    defaultValue={255}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    value={this.state.slid3}
                                    style={{color:'blue'}}
                                    step={1}
                                    min={0}
                                    max={255}
                                    disabled={this.props.isDisabled}
                                    onChange={(event, value) => this.handleSlider(event, value, 'slid3')}
                                    onChangeCommitted={(event, value) => this.handleSlider(event, value, 'slid3')}
                                />
                            </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {(this.state.mode !== 'Normal') && (
                                    <Grid
                                        container 
                                        spacing={0}
                                        direction="row"
                                    >
                                    <Grid item xs="auto">
                                        <Button
                                            className={classes.textButton}
                                            style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                            variant="contained"
                                            onClick={(e) => this.handleBtn(e, 'btn9')}
                                            disabled={this.props.isDisabled}
                                        >
                                            {this.state.btn9Txt}
                                        </Button>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Typography 
                                            className={classes.regularText}
                                            style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                        >
                                            {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][0]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Button
                                            className={classes.textButton}
                                            style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                            variant="contained"
                                            onClick={(e) => this.handleBtn(e, 'btn10')}
                                            disabled={this.props.isDisabled}
                                        >
                                            {this.state.btn10Txt}
                                        </Button>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Button
                                            className={classes.textButton}
                                            style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                            variant="contained"
                                            onClick={(e) => this.handleBtn(e, 'btn11')}
                                            disabled={this.props.isDisabled}
                                        >
                                            {this.state.btn11Txt}
                                        </Button>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Input 
                                            className={classes.inputField}
                                            name='in1'
                                            style={{width: this.state.LXBlocksOptions[this.state.renderType].in1Width}}
                                            onChange = {this.handleIn}
                                            value={this.state.in1}
                                            disabled={this.props.isDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Typography 
                                            className={classes.regularText}
                                            style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                        >
                                            {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][1]}
                                        </Typography>
                                    </Grid>

                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        </Grid>
                    )}
                    {this.state.renderType === 'iloop' && (

                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                            <Grid item xs={3}>
                                <img 
                                    src={this.state.LXBlocksOptions[this.state.renderType].blockImg} 
                                    style={{
                                        transform: `translate(-20px)`, 
                                        width:'110px', height: '110px', 
                                        position: 'relative',
                                    }} 
                                    alt={this.state.LXBlocksOptions[this.state.renderType].title} 
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Grid 
                                    container 
                                    spacing={0}
                                    direction="row"
                                >
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Input 
                                        className={classes.inputField}
                                        name='in1'
                                        style={{width: this.state.LXBlocksOptions[this.state.renderType].in1Width}}
                                        onChange = {this.handleIn}
                                        value={this.state.in1}
                                        disabled={this.props.isDisabled}
                                    />
                                </Grid>
                                <Grid item xs="auto">
                                    <Typography 
                                        className={classes.regularText}
                                        style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                    >
                                        {this.state.LXBlocksOptions[this.state.renderType].text[1]}
                                    </Typography>
                                </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {this.state.renderType === 'imove' && (
                        <Grid 
                            container 
                            spacing={0}
                            direction="row"
                        >
                        <Grid item xs={12} style={{position:'relative', top:'-35px',}}>
                            <Box display="flex" flexDirection="row-reverse">
                                <Button
                                    className={classes.textButton}
                                    style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                    variant="contained"
                                    onClick={(e) => this.handleMode(e, 'mode')}
                                    disabled={this.props.isDisabled}
                                >
                                    {this.state.mode}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6} style={{position:'relative', top:'-25px',}}>
                            <img 
                                src={this.state.LXBlocksOptions[this.state.renderType].blockImg} 
                                style={{
                                    transform: `translate(25px) rotate(${this.state.LXBlocksOptions[this.state.renderType].control.imageState1}deg)`, 
                                    width:'110px', height: '110px', 
                                    position: 'relative',
                                }} 
                                alt={this.state.LXBlocksOptions[this.state.renderType].title} 
                            />
                        </Grid>
                        <Grid item xs={6} style={{position:'relative', top:'-25px',}}>
                            <img 
                                src={this.state.LXBlocksOptions[this.state.renderType].blockImg} 
                                style={{
                                    transform: `translate(25px) rotate(${this.state.LXBlocksOptions[this.state.renderType].control.imageState2}deg)`, 
                                    width:'110px', height: '110px', 
                                    position: 'relative',
                                }} 
                                alt={this.state.LXBlocksOptions[this.state.renderType].title} 
                            />
                        </Grid>
                        <Grid item xs={12} style={{position:'relative', top:'-25px', transform: `translate(-10px)`}}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs="auto">
                                <Typography 
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][0]}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <Button
                                    className={classes.textButton}
                                    style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                    variant="contained"
                                    onClick={(e) => this.handleBtn(e, 'btn1')}
                                    disabled={this.props.isDisabled}
                                >
                                    {this.state.btn1Txt}
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Typography 
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][1]}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <Input 
                                    className={classes.inputField}
                                    name='in1'
                                    style={{width: this.state.LXBlocksOptions[this.state.renderType].in1Width}}
                                    onChange = {this.handleIn}
                                    value={this.state.in1}
                                    disabled={this.props.isDisabled}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <Typography 
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][2]}
                                </Typography>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{position:'relative', top:'-25px', transform: `translate(-10px)`}}>
                            <Grid 
                                wrap="nowrap" 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs="auto">
                                <Typography 
                                    noWrap
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][3]}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <Button
                                    className={classes.textButton}
                                    style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                    variant="contained"
                                    onClick={(e) => this.handleBtn(e, 'btn2')}
                                    disabled={this.props.isDisabled}
                                >
                                    {this.state.btn2Txt}
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Typography 
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][4]}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <Input 
                                    className={classes.inputField}
                                    name='in2'
                                    style={{width: this.state.LXBlocksOptions[this.state.renderType].in2Width}}
                                    onChange = {this.handleIn}
                                    value={this.state.in2}
                                    disabled={this.props.isDisabled}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <Typography
                                    noWrap
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][5]}
                                </Typography>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{position:'relative', top:'-25px', transform: `translate(-10px)`}}>
                            <Grid 
                                wrap="nowrap" 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs="auto">
                                <Typography 
                                    noWrap
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][6]}
                                </Typography>
                            </Grid>
                            {this.state.LXBlocksOptions[this.state.renderType].control.mode === 'Ultrasonic' && (
                                <Grid item xs="auto">
                                    <Button
                                        className={classes.textButton}
                                        style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color, whiteSpace: 'nowrap'}}
                                        variant="contained"
                                        onClick={(e) => this.handleBtn(e, 'btn3')}
                                        disabled={this.props.isDisabled}
                                    >
                                        {this.state.btn3Txt}
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs="auto">
                                <Input 
                                    className={classes.inputField}
                                    name='in3'
                                    style={{width: this.state.LXBlocksOptions[this.state.renderType].in2Width}}
                                    onChange = {this.handleIn}
                                    value={this.state.in3}
                                    disabled={this.props.isDisabled}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <Typography 
                                    noWrap={true}
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][7]}
                                </Typography>
                            </Grid>
                            </Grid>
                            {this.state.LXBlocksOptions[this.state.renderType].control.mode === 'Temperature' && (    
                                <Grid item xs={12} >
                                    <Grid 
                                        wrap="nowrap" 
                                        container 
                                        spacing={0}
                                        direction="row"
                                    >
                                    <Grid item xs="auto">
                                        <Typography 
                                            className={classes.regularText}
                                            style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                        >
                                            {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][8]}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Button
                                            className={classes.textButton}
                                            style={{backgroundColor:this.state.LXBlocksOptions[this.state.renderType].color}}
                                            variant="contained"
                                            onClick={(e) => this.handleBtn(e, 'btn4')}
                                            disabled={this.props.isDisabled}
                                        >
                                            {this.state.btn4Txt}
                                        </Button>
                                        <Input 
                                            className={classes.inputField}
                                            name='in4'
                                            style={{width: this.state.LXBlocksOptions[this.state.renderType].in4Width}}
                                            onChange = {this.handleIn}
                                            value={this.state.in4}
                                            disabled={this.props.isDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Typography 
                                            className={classes.regularText}
                                            style={{backgroundColor: this.state.LXBlocksOptions[this.state.renderType].color}}
                                        >
                                            {this.state.LXBlocksOptions[this.state.renderType].text[this.state.LXBlocksOptions[this.state.renderType].control.mode][9]}
                                        </Typography>
                                    </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        </Grid>
                    )}
                </Box>
            </React.Fragment>
        );
    }


}

export default withStyles(styles)(LocoXtremeBlocks);
