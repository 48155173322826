export const param_toc_cached = 
{
    "activeMarker": {
        "front": {
            "ident": 0,
            "persistent": false,
            "extended": true,
            "group": "activeMarker",
            "name": "front",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "back": {
            "ident": 1,
            "persistent": false,
            "extended": true,
            "group": "activeMarker",
            "name": "back",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "left": {
            "ident": 2,
            "persistent": false,
            "extended": true,
            "group": "activeMarker",
            "name": "left",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "right": {
            "ident": 3,
            "persistent": false,
            "extended": true,
            "group": "activeMarker",
            "name": "right",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "mode": {
            "ident": 4,
            "persistent": false,
            "extended": true,
            "group": "activeMarker",
            "name": "mode",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "poll": {
            "ident": 5,
            "persistent": false,
            "extended": false,
            "group": "activeMarker",
            "name": "poll",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "deck": {
        "bcActiveMarker": {
            "ident": 6,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcActiveMarker",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcAI": {
            "ident": 7,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcAI",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcBuzzer": {
            "ident": 8,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcBuzzer",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcFlow": {
            "ident": 9,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcFlow",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcFlow2": {
            "ident": 10,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcFlow2",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcLedRing": {
            "ident": 11,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcLedRing",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcLighthouse4": {
            "ident": 12,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcLighthouse4",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcDWM1000": {
            "ident": 13,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcDWM1000",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcLoco": {
            "ident": 14,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcLoco",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcMultiranger": {
            "ident": 15,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcMultiranger",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcOA": {
            "ident": 16,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcOA",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcUSD": {
            "ident": 17,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcUSD",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcZRanger": {
            "ident": 18,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcZRanger",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "bcZRanger2": {
            "ident": 19,
            "persistent": false,
            "extended": false,
            "group": "deck",
            "name": "bcZRanger2",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        }
    },
    "motion": {
        "disable": {
            "ident": 20,
            "persistent": false,
            "extended": false,
            "group": "motion",
            "name": "disable",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "adaptive": {
            "ident": 21,
            "persistent": false,
            "extended": false,
            "group": "motion",
            "name": "adaptive",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "flowStdFixed": {
            "ident": 22,
            "persistent": false,
            "extended": false,
            "group": "motion",
            "name": "flowStdFixed",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "ring": {
        "effect": {
            "ident": 23,
            "persistent": false,
            "extended": true,
            "group": "ring",
            "name": "effect",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "neffect": {
            "ident": 24,
            "persistent": false,
            "extended": false,
            "group": "ring",
            "name": "neffect",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 1
        },
        "solidRed": {
            "ident": 25,
            "persistent": false,
            "extended": false,
            "group": "ring",
            "name": "solidRed",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "solidGreen": {
            "ident": 26,
            "persistent": false,
            "extended": false,
            "group": "ring",
            "name": "solidGreen",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "solidBlue": {
            "ident": 27,
            "persistent": false,
            "extended": false,
            "group": "ring",
            "name": "solidBlue",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "headlightEnable": {
            "ident": 28,
            "persistent": false,
            "extended": false,
            "group": "ring",
            "name": "headlightEnable",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "emptyCharge": {
            "ident": 29,
            "persistent": false,
            "extended": false,
            "group": "ring",
            "name": "emptyCharge",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "fullCharge": {
            "ident": 30,
            "persistent": false,
            "extended": false,
            "group": "ring",
            "name": "fullCharge",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "fadeColor": {
            "ident": 31,
            "persistent": false,
            "extended": false,
            "group": "ring",
            "name": "fadeColor",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 0
        },
        "fadeTime": {
            "ident": 32,
            "persistent": false,
            "extended": false,
            "group": "ring",
            "name": "fadeTime",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "system": {
        "highlight": {
            "ident": 33,
            "persistent": false,
            "extended": false,
            "group": "system",
            "name": "highlight",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "storageStats": {
            "ident": 34,
            "persistent": false,
            "extended": false,
            "group": "system",
            "name": "storageStats",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "storageReformat": {
            "ident": 35,
            "persistent": false,
            "extended": false,
            "group": "system",
            "name": "storageReformat",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "arm": {
            "ident": 36,
            "persistent": false,
            "extended": false,
            "group": "system",
            "name": "arm",
            "ctype": "int8_t",
            "pytype": "<b",
            "access": 0
        },
        "taskDump": {
            "ident": 37,
            "persistent": false,
            "extended": false,
            "group": "system",
            "name": "taskDump",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "selftestPassed": {
            "ident": 38,
            "persistent": false,
            "extended": false,
            "group": "system",
            "name": "selftestPassed",
            "ctype": "int8_t",
            "pytype": "<b",
            "access": 1
        },
        "assertInfo": {
            "ident": 39,
            "persistent": false,
            "extended": false,
            "group": "system",
            "name": "assertInfo",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "testLogParam": {
            "ident": 40,
            "persistent": false,
            "extended": false,
            "group": "system",
            "name": "testLogParam",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "doAssert": {
            "ident": 41,
            "persistent": false,
            "extended": false,
            "group": "system",
            "name": "doAssert",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "loco": {
        "mode": {
            "ident": 42,
            "persistent": false,
            "extended": false,
            "group": "loco",
            "name": "mode",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "tdoa2": {
        "stddev": {
            "ident": 43,
            "persistent": false,
            "extended": false,
            "group": "tdoa2",
            "name": "stddev",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "tdoa3": {
        "stddev": {
            "ident": 44,
            "persistent": false,
            "extended": false,
            "group": "tdoa3",
            "name": "stddev",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "multiranger": {
        "filterMask": {
            "ident": 45,
            "persistent": false,
            "extended": false,
            "group": "multiranger",
            "name": "filterMask",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "usd": {
        "canLog": {
            "ident": 46,
            "persistent": false,
            "extended": false,
            "group": "usd",
            "name": "canLog",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "logging": {
            "ident": 47,
            "persistent": false,
            "extended": false,
            "group": "usd",
            "name": "logging",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "led": {
        "bitmask": {
            "ident": 48,
            "persistent": false,
            "extended": false,
            "group": "led",
            "name": "bitmask",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "motorPowerSet": {
        "enable": {
            "ident": 49,
            "persistent": false,
            "extended": false,
            "group": "motorPowerSet",
            "name": "enable",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "m1": {
            "ident": 50,
            "persistent": false,
            "extended": false,
            "group": "motorPowerSet",
            "name": "m1",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "m2": {
            "ident": 51,
            "persistent": false,
            "extended": false,
            "group": "motorPowerSet",
            "name": "m2",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "m3": {
            "ident": 52,
            "persistent": false,
            "extended": false,
            "group": "motorPowerSet",
            "name": "m3",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "m4": {
            "ident": 53,
            "persistent": false,
            "extended": false,
            "group": "motorPowerSet",
            "name": "m4",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "pm": {
        "lowVoltage": {
            "ident": 54,
            "persistent": false,
            "extended": true,
            "group": "pm",
            "name": "lowVoltage",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "criticalLowVoltage": {
            "ident": 55,
            "persistent": false,
            "extended": true,
            "group": "pm",
            "name": "criticalLowVoltage",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "imu_sensors": {
        "BMP388": {
            "ident": 56,
            "persistent": false,
            "extended": false,
            "group": "imu_sensors",
            "name": "BMP388",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "imuPhi": {
            "ident": 57,
            "persistent": false,
            "extended": true,
            "group": "imu_sensors",
            "name": "imuPhi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "imuTheta": {
            "ident": 58,
            "persistent": false,
            "extended": true,
            "group": "imu_sensors",
            "name": "imuTheta",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "imuPsi": {
            "ident": 59,
            "persistent": false,
            "extended": true,
            "group": "imu_sensors",
            "name": "imuPsi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "AK8963": {
            "ident": 60,
            "persistent": false,
            "extended": false,
            "group": "imu_sensors",
            "name": "AK8963",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "LPS25H": {
            "ident": 61,
            "persistent": false,
            "extended": false,
            "group": "imu_sensors",
            "name": "LPS25H",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        }
    },
    "imu_tests": {
        "MPU6500": {
            "ident": 62,
            "persistent": false,
            "extended": false,
            "group": "imu_tests",
            "name": "MPU6500",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "AK8963": {
            "ident": 63,
            "persistent": false,
            "extended": false,
            "group": "imu_tests",
            "name": "AK8963",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "LPS25H": {
            "ident": 64,
            "persistent": false,
            "extended": false,
            "group": "imu_tests",
            "name": "LPS25H",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        },
        "imuPhi": {
            "ident": 65,
            "persistent": false,
            "extended": true,
            "group": "imu_tests",
            "name": "imuPhi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "imuTheta": {
            "ident": 66,
            "persistent": false,
            "extended": true,
            "group": "imu_tests",
            "name": "imuTheta",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "imuPsi": {
            "ident": 67,
            "persistent": false,
            "extended": true,
            "group": "imu_tests",
            "name": "imuPsi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "syslink": {
        "probe": {
            "ident": 68,
            "persistent": false,
            "extended": false,
            "group": "syslink",
            "name": "probe",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "usec": {
        "reset": {
            "ident": 69,
            "persistent": false,
            "extended": false,
            "group": "usec",
            "name": "reset",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "colAv": {
        "enable": {
            "ident": 70,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "enable",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "ellipsoidX": {
            "ident": 71,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "ellipsoidX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ellipsoidY": {
            "ident": 72,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "ellipsoidY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ellipsoidZ": {
            "ident": 73,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "ellipsoidZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bboxMinX": {
            "ident": 74,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "bboxMinX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bboxMinY": {
            "ident": 75,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "bboxMinY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bboxMinZ": {
            "ident": 76,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "bboxMinZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bboxMaxX": {
            "ident": 77,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "bboxMaxX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bboxMaxY": {
            "ident": 78,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "bboxMaxY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bboxMaxZ": {
            "ident": 79,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "bboxMaxZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "horizon": {
            "ident": 80,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "horizon",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "maxSpeed": {
            "ident": 81,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "maxSpeed",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "sidestepThrsh": {
            "ident": 82,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "sidestepThrsh",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "maxPeerLocAge": {
            "ident": 83,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "maxPeerLocAge",
            "ctype": "int32_t",
            "pytype": "<i",
            "access": 0
        },
        "vorTol": {
            "ident": 84,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "vorTol",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vorIters": {
            "ident": 85,
            "persistent": false,
            "extended": false,
            "group": "colAv",
            "name": "vorIters",
            "ctype": "int32_t",
            "pytype": "<i",
            "access": 0
        }
    },
    "commander": {
        "enHighLevel": {
            "ident": 86,
            "persistent": false,
            "extended": false,
            "group": "commander",
            "name": "enHighLevel",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "cppm": {
        "rateRoll": {
            "ident": 87,
            "persistent": false,
            "extended": true,
            "group": "cppm",
            "name": "rateRoll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ratePitch": {
            "ident": 88,
            "persistent": false,
            "extended": true,
            "group": "cppm",
            "name": "ratePitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angPitch": {
            "ident": 89,
            "persistent": false,
            "extended": true,
            "group": "cppm",
            "name": "angPitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angRoll": {
            "ident": 90,
            "persistent": false,
            "extended": true,
            "group": "cppm",
            "name": "angRoll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rateYaw": {
            "ident": 91,
            "persistent": false,
            "extended": true,
            "group": "cppm",
            "name": "rateYaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "hlCommander": {
        "vtoff": {
            "ident": 92,
            "persistent": false,
            "extended": false,
            "group": "hlCommander",
            "name": "vtoff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vland": {
            "ident": 93,
            "persistent": false,
            "extended": false,
            "group": "hlCommander",
            "name": "vland",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "flightmode": {
        "althold": {
            "ident": 94,
            "persistent": false,
            "extended": false,
            "group": "flightmode",
            "name": "althold",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "poshold": {
            "ident": 95,
            "persistent": false,
            "extended": false,
            "group": "flightmode",
            "name": "poshold",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "posSet": {
            "ident": 96,
            "persistent": false,
            "extended": false,
            "group": "flightmode",
            "name": "posSet",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "yawMode": {
            "ident": 97,
            "persistent": false,
            "extended": false,
            "group": "flightmode",
            "name": "yawMode",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "stabModeRoll": {
            "ident": 98,
            "persistent": false,
            "extended": false,
            "group": "flightmode",
            "name": "stabModeRoll",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "stabModePitch": {
            "ident": 99,
            "persistent": false,
            "extended": false,
            "group": "flightmode",
            "name": "stabModePitch",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "stabModeYaw": {
            "ident": 100,
            "persistent": false,
            "extended": false,
            "group": "flightmode",
            "name": "stabModeYaw",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "locSrv": {
        "enRangeStreamFP32": {
            "ident": 101,
            "persistent": false,
            "extended": false,
            "group": "locSrv",
            "name": "enRangeStreamFP32",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "enLhAngleStream": {
            "ident": 102,
            "persistent": false,
            "extended": false,
            "group": "locSrv",
            "name": "enLhAngleStream",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "extPosStdDev": {
            "ident": 103,
            "persistent": false,
            "extended": false,
            "group": "locSrv",
            "name": "extPosStdDev",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "extQuatStdDev": {
            "ident": 104,
            "persistent": false,
            "extended": false,
            "group": "locSrv",
            "name": "extQuatStdDev",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "crtpsrv": {
        "echoDelay": {
            "ident": 105,
            "persistent": false,
            "extended": false,
            "group": "crtpsrv",
            "name": "echoDelay",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "health": {
        "startPropTest": {
            "ident": 106,
            "persistent": false,
            "extended": false,
            "group": "health",
            "name": "startPropTest",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "startBatTest": {
            "ident": 107,
            "persistent": false,
            "extended": false,
            "group": "health",
            "name": "startBatTest",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "propTestPWMRatio": {
            "ident": 108,
            "persistent": false,
            "extended": true,
            "group": "health",
            "name": "propTestPWMRatio",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "batTestPWMRatio": {
            "ident": 109,
            "persistent": false,
            "extended": true,
            "group": "health",
            "name": "batTestPWMRatio",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "kalman": {
        "maxPos": {
            "ident": 110,
            "persistent": false,
            "extended": false,
            "group": "kalman",
            "name": "maxPos",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "maxVel": {
            "ident": 111,
            "persistent": false,
            "extended": false,
            "group": "kalman",
            "name": "maxVel",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "resetEstimation": {
            "ident": 112,
            "persistent": false,
            "extended": false,
            "group": "kalman",
            "name": "resetEstimation",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "robustTdoa": {
            "ident": 113,
            "persistent": false,
            "extended": false,
            "group": "kalman",
            "name": "robustTdoa",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "robustTwr": {
            "ident": 114,
            "persistent": false,
            "extended": false,
            "group": "kalman",
            "name": "robustTwr",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "pNAcc_xy": {
            "ident": 115,
            "persistent": false,
            "extended": true,
            "group": "kalman",
            "name": "pNAcc_xy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pNAcc_z": {
            "ident": 116,
            "persistent": false,
            "extended": true,
            "group": "kalman",
            "name": "pNAcc_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pNVel": {
            "ident": 117,
            "persistent": false,
            "extended": true,
            "group": "kalman",
            "name": "pNVel",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pNPos": {
            "ident": 118,
            "persistent": false,
            "extended": true,
            "group": "kalman",
            "name": "pNPos",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pNAtt": {
            "ident": 119,
            "persistent": false,
            "extended": true,
            "group": "kalman",
            "name": "pNAtt",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "mNBaro": {
            "ident": 120,
            "persistent": false,
            "extended": true,
            "group": "kalman",
            "name": "mNBaro",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "mNGyro_rollpitch": {
            "ident": 121,
            "persistent": false,
            "extended": true,
            "group": "kalman",
            "name": "mNGyro_rollpitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "mNGyro_yaw": {
            "ident": 122,
            "persistent": false,
            "extended": true,
            "group": "kalman",
            "name": "mNGyro_yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "initialX": {
            "ident": 123,
            "persistent": false,
            "extended": false,
            "group": "kalman",
            "name": "initialX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "initialY": {
            "ident": 124,
            "persistent": false,
            "extended": false,
            "group": "kalman",
            "name": "initialY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "initialZ": {
            "ident": 125,
            "persistent": false,
            "extended": false,
            "group": "kalman",
            "name": "initialZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "initialYaw": {
            "ident": 126,
            "persistent": false,
            "extended": false,
            "group": "kalman",
            "name": "initialYaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "memTst": {
        "resetW": {
            "ident": 127,
            "persistent": false,
            "extended": false,
            "group": "memTst",
            "name": "resetW",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "powerDist": {
        "idleThrust": {
            "ident": 128,
            "persistent": false,
            "extended": true,
            "group": "powerDist",
            "name": "idleThrust",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 0
        }
    },
    "quadSysId": {
        "thrustToTorque": {
            "ident": 129,
            "persistent": false,
            "extended": false,
            "group": "quadSysId",
            "name": "thrustToTorque",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pwmToThrustA": {
            "ident": 130,
            "persistent": false,
            "extended": false,
            "group": "quadSysId",
            "name": "pwmToThrustA",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pwmToThrustB": {
            "ident": 131,
            "persistent": false,
            "extended": false,
            "group": "quadSysId",
            "name": "pwmToThrustB",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "armLength": {
            "ident": 132,
            "persistent": false,
            "extended": false,
            "group": "quadSysId",
            "name": "armLength",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "sensfusion6": {
        "kp": {
            "ident": 133,
            "persistent": false,
            "extended": true,
            "group": "sensfusion6",
            "name": "kp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ki": {
            "ident": 134,
            "persistent": false,
            "extended": true,
            "group": "sensfusion6",
            "name": "ki",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "baseZacc": {
            "ident": 135,
            "persistent": false,
            "extended": false,
            "group": "sensfusion6",
            "name": "baseZacc",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "sound": {
        "effect": {
            "ident": 136,
            "persistent": false,
            "extended": true,
            "group": "sound",
            "name": "effect",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "neffect": {
            "ident": 137,
            "persistent": false,
            "extended": false,
            "group": "sound",
            "name": "neffect",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 1
        },
        "freq": {
            "ident": 138,
            "persistent": false,
            "extended": false,
            "group": "sound",
            "name": "freq",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "stabilizer": {
        "estimator": {
            "ident": 139,
            "persistent": false,
            "extended": false,
            "group": "stabilizer",
            "name": "estimator",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "controller": {
            "ident": 140,
            "persistent": false,
            "extended": false,
            "group": "stabilizer",
            "name": "controller",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "stop": {
            "ident": 141,
            "persistent": false,
            "extended": false,
            "group": "stabilizer",
            "name": "stop",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "supervisor": {
        "infdmp": {
            "ident": 142,
            "persistent": false,
            "extended": false,
            "group": "supervisor",
            "name": "infdmp",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "cpu": {
        "flash": {
            "ident": 143,
            "persistent": false,
            "extended": false,
            "group": "cpu",
            "name": "flash",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 1
        },
        "id0": {
            "ident": 144,
            "persistent": false,
            "extended": false,
            "group": "cpu",
            "name": "id0",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 1
        },
        "id1": {
            "ident": 145,
            "persistent": false,
            "extended": false,
            "group": "cpu",
            "name": "id1",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 1
        },
        "id2": {
            "ident": 146,
            "persistent": false,
            "extended": false,
            "group": "cpu",
            "name": "id2",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 1
        }
    },
    "tdoaEngine": {
        "logId": {
            "ident": 147,
            "persistent": false,
            "extended": false,
            "group": "tdoaEngine",
            "name": "logId",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "logOthrId": {
            "ident": 148,
            "persistent": false,
            "extended": false,
            "group": "tdoaEngine",
            "name": "logOthrId",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "matchAlgo": {
            "ident": 149,
            "persistent": false,
            "extended": false,
            "group": "tdoaEngine",
            "name": "matchAlgo",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "lighthouse": {
        "method": {
            "ident": 150,
            "persistent": false,
            "extended": false,
            "group": "lighthouse",
            "name": "method",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "bsCalibReset": {
            "ident": 151,
            "persistent": false,
            "extended": false,
            "group": "lighthouse",
            "name": "bsCalibReset",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "systemType": {
            "ident": 152,
            "persistent": false,
            "extended": false,
            "group": "lighthouse",
            "name": "systemType",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "bsAvailable": {
            "ident": 153,
            "persistent": false,
            "extended": false,
            "group": "lighthouse",
            "name": "bsAvailable",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 1
        },
        "sweepStd": {
            "ident": 154,
            "persistent": false,
            "extended": false,
            "group": "lighthouse",
            "name": "sweepStd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "sweepStd2": {
            "ident": 155,
            "persistent": false,
            "extended": false,
            "group": "lighthouse",
            "name": "sweepStd2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "enLhRawStream": {
            "ident": 156,
            "persistent": false,
            "extended": false,
            "group": "lighthouse",
            "name": "enLhRawStream",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "lh2maxRate": {
            "ident": 157,
            "persistent": false,
            "extended": false,
            "group": "lighthouse",
            "name": "lh2maxRate",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "pid_attitude": {
        "roll_kp": {
            "ident": 158,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "roll_kp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_ki": {
            "ident": 159,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "roll_ki",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_kd": {
            "ident": 160,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "roll_kd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_kff": {
            "ident": 161,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "roll_kff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_kp": {
            "ident": 162,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "pitch_kp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_ki": {
            "ident": 163,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "pitch_ki",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_kd": {
            "ident": 164,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "pitch_kd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_kff": {
            "ident": 165,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "pitch_kff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_kp": {
            "ident": 166,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "yaw_kp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_ki": {
            "ident": 167,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "yaw_ki",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_kd": {
            "ident": 168,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "yaw_kd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_kff": {
            "ident": 169,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "yaw_kff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yawMaxDelta": {
            "ident": 170,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "yawMaxDelta",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "attFiltEn": {
            "ident": 171,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "attFiltEn",
            "ctype": "int8_t",
            "pytype": "<b",
            "access": 0
        },
        "attFiltCut": {
            "ident": 172,
            "persistent": false,
            "extended": true,
            "group": "pid_attitude",
            "name": "attFiltCut",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "pid_rate": {
        "roll_kp": {
            "ident": 173,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "roll_kp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_ki": {
            "ident": 174,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "roll_ki",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_kd": {
            "ident": 175,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "roll_kd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_kff": {
            "ident": 176,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "roll_kff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_kp": {
            "ident": 177,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "pitch_kp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_ki": {
            "ident": 178,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "pitch_ki",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_kd": {
            "ident": 179,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "pitch_kd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_kff": {
            "ident": 180,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "pitch_kff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_kp": {
            "ident": 181,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "yaw_kp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_ki": {
            "ident": 182,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "yaw_ki",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_kd": {
            "ident": 183,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "yaw_kd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_kff": {
            "ident": 184,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "yaw_kff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rateFiltEn": {
            "ident": 185,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "rateFiltEn",
            "ctype": "int8_t",
            "pytype": "<b",
            "access": 0
        },
        "omxFiltCut": {
            "ident": 186,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "omxFiltCut",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "omyFiltCut": {
            "ident": 187,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "omyFiltCut",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "omzFiltCut": {
            "ident": 188,
            "persistent": false,
            "extended": true,
            "group": "pid_rate",
            "name": "omzFiltCut",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "ctrlINDI": {
        "thrust_threshold": {
            "ident": 189,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "thrust_threshold",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bound_ctrl_input": {
            "ident": 190,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "bound_ctrl_input",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "g1_p": {
            "ident": 191,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "g1_p",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "g1_q": {
            "ident": 192,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "g1_q",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "g1_r": {
            "ident": 193,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "g1_r",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "g2": {
            "ident": 194,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "g2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ref_err_p": {
            "ident": 195,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "ref_err_p",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ref_err_q": {
            "ident": 196,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "ref_err_q",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ref_err_r": {
            "ident": 197,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "ref_err_r",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ref_rate_p": {
            "ident": 198,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "ref_rate_p",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ref_rate_q": {
            "ident": 199,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "ref_rate_q",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ref_rate_r": {
            "ident": 200,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "ref_rate_r",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "act_dyn_p": {
            "ident": 201,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "act_dyn_p",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "act_dyn_q": {
            "ident": 202,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "act_dyn_q",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "act_dyn_r": {
            "ident": 203,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "act_dyn_r",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "filt_cutoff": {
            "ident": 204,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "filt_cutoff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "filt_cutoff_r": {
            "ident": 205,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "filt_cutoff_r",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "outerLoopActive": {
            "ident": 206,
            "persistent": false,
            "extended": false,
            "group": "ctrlINDI",
            "name": "outerLoopActive",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "ctrlMel": {
        "kp_xy": {
            "ident": 207,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "kp_xy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "kd_xy": {
            "ident": 208,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "kd_xy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ki_xy": {
            "ident": 209,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "ki_xy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "i_range_xy": {
            "ident": 210,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "i_range_xy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "kp_z": {
            "ident": 211,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "kp_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "kd_z": {
            "ident": 212,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "kd_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ki_z": {
            "ident": 213,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "ki_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "i_range_z": {
            "ident": 214,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "i_range_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "mass": {
            "ident": 215,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "mass",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "massThrust": {
            "ident": 216,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "massThrust",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "kR_xy": {
            "ident": 217,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "kR_xy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "kR_z": {
            "ident": 218,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "kR_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "kw_xy": {
            "ident": 219,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "kw_xy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "kw_z": {
            "ident": 220,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "kw_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ki_m_xy": {
            "ident": 221,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "ki_m_xy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ki_m_z": {
            "ident": 222,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "ki_m_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "kd_omega_rp": {
            "ident": 223,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "kd_omega_rp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "i_range_m_xy": {
            "ident": 224,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "i_range_m_xy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "i_range_m_z": {
            "ident": 225,
            "persistent": false,
            "extended": true,
            "group": "ctrlMel",
            "name": "i_range_m_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "ctrlAtt": {
        "tau_xy": {
            "ident": 226,
            "persistent": false,
            "extended": false,
            "group": "ctrlAtt",
            "name": "tau_xy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zeta_xy": {
            "ident": 227,
            "persistent": false,
            "extended": false,
            "group": "ctrlAtt",
            "name": "zeta_xy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "tau_z": {
            "ident": 228,
            "persistent": false,
            "extended": false,
            "group": "ctrlAtt",
            "name": "tau_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zeta_z": {
            "ident": 229,
            "persistent": false,
            "extended": false,
            "group": "ctrlAtt",
            "name": "zeta_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "tau_rp": {
            "ident": 230,
            "persistent": false,
            "extended": false,
            "group": "ctrlAtt",
            "name": "tau_rp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "mixing_factor": {
            "ident": 231,
            "persistent": false,
            "extended": false,
            "group": "ctrlAtt",
            "name": "mixing_factor",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "coll_fairness": {
            "ident": 232,
            "persistent": false,
            "extended": false,
            "group": "ctrlAtt",
            "name": "coll_fairness",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "posCtrlIndi": {
        "K_xi_x": {
            "ident": 233,
            "persistent": false,
            "extended": false,
            "group": "posCtrlIndi",
            "name": "K_xi_x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "K_xi_y": {
            "ident": 234,
            "persistent": false,
            "extended": false,
            "group": "posCtrlIndi",
            "name": "K_xi_y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "K_xi_z": {
            "ident": 235,
            "persistent": false,
            "extended": false,
            "group": "posCtrlIndi",
            "name": "K_xi_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "K_dxi_x": {
            "ident": 236,
            "persistent": false,
            "extended": false,
            "group": "posCtrlIndi",
            "name": "K_dxi_x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "K_dxi_y": {
            "ident": 237,
            "persistent": false,
            "extended": false,
            "group": "posCtrlIndi",
            "name": "K_dxi_y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "K_dxi_z": {
            "ident": 238,
            "persistent": false,
            "extended": false,
            "group": "posCtrlIndi",
            "name": "K_dxi_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pq_clamping": {
            "ident": 239,
            "persistent": false,
            "extended": false,
            "group": "posCtrlIndi",
            "name": "pq_clamping",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "posCtlPid": {
        "xKp": {
            "ident": 240,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "xKp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "xKi": {
            "ident": 241,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "xKi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "xKd": {
            "ident": 242,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "xKd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "xKff": {
            "ident": 243,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "xKff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yKp": {
            "ident": 244,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "yKp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yKi": {
            "ident": 245,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "yKi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yKd": {
            "ident": 246,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "yKd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yKff": {
            "ident": 247,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "yKff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zKp": {
            "ident": 248,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "zKp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zKi": {
            "ident": 249,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "zKi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zKd": {
            "ident": 250,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "zKd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zKff": {
            "ident": 251,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "zKff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "thrustBase": {
            "ident": 252,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "thrustBase",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "thrustMin": {
            "ident": 253,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "thrustMin",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "rLimit": {
            "ident": 254,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "rLimit",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pLimit": {
            "ident": 255,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "pLimit",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "xVelMax": {
            "ident": 256,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "xVelMax",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yVelMax": {
            "ident": 257,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "yVelMax",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zVelMax": {
            "ident": 258,
            "persistent": false,
            "extended": true,
            "group": "posCtlPid",
            "name": "zVelMax",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "velCtlPid": {
        "vxKp": {
            "ident": 259,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vxKp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vxKi": {
            "ident": 260,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vxKi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vxKd": {
            "ident": 261,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vxKd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vxKFF": {
            "ident": 262,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vxKFF",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vyKp": {
            "ident": 263,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vyKp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vyKi": {
            "ident": 264,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vyKi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vyKd": {
            "ident": 265,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vyKd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vyKFF": {
            "ident": 266,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vyKFF",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vzKp": {
            "ident": 267,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vzKp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vzKi": {
            "ident": 268,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vzKi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vzKd": {
            "ident": 269,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vzKd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vzKFF": {
            "ident": 270,
            "persistent": false,
            "extended": true,
            "group": "velCtlPid",
            "name": "vzKFF",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "posEstAlt": {
        "estAlphaAsl": {
            "ident": 271,
            "persistent": false,
            "extended": true,
            "group": "posEstAlt",
            "name": "estAlphaAsl",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "estAlphaZr": {
            "ident": 272,
            "persistent": false,
            "extended": true,
            "group": "posEstAlt",
            "name": "estAlphaZr",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "velFactor": {
            "ident": 273,
            "persistent": false,
            "extended": true,
            "group": "posEstAlt",
            "name": "velFactor",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "velZAlpha": {
            "ident": 274,
            "persistent": false,
            "extended": true,
            "group": "posEstAlt",
            "name": "velZAlpha",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vAccDeadband": {
            "ident": 275,
            "persistent": false,
            "extended": true,
            "group": "posEstAlt",
            "name": "vAccDeadband",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "firmware": {
        "revision0": {
            "ident": 276,
            "persistent": false,
            "extended": false,
            "group": "firmware",
            "name": "revision0",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 1
        },
        "revision1": {
            "ident": 277,
            "persistent": false,
            "extended": false,
            "group": "firmware",
            "name": "revision1",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 1
        },
        "modified": {
            "ident": 278,
            "persistent": false,
            "extended": false,
            "group": "firmware",
            "name": "modified",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 1
        }
    }
}