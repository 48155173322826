import { DRONE_RC_ITEMS } from "./store/drone_rc_store";

// For TopBar Routing
const MenuCustom = [
  /*
  {
    label: "Home",
    pathname: "/",
    image: "HomeIcon",
  },
  */
  {
    label: "Program",
    pathname: "/program",
    image: "CodeIcon",
  },
  {
    label: "Data View",
    pathname: "/datavis",
    image: "TimelineIcon",
  },
  {
    label: "Academy",
    pathname: "https://learn-locorobo.com",
    image: "SchoolIcon",
    external: true
  },
  {
    label: "Drone RC",
    pathname: "/dronerc",
    image: "GamesIcon",
    conditional: true,
    options: DRONE_RC_ITEMS,
  },
  {
    label: "Dog RC",
    pathname: "/dogrc",
    image: "DogIcon",
  },
  {
    label: "Drone View",
    pathname: "/droneview",
    image: "FlightIcon",
    conditional: true,
    options: ["LocoCraze"],
  }
];

export default MenuCustom;