import { CRTPPacket, CRTPPort } from './LCExtra.js';

const SET_SETPOINT_CHANNEL = 0;
const META_COMMAND_CHANNEL = 1;

const TYPE_STOP = 0;
const TYPE_VELOCITY_WORLD = 1;
const TYPE_ZDISTANCE = 2;
const TYPE_HOVER = 5;
const TYPE_FULL_STATE = 6;
const TYPE_POSITION = 7;

const TYPE_META_COMMAND_NOTIFY_SETPOINT_STOP = 0;


const COMMAND_DELAY = 60; // ms

const VELOCITY = 0.2; // meters/second
const RATE = 360.0 / 5;

export class MotionCommander {
    /**
     * The motion commander
     */
    constructor(crazyflie, default_height = 0.5) {
        /**
         * Construct an instance of a MotionCommander
         *
         * @param crazyflie: A Crazyflie or SyncCrazyflie instance
         * @param default_height: The default height to fly at - meters
         */
        this._cf = crazyflie;

        this.default_height = default_height;

        this._is_flying = false;
        this._thread = null;
    }

    // Distance based primitives

    takeoff = async(height = null, velocity = VELOCITY) => {
        /**
         * Takes off, that is starts the motors, goes straight up and hovers.
         *
         * @param height: The height (meters) to hover at. null uses the default
         *                height set when constructed.
         * @param velocity: The velocity (meters/second) when taking off
         * @return:
         */
        if (this._is_flying) {
            throw new Error('Already flying');
        }
        //console.log("starting takeoff")

        //if (!this._cf.is_connected()) {
        //    throw new Error('Crazyflie is not connected');
        //}

        this._is_flying = true;
        await this._reset_position_estimator();

        this._thread = new _SetPointThread(this._cf);
        this._thread.start();

        if (height === null) {
            height = this.default_height;
        }

        await this.up(height, velocity);
    }

    land = async(velocity = VELOCITY) => {
        /**
         * Go straight down and turn off the motors.
         *
         * Do not call this function if you use the with keyword. Landing is
         * done automatically when the context goes out of scope.
         *
         * @param velocity: The velocity (meters/second) when going down
         * @return:
         */
        //console.log("starting land")
        if (this._is_flying) {
            await this.down(this._thread.get_height(), velocity);

            this._thread.stop();
            this._thread = null;
            // wait 200 ms
            await new Promise(resolve => setTimeout(resolve, 200));

            this._cf.commander.send_stop_setpoint();
            // wait 200 ms
            await new Promise(resolve => setTimeout(resolve, 200));

            // Stop using low level setpoints and hand responsibility over to the high level commander to
            // avoid time out when no setpoints are received any more
            this._cf.commander.send_notify_setpoint_stop();
            
            // wait 200 ms
            await new Promise(resolve => setTimeout(resolve, 200));

            this._is_flying = false;
        }
    }

    left = async(distance_m, velocity = VELOCITY) => {
        /**
         * Go left
         *
         * @param distance_m: The distance to travel (meters)
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        await this.move_distance(0.0, distance_m, 0.0, velocity);
    }

    right = async(distance_m, velocity = VELOCITY) => {
        /**
         * Go right
         *
         * @param distance_m: The distance to travel (meters)
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        await this.move_distance(0.0, -distance_m, 0.0, velocity);
    }

    forward = async(distance_m, velocity = VELOCITY) => {
        /**
         * Go forward
         *
         * @param distance_m: The distance to travel (meters)
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        await this.move_distance(distance_m, 0.0, 0.0, velocity);
    }

    back = async(distance_m, velocity = VELOCITY) => {
        /**
         * Go backwards
         *
         * @param distance_m: The distance to travel (meters)
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        await this.move_distance(-distance_m, 0.0, 0.0, velocity);
    }

    up = async(distance_m, velocity = VELOCITY) => {
        /**
         * Go up
         *
         * @param distance_m: The distance to travel (meters)
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        await this.move_distance(0.0, 0.0, distance_m, velocity);
    }

    down = async(distance_m, velocity = VELOCITY) => {
        /**
         * Go down
         *
         * @param distance_m: The distance to travel (meters)
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        await this.move_distance(0.0, 0.0, -distance_m, velocity);
    }

    turn_left = async(angle_degrees, rate = RATE) => {
        /**
         * Turn to the left, staying on the spot
         *
         * @param angle_degrees: How far to turn (degrees)
         * @param rate: The turning speed (degrees/second)
         * @return:
         */
        const flight_time = angle_degrees / rate;

        this.start_turn_left(rate);
        await new Promise(resolve => setTimeout(() => {
            this.stop();
            resolve();
        }, flight_time * 1000));
        await new Promise(resolve => setTimeout(resolve, COMMAND_DELAY));
    }

    turn_right = async(angle_degrees, rate = RATE) => {
        /**
         * Turn to the right, staying on the spot
         *
         * @param angle_degrees: How far to turn (degrees)
         * @param rate: The turning speed (degrees/second)
         * @return:
         */
        const flight_time = angle_degrees / rate;

        this.start_turn_right(rate);
        await new Promise(resolve => setTimeout(() => {
            this.stop();
            resolve();
        }, flight_time * 1000));
        await new Promise(resolve => setTimeout(resolve, COMMAND_DELAY));
    }

    circle_left = async(radius_m, velocity = VELOCITY, angle_degrees = 360.0) => {
        /**
         * Go in circle, counter clock wise
         *
         * @param radius_m: The radius of the circle (meters)
         * @param velocity: The velocity along the circle (meters/second)
         * @param angle_degrees: How far to go in the circle (degrees)
         * @return:
         */
        const distance = 2 * radius_m * Math.PI * angle_degrees / 360.0;
        const flight_time = distance / velocity;
        this.start_circle_left(radius_m, velocity);
        await new Promise(resolve => setTimeout(() => {
            this.stop();
            resolve();
        }, flight_time * 1000));
        await new Promise(resolve => setTimeout(resolve, COMMAND_DELAY));
    }

    circle_right = async(radius_m, velocity = VELOCITY, angle_degrees = 360.0) => {
        /**
         * Go in circle, clock wise
         *
         * @param radius_m: The radius of the circle (meters)
         * @param velocity: The velocity along the circle (meters/second)
         * @param angle_degrees: How far to go in the circle (degrees)
         * @return:
         */
        const distance = 2 * radius_m * Math.PI * angle_degrees / 360.0;
        const flight_time = distance / velocity;

        this.start_circle_right(radius_m, velocity);
        await new Promise(resolve => setTimeout(() => {
            this.stop();
            resolve();
        }, flight_time * 1000));
        await new Promise(resolve => setTimeout(resolve, COMMAND_DELAY));
    }

    move_distance = async(distance_x_m, distance_y_m, distance_z_m, velocity = VELOCITY) => {
        /**
         * Move in a straight line.
         * positive X is forward
         * positive Y is left
         * positive Z is up
         *
         * @param distance_x_m: The distance to travel along the X-axis (meters)
         * @param distance_y_m: The distance to travel along the Y-axis (meters)
         * @param distance_z_m: The distance to travel along the Z-axis (meters)
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        const distance = Math.sqrt(distance_x_m * distance_x_m +
                                   distance_y_m * distance_y_m +
                                   distance_z_m * distance_z_m);
        const flight_time = distance / velocity;
        console.log(flight_time);
        const velocity_x = velocity * distance_x_m / distance;
        const velocity_y = velocity * distance_y_m / distance;
        const velocity_z = velocity * distance_z_m / distance;
        console.log(velocity_x, velocity_y, velocity_z);
        this.start_linear_motion(velocity_x, velocity_y, velocity_z);
        await new Promise(resolve => setTimeout(() => {
            this.stop();
            resolve();
        }, flight_time * 1000));
        await new Promise(resolve => setTimeout(resolve, COMMAND_DELAY));
    }

    // Velocity based primitives

    start_left(velocity = VELOCITY) {
        /**
         * Start moving left. This function returns immediately.
         *
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        this.start_linear_motion(0.0, velocity, 0.0);
    }

    start_right(velocity = VELOCITY) {
        /**
         * Start moving right. This function returns immediately.
         *
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        this.start_linear_motion(0.0, -velocity, 0.0);
    }

    start_forward(velocity = VELOCITY) {
        /**
         * Start moving forward. This function returns immediately.
         *
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        this.start_linear_motion(velocity, 0.0, 0.0);
    }

    start_back(velocity = VELOCITY) {
        /**
         * Start moving backwards. This function returns immediately.
         *
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        this.start_linear_motion(-velocity, 0.0, 0.0);
    }

    start_up(velocity = VELOCITY) {
        /**
         * Start moving up. This function returns immediately.
         *
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        this.start_linear_motion(0.0, 0.0, velocity);
    }

    start_down(velocity = VELOCITY) {
        /**
         * Start moving down. This function returns immediately.
         *
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        this.start_linear_motion(0.0, 0.0, -velocity);
    }

    stop() {
        /**
         * Stop any motion and hover.
         *
         * @return:
         */
        this._set_vel_setpoint(0.0, 0.0, 0.0, 0.0);
    }

    start_turn_left(rate = RATE) {
        /**
         * Start turning left. This function returns immediately.
         *
         * @param rate: The angular rate (degrees/second)
         * @return:
         */
        this._set_vel_setpoint(0.0, 0.0, 0.0, -rate);
    }

    start_turn_right(rate = RATE) {
        /**
         * Start turning right. This function returns immediately.
         *
         * @param rate: The angular rate (degrees/second)
         * @return:
         */
        this._set_vel_setpoint(0.0, 0.0, 0.0, rate);
    }

    start_circle_left(radius_m, velocity = VELOCITY) {
        /**
         * Start a circular motion to the left. This function returns immediately.
         *
         * @param radius_m: The radius of the circle (meters)
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        const circumference = 2 * radius_m * Math.PI;
        const rate = 360.0 * velocity / circumference;

        this._set_vel_setpoint(velocity, 0.0, 0.0, -rate);
    }

    start_circle_right(radius_m, velocity = VELOCITY) {
        /**
         * Start a circular motion to the right. This function returns immediately
         *
         * @param radius_m: The radius of the circle (meters)
         * @param velocity: The velocity of the motion (meters/second)
         * @return:
         */
        const circumference = 2 * radius_m * Math.PI;
        const rate = 360.0 * velocity / circumference;

        this._set_vel_setpoint(velocity, 0.0, 0.0, rate);
    }

    start_linear_motion(velocity_x_m, velocity_y_m, velocity_z_m, rate_yaw = 0.0) {
        /**
         * Start a linear motion with an optional yaw rate input. This function returns immediately.
         *
         * positive X is forward
         * positive Y is left
         * positive Z is up
         *
         * @param velocity_x_m: The velocity along the X-axis (meters/second)
         * @param velocity_y_m: The velocity along the Y-axis (meters/second)
         * @param velocity_z_m: The velocity along the Z-axis (meters/second)
         * @param rate: The angular rate (degrees/second)
         * @return:
         */
        this._set_vel_setpoint(
            velocity_x_m, velocity_y_m, velocity_z_m, rate_yaw);
    }

    _set_vel_setpoint(velocity_x, velocity_y, velocity_z, rate_yaw) {
        if (!this._is_flying) {
            throw new Error('Can not move on the ground. Take off first!');
        }
        this._thread.set_vel_setpoint(
            velocity_x, velocity_y, velocity_z, rate_yaw);
    }

    _reset_position_estimator = async() => {
        await this._cf.param.set_value('kalman.resetEstimation', '1');
        
        await new Promise(resolve => setTimeout(resolve, 250));

        await this._cf.param.set_value('kalman.resetEstimation', '0');

        // Wait for 2000ms
        await new Promise(resolve => setTimeout(resolve, 2000));
    }
}

class _SetPointThread {
    static TERMINATE_EVENT = 'terminate';
    static UPDATE_PERIOD = 0.2;
    static ABS_Z_INDEX = 3;

    constructor(cf, update_period = _SetPointThread.UPDATE_PERIOD) {
        this.update_period = update_period;

        this._queue = [];
        this._cf = cf;

        this._hover_setpoint = [0.0, 0.0, 0.0, 0.0];

        this._z_base = 0.0;
        this._z_velocity = 0.0;
        this._z_base_time = 0.0;

        this._running = false;
    }

    start() {
        this._running = true;
        this._run();
    }

    stop() {
        /**
         * Stop the thread and wait for it to terminate
         *
         * @return:
         */
        this._queue.push(_SetPointThread.TERMINATE_EVENT);
        this._running = false;
    }

    set_vel_setpoint(velocity_x, velocity_y, velocity_z, rate_yaw) {
        /** Set the velocity setpoint to use for the future motion */
        this._queue.push([velocity_x, velocity_y, velocity_z, rate_yaw]);
    }

    get_height() {
        /**
         * Get the current height of the Crazyflie.
         *
         * @return: The height (meters)
         */
        return this._hover_setpoint[_SetPointThread.ABS_Z_INDEX];
    }

    async _run() {
        while (this._running) {
            try {
                const event = this._queue.shift();
                if (event === _SetPointThread.TERMINATE_EVENT) {
                    return;
                }

                if (event) {
                    this._new_setpoint(...event);
                }
            } catch (e) {
                // Handle error
            }

            this._update_z_in_setpoint();
            this._cf.commander.send_hover_setpoint(...this._hover_setpoint);

            await new Promise(resolve => setTimeout(resolve, this.update_period * 1000));
        }
    }

    _new_setpoint(velocity_x, velocity_y, velocity_z, rate_yaw) {
        this._z_base = this._current_z();
        this._z_velocity = velocity_z;
        this._z_base_time = Date.now() / 1000;

        this._hover_setpoint = [velocity_x, velocity_y, rate_yaw, this._z_base];
    }

    _update_z_in_setpoint() {
        this._hover_setpoint[_SetPointThread.ABS_Z_INDEX] = this._current_z();
    }

    _current_z() {
        const now = Date.now() / 1000;
        return this._z_base + this._z_velocity * (now - this._z_base_time);
    }
}






export class Commander {
    /**
     * Used for sending control setpoints to the Crazyflie
     */
    constructor(crazyflie = null) {
        /**
         * Initialize the commander object. By default the commander is in
         * +-mode (not x-mode).
         */
        this._cf = crazyflie;
        this._x_mode = false;
    }

    set_client_xmode = (enabled) => {
        /**
         * Enable/disable the client side X-mode. When enabled this recalculates
         * the setpoints before sending them to the Crazyflie.
         */
        this._x_mode = enabled;
    }

    send_setpoint = (roll, pitch, yawrate, thrust) => {
        /**
         * Send a new control setpoint for roll/pitch/yaw_Rate/thrust to the copter.
         *
         * The meaning of these values is depended on the mode of the RPYT commander in the firmware.
         * The roll, pitch and yaw can be set in a rate or absolute mode with parameter group
         * `flightmode` with variables `stabModeRoll`, `.stabModeRoll` and `.stabModeRoll`.
         * Default settings are roll, pitch, yawrate and thrust
         *
         * roll,  pitch are in degrees,
         * yawrate is in degrees/s,
         * thrust is an integer value ranging from 10001 (next to no power) to 60000 (full power)
         */
        if (thrust > 0xFFFF || thrust < 0) {
            throw new Error('Thrust must be between 0 and 0xFFFF');
        }

        if (this._x_mode) {
            [roll, pitch] = [0.707 * (roll - pitch), 0.707 * (roll + pitch)];
        }

        const pk = new CRTPPacket();
        pk.port = CRTPPort.COMMANDER;
        pk.data = new Uint8Array(new Float32Array([roll, -pitch, yawrate, thrust]).buffer);
        this._cf.sendAndNoReply(pk);
    }

    send_notify_setpoint_stop = (remain_valid_milliseconds = 0) => {
        /**
         * Sends a packet so that the priority of the current setpoint to the lowest non-disabled value,
         * so any new setpoint regardless of source will overwrite it.
         */
        const pk = new CRTPPacket();
        pk.port = CRTPPort.COMMANDER_GENERIC;
        pk.channel = META_COMMAND_CHANNEL;
        pk.data = new Uint8Array(new ArrayBuffer(5));
        const view = new DataView(pk.data.buffer);
        view.setUint8(0, TYPE_META_COMMAND_NOTIFY_SETPOINT_STOP);
        view.setUint32(1, remain_valid_milliseconds, true);
        this._cf.sendAndNoReply(pk);
    }

    send_stop_setpoint = () => {
        /**
         * Send STOP setpoint, stopping the motors and (potentially) falling.
         */
        const pk = new CRTPPacket();
        pk.port = CRTPPort.COMMANDER_GENERIC;
        pk.data = new Uint8Array([TYPE_STOP]);
        this._cf.sendAndNoReply(pk);
    }

    send_velocity_world_setpoint = (vx, vy, vz, yawrate) => {
        /**
         * Send Velocity in the world frame of reference setpoint with yawrate commands
         *
         * vx, vy, vz are in m/s
         * yawrate is in degrees/s
         */
        const pk = new CRTPPacket();
        pk.port = CRTPPort.COMMANDER_GENERIC;
        pk.channel = SET_SETPOINT_CHANNEL;

        // Create a buffer to hold the data
        const buffer = new ArrayBuffer(1 + 4 * 4); // 1 byte for TYPE_VELOCITY_WORLD + 4 floats (4 bytes each)
        const view = new DataView(buffer);

        // Set the TYPE_VELOCITY_WORLD byte
        view.setUint8(0, TYPE_VELOCITY_WORLD);

        // Set the floats for vx, vy, vz, and yawrate
        view.setFloat32(1, vx, true); // true for little-endian
        view.setFloat32(5, vy, true); // true for little-endian
        view.setFloat32(9, vz, true); // true for little-endian
        view.setFloat32(13, yawrate, true); // true for little-endian

        pk.data = new Uint8Array(buffer);
        this._cf.sendAndNoReply(pk);
    }

    send_zdistance_setpoint = (roll, pitch, yawrate, zdistance) => {
        /**
         * Control mode where the height is send as an absolute setpoint (intended
         * to be the distance to the surface under the Crazyflie), while giving roll,
         * pitch and yaw rate commands
         *
         * roll, pitch are in degrees
         * yawrate is in degrees/s
         * zdistance is in meters
         */
        const pk = new CRTPPacket();
        pk.port = CRTPPort.COMMANDER_GENERIC;
        pk.channel = SET_SETPOINT_CHANNEL;

        // Create a buffer to hold the data
        const buffer = new ArrayBuffer(1 + 4 * 4); // 1 byte for TYPE_ZDISTANCE + 4 floats (4 bytes each)
        const view = new DataView(buffer);

        // Set the TYPE_ZDISTANCE byte
        view.setUint8(0, TYPE_ZDISTANCE);

        // Set the floats for roll, pitch, yawrate, and zdistance
        view.setFloat32(1, roll, true); // true for little-endian
        view.setFloat32(5, pitch, true); // true for little-endian
        view.setFloat32(9, yawrate, true); // true for little-endian
        view.setFloat32(13, zdistance, true); // true for little-endian

        // Create a Uint8Array from the buffer
        pk.data = new Uint8Array(buffer);
        this._cf.sendAndNoReply(pk);
    }

    send_hover_setpoint = (vx, vy, yawrate, zdistance) => {
        /**
         * Control mode where the height is send as an absolute setpoint (intended
         * to be the distance to the surface under the Crazyflie), while giving x, y velocity
         * commands in body-fixed coordinates.
         *
         * vx,  vy are in m/s
         * yawrate is in degrees/s
         * zdistance is in meters
         */
        const pk = new CRTPPacket();
        pk.port = CRTPPort.COMMANDER_GENERIC;
        pk.channel = SET_SETPOINT_CHANNEL;

        // Create a buffer to hold the data
        const buffer = new ArrayBuffer(1 + 4 * 4); // 1 byte for TYPE_HOVER + 4 floats (4 bytes each)
        const view = new DataView(buffer);

        // Set the TYPE_HOVER byte
        view.setUint8(0, TYPE_HOVER);

        // Set the floats for vx, vy, yawrate, and zdistance
        view.setFloat32(1, vx, true); // true for little-endian
        view.setFloat32(5, vy, true); // true for little-endian
        view.setFloat32(9, yawrate, true); // true for little-endian
        view.setFloat32(13, zdistance, true); // true for little-endian

        // Create a Uint8Array from the buffer
        pk.data = new Uint8Array(buffer);
        //console.log(pk);
        this._cf.sendAndNoReply(pk);
    }

    send_full_state_setpoint = (pos, vel, acc, orientation, rollrate, pitchrate, yawrate) => {
        /**
         * Control mode where the position, velocity, acceleration, orientation and angular
         * velocity are sent as absolute (world) values.
         *
         * position [x, y, z] are in m
         * velocity [vx, vy, vz] are in m/s
         * acceleration [ax, ay, az] are in m/s^2
         * orientation [qx, qy, qz, qw] are the quaternion components of the orientation
         * rollrate, pitchrate, yawrate are in degrees/s
         */
        const vector_to_mm_16bit = (vec) => vec.map(v => Math.round(v * 1000));

        const [x, y, z] = vector_to_mm_16bit(pos);
        const [vx, vy, vz] = vector_to_mm_16bit(vel);
        const [ax, ay, az] = vector_to_mm_16bit(acc);
        const [rr, pr, yr] = vector_to_mm_16bit([rollrate, pitchrate, yawrate]);
        const orient_comp = compress_quaternion(orientation);

        const pk = new CRTPPacket();
        pk.port = CRTPPort.COMMANDER_GENERIC;
        pk.data = new Uint8Array(new ArrayBuffer(25));
        const view = new DataView(pk.data.buffer);
        view.setUint8(0, TYPE_FULL_STATE);
        view.setInt16(1, x, true);
        view.setInt16(3, y, true);
        view.setInt16(5, z, true);
        view.setInt16(7, vx, true);
        view.setInt16(9, vy, true);
        view.setInt16(11, vz, true);
        view.setInt16(13, ax, true);
        view.setInt16(15, ay, true);
        view.setInt16(17, az, true);
        view.setUint32(19, orient_comp, true);
        view.setInt16(23, rr, true);
        view.setInt16(25, pr, true);
        view.setInt16(27, yr, true);
        this._cf.sendAndNoReply(pk);
    }

    send_position_setpoint = (x, y, z, yaw) => {
        /**
         * Control mode where the position is sent as absolute (world) x,y,z coordinate in
         * meter and the yaw is the absolute orientation.
         *
         * x, y, z are in m
         * yaw is in degrees
         */
        const pk = new CRTPPacket();
        pk.port = CRTPPort.COMMANDER_GENERIC;
        pk.channel = SET_SETPOINT_CHANNEL;

        // Create a buffer to hold the data
        const buffer = new ArrayBuffer(1 + 4 * 4); // 1 byte for TYPE_POSITION + 4 floats (4 bytes each)
        const view = new DataView(buffer);

        // Set the TYPE_POSITION byte
        view.setUint8(0, TYPE_POSITION);

        // Set the floats for vx, vy, yawrate, and zdistance
        view.setFloat32(1, x, true); // true for little-endian
        view.setFloat32(5, y, true); // true for little-endian
        view.setFloat32(9, z, true); // true for little-endian
        view.setFloat32(13, yaw, true); // true for little-endian

        // Create a Uint8Array from the buffer
        pk.data = new Uint8Array(buffer);
        this._cf.sendAndNoReply(pk);
    }
}


function compress_quaternion(quat) {
    /**
     * Compress a quaternion.
     * Assumes input quaternion is normalized. Will fail if not.
     *
     * Args:
     *     quat : An array of floats representing a quaternion [x, y, z, w]
     *
     * Returns: 32-bit integer
     */
    // Normalize the quaternion
    const norm = Math.sqrt(quat.reduce((sum, val) => sum + val * val, 0));
    const quat_n = quat.map(val => val / norm);

    let i_largest = 0;
    for (let i = 1; i < 4; i++) {
        if (Math.abs(quat_n[i]) > Math.abs(quat_n[i_largest])) {
            i_largest = i;
        }
    }
    const negate = quat_n[i_largest] < 0;

    const M_SQRT1_2 = 1.0 / Math.sqrt(2);

    let comp = i_largest;
    for (let i = 0; i < 4; i++) {
        if (i !== i_largest) {
            const negbit = ((quat_n[i] < 0) ^ negate) ? 1 : 0;
            const mag = Math.floor(((1 << 9) - 1) * (Math.abs(quat_n[i]) / M_SQRT1_2) + 0.5);
            comp = (comp << 10) | (negbit << 9) | mag;
        }
    }

    return comp;
}