
export function arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
}

export async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export function appendToUint8Array(original, dataToAppend) {
    const combined = new Uint8Array(original.length + dataToAppend.length);
    combined.set(original);
    combined.set(dataToAppend, original.length);
    return combined;
}

const RADIO_VID = 0x1915
const RADIO_PID = 0x7777

const SET_RADIO_CHANNEL = 0x01
const SET_RADIO_ADDRESS = 0x02
const SET_DATA_RATE = 0x03
const SET_RADIO_POWER = 0x04
const SET_RADIO_ARD = 0x05
const SET_RADIO_ARC = 0x06
const ACK_ENABLE = 0x10
const SET_CONT_CARRIER = 0x20
const SCANN_CHANNELS = 0x21
const LAUNCH_BOOTLOADER = 0xFF

export const DR_250KPS = 0
export const DR_1MPS = 1
export const DR_2MPS = 2

const P_M18DBM = 0
const P_M12DBM = 1
const P_M6DBM = 2
const P_0DBM = 3

export class RadioLink {

    constructor(isVerbose = false) {
        this.isVerbose = isVerbose;

        this.device = null;
        this.arc = -1;
        this.currentAddress = [0xE7, 0xE7, 0xE7, 0xE7, 0xE7];
        this.currentChannel = 2;
        this.currentDataRate = DR_2MPS;
    }

    log = (msg, ...optionalParams) => {
        if (this.isVerbose) {
            console.log(msg, ...optionalParams);
        }
    }
    logerror = (msg, ...optionalParams) => {
        if (this.isVerbose) {
            console.error(msg, ...optionalParams);
        }
    }

    
    initRadio = async() => {
        try {
            await this.selectDevice();
            await this.setDataRate(DR_2MPS);
            await this.setRadioChannel(2);

            const version = this.getVersion();
            this.log('Device version:', version);
            if (version >= 0.4) {
                await this.setContCarrier(false);
                //await this.setAddress([0xE7, 0xE7, 0xE7, 0xE7, 0xE7]);
                await this.setAddress(parseInt('E7E7E7E7E7', 16));
                await this.setPower(P_0DBM);
                await this.setArc(3);
                await this.setArdBytes(32);
                await this.setAckEnable(true);
            }
            return true;
        } catch (error) {
            this.logerror('Error initializing radio:', error);
            return false;
        }
    }
    
    sendPacket = async(dataOut) => {
        // Type check to ensure dataOut is a Uint8Array
        if (!(dataOut instanceof Uint8Array)) {
            throw new TypeError('dataOut must be a Uint8Array');
        }
        let ackIn = null;
        let data = null;
        try {
          // Send data to the OUT endpoint (endpoint 1)
          await this.device.transferOut(1, dataOut);
          // Read data from the IN endpoint (endpoint 0x81)
          const result = await this.device.transferIn(1, 64);
          data = new Uint8Array(result.data.buffer);

        } catch (error) {
          this.logerror('USB Error:', error);
        }
        if (data !== null) {
          ackIn = {
            ack: (data[0] & 0x01) !== 0,
            powerDet: (data[0] & 0x02) !== 0,
            retry: data[0] >> 4,
            data: data.slice(1)
          };
        }
        return ackIn;
    }

    selectDevice = async() => {
        const filters = [
            { 'vendorId': RADIO_VID, 'productId': RADIO_PID },
        ];
        //try {
        this.device = await navigator.usb.requestDevice({ filters: filters });
        this.log('Device selected:', this.device);
        await this.device.open();
        await this.device.selectConfiguration(1);
        await this.device.claimInterface(0);
        //    console.log('Device opened and configured');
        //} catch (error) {
        //    console.error('Error selecting device:', error);
        //}
    }

    disconnectDevice = async() => {
        if (this.device) {
            try {
                await this.device.close();
                this.device = null;
                console.log('Device closed');
            } catch (error) {
                console.error('Error closing device:', error);
            }
        }
    };
    
    scanChannels = async (address) => {

        // Format the address as a zero-padded 10-character hexadecimal string
        //const addr = address.toString(16).toUpperCase().padStart(10, '0');
        // Convert the hexadecimal string to a byte array
        //const newAddr = [];
        //for (let i = 0; i < addr.length; i += 2) {
        //  newAddr.push(parseInt(addr.substr(i, 2), 16));
        //}
        await this.setAddress(address);
        await this.setArc(1);
    
        let result = [];
        // Scan at each data rate
        await this.setDataRate(DR_250KPS);
        result = await this.scanRadioChannels(0, 125, [0xff]);
        this.log("Scanned 250KPS", result);

        await this.setDataRate(DR_1MPS);
        result = await this.scanRadioChannels(0, 125, [0xff]);
        this.log("Scanned 1MPS", result);

        await this.setDataRate(DR_2MPS);
        result = await this.scanRadioChannels(0, 125, [0xff]);
        this.log("Scanned 2MPS", result);
    }

    scanRadioChannels = async(start, stop, packet) => {
        const result = [];
        for (let i = start; i <= stop; i++) {
          await this.setRadioChannel(i);
          packet = new Uint8Array(packet);
          const status = await this.sendPacket(packet);
          if (status && status.ack) {
            result.push(i);
          }
        }
        return result;
    }

    setDataRate = async(dataRate) => {
        try {
            const result = await this.device.controlTransferOut({
                requestType: 'vendor',
                recipient: 'device',
                request: SET_DATA_RATE,
                value: dataRate,
                index: 0
            });
            //console.log('SET_DATA_RATE:', result);
            this.currentDataRate = dataRate;
        } catch (error) {
            console.error('Error setting data rate:', error);
        }
    }

    setRadioChannel = async(channel) => {
        try {
            const result = await this.device.controlTransferOut({
                requestType: 'vendor',
                recipient: 'device',
                request: SET_RADIO_CHANNEL,
                value: channel, // channel
                index: 0
            });
            //console.log('SET_RADIO_CHANNEL:', result);
            this.currentChannel = channel;
        } catch (error) {
            console.error('Error setting radio channel:', error);
        }
    }

    getVersion = () => {
        // Combine deviceVersionMajor and deviceVersionMinor into a version string
        const versionString = `${this.device.deviceVersionMajor}.${this.device.deviceVersionMinor}`;
        // Convert the version string to a float
        const version = parseFloat(versionString);
        return version;
    }

    setContCarrier = async(active) => {
        const value = active ? 1 : 0;
        const result = await this.device.controlTransferOut({
          requestType: 'vendor',
          recipient: 'device',
          request: SET_CONT_CARRIER,
          value: value,
          index: 0
        });
        if (result.status === 'ok') {
          console.log('Continuous carrier set successfully');
        } else {
          console.error('Failed to set continuous carrier:', result.status);
        }
    }
    setAddress = async(address) => {

        // Format the address as a zero-padded 10-character hexadecimal string
        const addr = address.toString(16).toUpperCase().padStart(10, '0');
        // Convert the hexadecimal string to a byte array
        const newAddr = [];
        for (let i = 0; i < addr.length; i += 2) {
            newAddr.push(parseInt(addr.slice(i, i + 2), 16));
        }
        if (newAddr.length !== 5) {
            throw new Error('Crazyradio: the radio address shall be 5 bytes long');
        }
        const result = await this.device.controlTransferOut({
            requestType: 'vendor',
            recipient: 'device',
            request: SET_RADIO_ADDRESS,
            value: 0,
            index: 0
        }, new Uint8Array(newAddr));
        if (result.status === 'ok') {
            console.log('Address set successfully');
            this.currentAddress = newAddr;
        } else {
            console.error('Failed to set address:', result.status);
        }
    }
    setPower = async(power) => {
        const result = await this.device.controlTransferOut({
            requestType: 'vendor',
            recipient: 'device',
            request: SET_RADIO_POWER,
            value: power,
            index: 0
        });
        if (result.status === 'ok') {
            console.log('Power set successfully');
        } else {
            console.error('Failed to set power:', result.status);
        }
    }
    
    setArc = async(arc) => {
        const result = await this.device.controlTransferOut({
            requestType: 'vendor',
            recipient: 'device',
            request: SET_RADIO_ARC,
            value: arc,
            index: 0
        });
        if (result.status === 'ok') {
            console.log('ARC set successfully');
        } else {
            console.error('Failed to set ARC:', result.status);
        }
    }

    setArdBytes = async(nbytes) => {
        const result = await this.device.controlTransferOut({
            requestType: 'vendor',
            recipient: 'device',
            request: SET_RADIO_ARD,
            value: 0x80 | nbytes,
            index: 0
        });
        if (result.status === 'ok') {
            console.log('ARD bytes set successfully');
        } else {
            console.error('Failed to set ARD bytes:', result.status);
        }
    }

    setAckEnable = async(enable) => {
        const value = enable ? 1 : 0;
        const result = await this.device.controlTransferOut({
            requestType: 'vendor',
            recipient: 'device',
            request: ACK_ENABLE,
            value: value,
            index: 0
        });
        if (result.status === 'ok') {
            console.log('ACK enable set successfully');
        } else {
            console.error('Failed to set ACK enable:', result.status);
        }
    }


    sendPacketSafe = async (packet) => {
        packet[0] &= 0xF3;
        packet[0] |= (this._curr_up << 3) | (this._curr_down << 2);
    
        const resp = await this.sendPacket(packet);
    
        if (resp && resp.ack && resp.data.length > 0 && (resp.data[0] & 0x04) === (this._curr_down << 2)) {
            this._curr_down = 1 - this._curr_down;
        }
    
        if (resp && resp.ack) {
            this._curr_up = 1 - this._curr_up;
        }
    
        return resp;
    }

}


export class Toc {
    /**
     * Container for TocElements.
     */
    constructor() {
        this.toc = {};
    }

    clear() {
        /**
         * Clear the TOC
         */
        this.toc = {};
    }

    addElement(element) {
        /**
         * Add a new TocElement to the TOC container.
         */
        if (!this.toc[element.group]) {
            this.toc[element.group] = {};
        }
        this.toc[element.group][element.name] = element;
    }

    getElementByCompleteName(completeName) {
        /**
         * Get a TocElement element identified by complete name from the container.
         */
        try {
            return this.getElementById(this.getElementId(completeName));
        } catch (error) {
            // Item not found
            return null;
        }
    }

    getElementId(completeName) {
        /**
         * Get the TocElement element id-number of the element with the supplied name.
         */
        const [group, name] = completeName.split('.');
        const element = this.getElement(group, name);
        if (element) {
            return element.ident;
        } else {
            console.warn(`Unable to find variable [${completeName}]`);
            return null;
        }
    }

    getElement(group, name) {
        /**
         * Get a TocElement element identified by name and group from the container.
         */
        if (this.toc[group] && this.toc[group][name]) {
            return this.toc[group][name];
        } else {
            return null;
        }
    }

    getElementById(ident) {
        /**
         * Get a TocElement element identified by index number from the container.
         */
        for (const group of Object.keys(this.toc)) {
            for (const name of Object.keys(this.toc[group])) {
                if (this.toc[group][name].ident === ident) {
                    return this.toc[group][name];
                }
            }
        }
        return null;
    }
}



const TOC_CHANNEL = 0
// Commands used when accessing the Table of Contents
const CMD_TOC_ELEMENT = 0  // original version: up to 255 entries
const CMD_TOC_INFO = 1    // original version: up to 255 entries
const CMD_TOC_ITEM_V2 = 2  // version 2: up to 16k entries
const CMD_TOC_INFO_V2 = 3  // version 2: up to 16k entries
// 
const TOC_IDLE = 'IDLE'
const GET_TOC_INFO = 'GET_TOC_INFO'
const GET_TOC_ELEMENT = 'GET_TOC_ELEMENT'

export class TocFetcher {

    constructor(crazyflie, port, toc_holder, toc_cache, elementClass) {
        this.cf = crazyflie;
        this.port = port;
        this.toc = toc_holder;
        this._toc_cache = toc_cache

        this._crc = 0;
        this.nbr_of_items = null;
        this.requested_index = 0;
        this.elementClass = elementClass;

        this.state = null;

        this._useV2 = false;

    }

    start = async () => {

        this._useV2 = this.cf.protocolVersion >= 4;

        let pk = new CRTPPacket();
        pk.set_header(this.port, TOC_CHANNEL);
        if (this._useV2) {
            pk.data = new Uint8Array([CMD_TOC_INFO_V2]);
        } else {
            pk.data = new Uint8Array([CMD_TOC_INFO]);
        }

        this.state = GET_TOC_INFO;
        let inPacket = await this.cf.sendPacketAndWaitForResponse({
            pk: pk,
            port: this.port,
            channel: TOC_CHANNEL,
            timeout: 3000,
            resend: true
        });
        //console.log(inPacket);

        let payload = inPacket.data.slice(1);
        if (this._useV2) {
            const buffer = payload.buffer.slice(0, 6);
            const dataView = new DataView(buffer);
            
            this.nbr_of_items = dataView.getUint16(0, true); // Read 2 bytes as unsigned integer (little-endian)
            this._crc = dataView.getUint32(2, true); // Read 4 bytes as unsigned integer (little-endian)
        } else {
            const buffer = payload.buffer.slice(0, 5);
            const dataView = new DataView(buffer);
            
            this.nbr_of_items = dataView.getUint8(0); // Read 1 byte as unsigned integer
            this._crc = dataView.getUint32(1, true); // Read 4 bytes as unsigned integer (little-endian)
        }

        // if cache data is available, use it
        if (this._toc_cache !== null) {
            this.toc.toc = this._toc_cache;
            return;
        }


        this.state = GET_TOC_ELEMENT;
        
        this.requested_index = 0
        //console.log("num elements: ", this.nbr_of_items);
        while (this.state === GET_TOC_ELEMENT) {

            pk = new CRTPPacket();
            pk.set_header(this.port, TOC_CHANNEL);
            if (this._useV2) {
                pk.data = new Uint8Array([CMD_TOC_ITEM_V2, this.requested_index & 0x0ff, (this.requested_index >> 8) & 0x0ff]);
            } else {
                pk.data = new Uint8Array([CMD_TOC_ELEMENT, this.requested_index]);
            }
            inPacket = await this.cf.sendPacketAndWaitForResponse({
                pk: pk,
                port: this.port,
                channel: TOC_CHANNEL,
                timeout: 500,
                resend: true
            });
            const chan = inPacket.channel
            if (chan != 0)
                continue         

            payload = inPacket.data.slice(1);
            let ident = null;
            if (this._useV2) {
                const buffer = payload.buffer.slice(0, 2);
                const dataView = new DataView(buffer);
                ident = dataView.getUint16(0, true); // Read 2 bytes as unsigned integer (little-endian)
            } else {
                ident = payload[0];
            }
            //console.log(ident, this.toc_requested_index)
            if (ident !== this.requested_index) {
                continue;
            }

            if (this._useV2) {
                this.toc.addElement(new this.elementClass(ident, payload.slice(2)));
                //console.log(this.toc.getElementById(ident));
            } else {
                this.toc.addElement(new this.elementClass(ident, payload.slice(1)));
            }

            if (this.requested_index < (this.nbr_of_items - 1)) {
                //console.log(this.requested_index)
                this.requested_index += 1;
            } else { // No More Variables in TOC, Exit Loop
                console.log("done toc fetch loop")
                //console.log(this.toc);
                this.state = TOC_IDLE;   
            }
        }




    }

}












export class CRTPPort {
    /**
     * Lists the available ports for the CRTP.
     */
    static CONSOLE = 0x00;
    static PARAM = 0x02;
    static COMMANDER = 0x03;
    static MEM = 0x04;
    static LOGGING = 0x05;
    static LOCALIZATION = 0x06;
    static COMMANDER_GENERIC = 0x07;
    static SETPOINT_HL = 0x08;
    static PLATFORM = 0x0D;
    static LINKCTRL = 0x0F;
    static ALL = 0xFF;
}

export class CRTPPacket {
    /**
     * A packet that can be sent via the CRTP.
     */

    // The max size of a CRTP packet payload
    static MAX_DATA_SIZE = 30;

    constructor(header = 0, data = null) {
        /**
         * Create an empty packet with default values.
         */
        this.size = 0;
        this._data = new Uint8Array();
        // The two bits in position 3 and 4 needs to be set for legacy
        // support of the bootloader
        this.header = header | (0x3 << 2);
        this._port = (header & 0xF0) >> 4;
        this._channel = header & 0x03;
        if (data) {
            this._set_data(data);
        }
    }

    _get_channel() {
        /** Get the packet channel */
        return this._channel;
    }

    _set_channel(channel) {
        /** Set the packet channel */
        this._channel = channel;
        this._update_header();
    }

    _get_port() {
        /** Get the packet port */
        return this._port;
    }

    _set_port(port) {
        /** Set the packet port */
        this._port = port;
        this._update_header();
    }

    get_header() {
        /** Get the header */
        this._update_header();
        return this.header;
    }

    set_header(port, channel) {
        /**
         * Set the port and channel for this packet.
         */
        this._port = port;
        this._channel = channel;
        this._update_header();
    }

    _update_header() {
        /** Update the header with the port/channel values */
        // The two bits in position 3 and 4 needs to be set for legacy
        // support of the bootloader
        this.header = ((this._port & 0x0f) << 4) | (3 << 2) | (this._channel & 0x03);
    }

    _get_data() {
        /** Get the packet data */
        return this._data;
    }

    _set_data(data) {
        /** Set the packet data */
        if (data instanceof Uint8Array) {
            this._data = data;
        } else if (typeof data === 'string') {
            this._data = new Uint8Array(data.split('').map(char => char.charCodeAt(0)));
        } else if (Array.isArray(data)) {
            this._data = new Uint8Array(data);
        } else if (data instanceof ArrayBuffer) {
            this._data = new Uint8Array(data);
        } else {
            throw new Error('Data must be Uint8Array, string, array, or ArrayBuffer, not ' + typeof data);
        }
    }

    _get_data_l() {
        /** Get the data in the packet as a list */
        return Array.from(this._get_data());
    }

    _get_data_t() {
        /** Get the data in the packet as a tuple */
        return Array.from(this._get_data());
    }

    toString() {
        /** Get a string representation of the packet */
        return `${this._port}:${this._channel} ${this._data}`;
    }

    get_data_size() {
        return this._data.length;
    }

    available_data_size() {
        return CRTPPacket.MAX_DATA_SIZE - this.get_data_size();
    }

    is_data_size_valid() {
        return this.available_data_size() >= 0;
    }

    get data() {
        return this._get_data();
    }

    set data(value) {
        this._set_data(value);
    }

    get datal() {
        return this._get_data_l();
    }

    set datal(value) {
        this._set_data(value);
    }

    get datat() {
        return this._get_data_t();
    }

    set datat(value) {
        this._set_data(value);
    }

    get datas() {
        return this._get_data();
    }

    set datas(value) {
        this._set_data(value);
    }

    get port() {
        return this._get_port();
    }

    set port(value) {
        this._set_port(value);
    }

    get channel() {
        return this._get_channel();
    }

    set channel(value) {
        this._set_channel(value);
    }
}

export function unpackValue(dataView, dataIndex, unpackString, littleEndian) {
    let value;
    switch (unpackString) {
        case '<B': // uint8_t
            value = dataView.getUint8(dataIndex);
            break;
        case '<H': // uint16_t
            value = dataView.getUint16(dataIndex, littleEndian);
            break;
        case '<L': // uint32_t
            value = dataView.getUint32(dataIndex, littleEndian);
            break;
        case '<b': // int8_t
            value = dataView.getInt8(dataIndex);
            break;
        case '<h': // int16_t
            value = dataView.getInt16(dataIndex, littleEndian);
            break;
        case '<i': // int32_t
            value = dataView.getInt32(dataIndex, littleEndian);
            break;
        case '<e': // FP16 (half-precision float)
            value = getFloat16(dataView, dataIndex, littleEndian);
            break;
        case '<f': // float
            value = dataView.getFloat32(dataIndex, littleEndian);
            break;
        default:
            throw new Error(`Unsupported unpack string: ${unpackString}`);
    }
    return value;
}

export function getFloat16(dataView, byteOffset, littleEndian) {
    // Implement FP16 (half-precision float) conversion if needed
    // This is a placeholder implementation
    const uint16 = dataView.getUint16(byteOffset, littleEndian);
    const exponent = (uint16 & 0x7C00) >> 10;
    const fraction = uint16 & 0x03FF;
    let value;
    if (exponent === 0) {
        value = fraction * Math.pow(2, -24);
    } else if (exponent === 0x1F) {
        value = fraction ? NaN : Infinity;
    } else {
        value = (fraction + 0x0400) * Math.pow(2, exponent - 25);
    }
    return (uint16 & 0x8000) ? -value : value;
}


// Mapping function
export function mapValue(value, fromMin, fromMax, toMin, toMax) {
    return (value - fromMin) * (toMax - toMin) / (fromMax - fromMin) + toMin;
}


export const ERR_MAP = {
    ENOMEM: 12,
    ENOEXEC: 8,
    ENOENT: 2,
    E2BIG: 7,
    EEXIST: 17,
}

export const ERR_CODES = {
    12: 'No more memory available', // ENOMEM
    8: 'Command not found',         // ENOEXEC
    2: 'No such block id',          // ENOENT
    7: 'Block too large',           // E2BIG
    17: 'Block already exists'      // EEXIST
};