import { CRTPPacket, CRTPPort, ERR_MAP, Toc, TocFetcher } from "./LCExtra";


// Possible states
const IDLE = 0
const WAIT_TOC = 1
const WAIT_READ = 2
const WAIT_WRITE = 3

const TOC_CHANNEL = 0
const READ_CHANNEL = 1
const WRITE_CHANNEL = 2
const MISC_CHANNEL = 3

const MISC_SETBYNAME = 0
const MISC_VALUE_UPDATED = 1
const MISC_GET_EXTENDED_TYPE = 2
const MISC_PERSISTENT_STORE = 3
const MISC_PERSISTENT_GET_STATE = 4
const MISC_PERSISTENT_CLEAR = 5
const MISC_GET_DEFAULT_VALUE = 6

class ParamTocElement {
    /**
     * An element in the Log TOC.
     */

    static RW_ACCESS = 0;
    static RO_ACCESS = 1;

    static EXTENDED_PERSISTENT = 1;

    static types = {
        0x08: ['uint8_t', '<B'],
        0x09: ['uint16_t', '<H'],
        0x0A: ['uint32_t', '<L'],
        0x0B: ['uint64_t', '<Q'],
        0x00: ['int8_t', '<b'],
        0x01: ['int16_t', '<h'],
        0x02: ['int32_t', '<i'],
        0x03: ['int64_t', '<q'],
        0x05: ['FP16', ''],
        0x06: ['float', '<f'],
        0x07: ['double', '<d']
    };

    constructor(ident = 0, data = null) {
        /**
         * TocElement creator. Data is the binary payload of the element.
         */
        this.ident = ident;
        this.persistent = false;
        this.extended = false;
        if (data) {
            let strs = Array.from(data.slice(1)).map(byte => String.fromCharCode(byte)).join('');
            strs = strs.split('\x00');
            this.group = strs[0];
            this.name = strs[1];

            let metadata = data[0];
            if (typeof metadata === 'string') {
                metadata = metadata.charCodeAt(0);
            }

            // If the fourth byte (1 << 4) (0x10) is set we have extended
            // type information for this element.
            this.extended = ((metadata & 0x10) !== 0);

            this.ctype = ParamTocElement.types[metadata & 0x0F][0];
            this.pytype = ParamTocElement.types[metadata & 0x0F][1];
            if ((metadata & 0x40) !== 0) {
                this.access = ParamTocElement.RO_ACCESS;
            } else {
                this.access = ParamTocElement.RW_ACCESS;
            }
        }
    }

    get_readable_access() {
        if (this.access === ParamTocElement.RO_ACCESS) {
            return 'RO';
        }
        return 'RW';
    }

    is_extended() {
        return this.extended;
    }

    mark_persistent() {
        this.persistent = true;
    }

    is_persistent() {
        return this.persistent;
    }
}

export class Param {

    constructor(crazyflie) {
        this.toc = null;

        this.cf = crazyflie;

        this._lock_pattern = null;
        this.release_pattern = null;
    }



    refresh_toc = async (protocolVersion, toc_cache) => {

        this._useV2 = protocolVersion >= 4;
        this._toc_cache = toc_cache;
       
        this.toc = new Toc();
        let tocFetcher = new TocFetcher(
            this.cf, CRTPPort.PARAM, this.toc, this._toc_cache, ParamTocElement
        );

        await tocFetcher.start();
    }

    set_value = async(complete_name, value) => {

        // skipping those everything checks

        const element = this.toc.getElementByCompleteName(complete_name)
        //console.log(element);
        if (element === null) {
            console.warning("Cannot set value for [%s], it's not in the TOC!",
                       complete_name)
            throw new Error('{} not in param TOC'.format(complete_name))
        } else if (element.access === ParamTocElement.RO_ACCESS) {
            console.warning("Cannot set value for [%s], it's read-only!",
                       complete_name)
            throw new Error('{} is read-only'.format(complete_name))
        } else {

            const varid = element.ident;

            let pk = new CRTPPacket();
            pk.set_header(CRTPPort.PARAM, WRITE_CHANNEL);
            if (this._useV2) {
                const buffer = new ArrayBuffer(2);
                const view = new DataView(buffer);
                view.setUint16(0, varid, true);
                pk.data = new Uint8Array(buffer);
            } else {
                const buffer = new ArrayBuffer(1);
                const view = new DataView(buffer);
                view.setUint8(0, varid);
                pk.data = new Uint8Array(buffer);
            }

            let value_nr;
            if (element.pytype === '<f' || element.pytype === '<d') {
                value_nr = parseFloat(value);
            } else {
                value_nr = parseInt(value, 10);
            }
            const valueBuffer = createValueBuffer(element.pytype, value_nr);
            const valueArray = new Uint8Array(valueBuffer);
            const combinedData = new Uint8Array(pk.data.length + valueArray.length);
            combinedData.set(pk.data);
            combinedData.set(valueArray, pk.data.length);
            pk.data = combinedData;

            const inPacket = await this.paramUpdaterSend(pk);
                
            if (this._useV2) {
                this.release_pattern = inPacket.data.slice(0, 2)
                // this is only for read_channel, not write_channel
                //pk.data = new Uint8Array([...pk.data.slice(0, 2), ...pk.data.slice(3)]);
            } else {
                this.release_pattern = inPacket.data.slice(0, 1)
            }
    
            const res = this.handleParamPacket(inPacket);
            return res;
        }
    };

    get_value = async(complete_name) => {

    };


    // this is what they named the read
    request_param_update = async(complete_name) => {

        this._useV2 = this.cf.protocolVersion >= 4;

        const var_id = this.toc.getElementId(complete_name);

        let pk = new CRTPPacket();
        pk.set_header(CRTPPort.PARAM, READ_CHANNEL);
        if (this._useV2) {
            const buffer = new ArrayBuffer(2);
            const view = new DataView(buffer);
            view.setUint16(0, var_id, true);
            pk.data = new Uint8Array(buffer);
        } else {
            const buffer = new ArrayBuffer(1);
            const view = new DataView(buffer);
            view.setUint8(0, var_id);
            pk.data = new Uint8Array(buffer);
        }

        const inPacket = await this.paramUpdaterSend(pk);

        if (this._useV2) {

            this.release_pattern = inPacket.data.slice(0, 2)
            inPacket.data = new Uint8Array([...inPacket.data.slice(0, 2), ...inPacket.data.slice(3)]);

        } else {

            this.release_pattern = pk.data.slice(0, 1)
        }

        const res = this.handleParamPacket(inPacket);
        return res;
    };

    handleParamPacket = (packet) => {
        let var_id = 0;
        let id_index = 0;
        if (packet.channel === MISC_CHANNEL) {
            id_index = 1
        } else {
            id_index = 0
        }

        if (this._useV2 === true) {
            // Assuming pk.data is a Uint8Array or similar array-like object
            const dataView = new DataView(packet.data.buffer);
            var_id = dataView.getUint16(id_index, true); // true for little-endian
        } else {
            var_id = packet.data.slice(0);
        }

        const element = this.toc.getElementById(var_id);
        if (element) {

            let value;

            if (this._useV2) {
                value = getValue(element.pytype, packet.data, id_index + 2);
            } else {
                value = getValue(element.pytype, packet.data, 1);
            }
            
            //const value_s = value.toString();
            return value;
        } else {
            console.error("Element not found in toc");
            return null;
        }
    };

    getDefaultValue = async(complete_name) => {

        const element = this.toc.getElementByCompleteName(complete_name);

        let pk = new CRTPPacket();
        pk.set_header(CRTPPort.PARAM, MISC_CHANNEL);

        const buffer = new ArrayBuffer(3); // 1 byte for MISC_GET_DEFAULT_VALUE and 2 bytes for element.ident
        const view = new DataView(buffer);
        view.setUint8(0, MISC_GET_DEFAULT_VALUE);
        view.setUint16(1, element.ident, true); // true for little-endian
        pk.data = new Uint8Array(buffer);

        //send_param_misc
        // puts it on queue, then needs
        const inPacket = await this.paramUpdaterSend(pk);

        if (inPacket.data[3] === ERR_MAP.ENOENT) {
            // throw error here
            console.error("Element not found");
            return null;
        } else {
            const default_value = getValue(element.pytype, inPacket.data, 3);
            return default_value;
        }
    };

    paramUpdaterSend = async(pk) => {

        if (this._useV2) {
            if (pk.channel === MISC_CHANNEL) {
                this._lock_pattern = pk.data.slice(0, 3)
            } else {
                this._lock_pattern = pk.data.slice(0, 2)
            }
            //console.log('param packet', pk);
            //console.log('lock pattern', this._lock_pattern);
            let inPacket = await this.cf.sendPacketAndWaitForResponse({
                pk: pk,
                port: CRTPPort.PARAM,
                channel: pk.channel,
                timeout: 1000,
                resend: true,
            });
            //console.log("here: ", inPacket);
            return inPacket;

        } else {
            this._lock_pattern = pk.data.slice(0, 1)
            //self.cf.send_packet(pk, expected_reply=(tuple(pk.data[:1])))
        }

    }
    

}




function getValue(ptype, data, offset) {

    const dataView = new DataView(data.buffer, data.byteOffset, data.byteLength);
    let value;

    switch (ptype) {
        case '<B':
            value = dataView.getUint8(offset);
            break;
        case '<H':
            value = dataView.getUint16(offset, true);
            break;
        case '<L':
            value = dataView.getUint32(offset, true);
            break;
        case '<b':
            value = dataView.getInt8(offset);
            break;
        case '<h':
            value = dataView.getInt16(offset, true);
            break;
        case '<i':
            value = dataView.getInt32(offset, true);
            break;
        case '<f':
            value = dataView.getFloat32(offset, true);
            break;
        case '<d':
            value = dataView.getFloat64(offset, true);
            break;
        default:
            throw new Error('Unsupported type');
    }
    return value;
}


function createValueBuffer(pytype, value_nr) {
    let valueBuffer;
    switch (pytype) {
        case '<B':
            valueBuffer = new ArrayBuffer(1);
            new DataView(valueBuffer).setUint8(0, value_nr);
            break;
        case '<H':
            valueBuffer = new ArrayBuffer(2);
            new DataView(valueBuffer).setUint16(0, value_nr, true);
            break;
        case '<L':
            valueBuffer = new ArrayBuffer(4);
            new DataView(valueBuffer).setUint32(0, value_nr, true);
            break;
        case '<Q':
            // JavaScript does not support 64-bit integers directly, so this is a placeholder
            // You might need a library like long.js for handling 64-bit integers
            break;
        case '<b':
            valueBuffer = new ArrayBuffer(1);
            new DataView(valueBuffer).setInt8(0, value_nr);
            break;
        case '<h':
            valueBuffer = new ArrayBuffer(2);
            new DataView(valueBuffer).setInt16(0, value_nr, true);
            break;
        case '<i':
            valueBuffer = new ArrayBuffer(4);
            new DataView(valueBuffer).setInt32(0, value_nr, true);
            break;
        case '<q':
            // JavaScript does not support 64-bit integers directly, so this is a placeholder
            // You might need a library like long.js for handling 64-bit integers
            break;
        case '<f':
            valueBuffer = new ArrayBuffer(4);
            new DataView(valueBuffer).setFloat32(0, value_nr, true);
            break;
        case '<d':
            valueBuffer = new ArrayBuffer(8);
            new DataView(valueBuffer).setFloat64(0, value_nr, true);
            break;
        default:
            throw new Error('Unsupported type');
    }
    return valueBuffer;
}
