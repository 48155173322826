// Import React and Material-UI Modules
import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Topbar from "./Topbar";
import Button from "@material-ui/core/Button";

// Material-UI CSS-type Style Specifications
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary["light"],
    overflow: "hidden",
    backgroundPosition: "0 400px",
    marginTop: 20,
    padding: 20,
    paddingBottom: 200
  },
  actionButton: {
    type: "submit",
    justify: "center",
    margin: theme.spacing(1),
    color: theme.palette.primary["contrastText"],
    backgroundColor: "green",
    fontSize: '15px',
  },
  grid: {
    width: 1000,
    height: 550
  }
});

// Component Class - Academy
class Academy extends Component {

  constructor(props) {
    super(props);

    this.iframeAcad = React.createRef();

  }


  state = {

  };


  handleFileLoad = () => {
    //console.log(this.iframeAcad.current.contentWindow.location.href);
  };

  handleOpenPage = () => {
    window.open("https://learn-locorobo.com/", "_blank") //to open new page    
  }

/*
<Grid item xs={12}>
  <iframe ref={this.iframeAcad} onLoad={this.handleFileLoad} title="moodle" src="https://learn-locorobo.co/" width = '1050px' height = '575px' allowFullScreen></iframe>
</Grid>
*/

  // Takes input data, returns what to display
  render() {

    // Referenced below for setting styles
    const { classes } = this.props;
    // For TopBar Route Highlight
    const currentPath = this.props.location.pathname;

    // What to Display - iframe for Moodle
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Grid container justifyContent="center">
            <Grid
              spacing={2}
              alignItems="flex-start"
              justifyContent="center"
              container
              className={classes.grid}
            >
              <Grid item xs={12}>
                <Button
                  title="LocoRobo Academy"
                  onClick={this.handleOpenPage}
                  className={classes.actionButton}
                >
                  Open LocoRobo Academy
                </Button>
              </Grid>
              
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Academy);
