// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import FormControl from "@material-ui/core/FormControl";
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';


import { LocoXtreme, MotorDirectionC, Preference, PreferenceSettingC, WaitTypeC } from "../../libraries/LocoXtreme";
import GeneralMessage from "../drawers/GeneralMessage"
import { AuthContext } from '../../context/auth';
import { Divider } from '@material-ui/core';


const LG_HEIGHT = '500px'
const DEFAULT_HEIGHT = '425px'

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 500,//400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: 'scroll',
      overflowX: 'hidden',
      //overflow: 'visible',
    },    
    paperPlot: {
        flexGrow: 1,
        padding: theme.spacing(1),
        textAlign: "center",
        justify: "center",
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],        
        height: theme.spacing(42),
        width: theme.spacing(52),
    },
    inputHelper: {
        marginLeft: theme.spacing(2),
    },
    actionButton: {
      //justify: "center",
      margin: theme.spacing(0),
      color: "white",
    },  
    cancelButton: {
        marginTop: theme.spacing(-0.5),
        color: "white",
    },
    titleText: {
      color: "white",
      margin: theme.spacing(1),
    },
    lightIndices: {
        borderRadius: '70%',
        width: '25px',
        height: '25px',
    }, 
    inputField: {
        width: "90%",
        marginLeft: theme.spacing(2),
    },
    grid: {
        margin: theme.spacing(0),
        justify: "space-between",
        justifyContent: "space-between",
    },
    
});

// For Name to RGB LED Colors - Get Colors
function nameToColors(name) {

    let hashNum = lightHash(name);

    let nameColors = []
    let sF = 255
    for (let i = 0; i < 8; i++) {
        let tempHash = hashNum;
        let h = tempHash >> (i * 3)
        let r = sF * ((h >> 2) & 1)
        let g = sF * ((h >> 1) & 1)
        let b = sF * ((h >> 0) & 1)

        nameColors.push([r, g, b])
    }

    return nameColors;
}

// For Name to RGB LED Colors - Apply Hash Code
function lightHash(nameStr) {
    let hashNum = 5381;
    for (let i = 0; i < nameStr.length; i++) {
        hashNum = ((hashNum << 5) + hashNum) + nameStr.charCodeAt(i);
    }
    return hashNum;
}

// For Name to RGB LED Colors - Convert to HEX String
function componentToHex(c) {
    let hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

// For Name to RGB LED Colors - Create Full RGB Hex String
function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
  

// Constants for Modal Top Text
const STATE_TEXT_OPTS = ["Scan For Robots", "Scanning...", "Select Robot", "Connecting...", "Connected"];
const STATE_TEXT_ERRS = ["No Robots Found, Try Scanning Again", "Could Not Connect to Robot, Try Again", "Data Error, Disconnected"];

// Not Used Anymore
const MOTORS_MAP = {0: "Normal", 1: "Flipped"};

const CONNECT_ROBOT = {'MSG': "Connected to Robot", 'TIME': 2500, 'COLOR': 'green'}
const CONNECT_FAIL = {'MSG': "Could not Connect to Robot", 'TIME': 3500, 'COLOR': 'red'}
const DISCONNECT_ROBOT = {'MSG': "Disconnected from Robot", 'TIME': 3500, 'COLOR': '#f57c00'}
const NAME_ROBOT = {'MSG': "Robot Name Changed", 'TIME': 3500, 'COLOR': 'green'}
const ULTRASONIC_SET = {'MSG': "Ultrasonic Filter Set", 'TIME': 3500, 'COLOR': 'green'}
const VEL_TARGETS_SET = {'MSG': "Velocity Targets Set", 'TIME': 3500, 'COLOR': 'green'}
const R_TICKS_SET = {'MSG': "Rotation Conversion Set", 'TIME': 3500, 'COLOR': 'green'}
const DATA_ERROR = {'MSG': "Error Reading Data", 'TIME': 3500, 'COLOR': 'red'}
const FILTER_SIZE_ERR = {'MSG': "Invalid Filter Value, Resetting to 20", 'TIME': 3500, 'COLOR': '#f57c00'}

const ULTRA_PLOT_BUTTON_STRS = ['Start Ultrasonic Data Plot', 'Stop Ultrasonic Data Plot']
const ULTRA_PLOT_RAW_STRS = ['Show Raw Data ', 'Hide Raw Data']
const PLOT_RATE_MS = 150 // milliseconds


// Component Class
class LXSettingsModal extends React.Component {

    // For Use of AuthContext
    static contextType = AuthContext;

    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);

        // Modal Behavior Options
        this.MODE_OPTIONS = ["CONVERSION", "SEARCH", "SETTINGS", "PICK", "GUI", "SELECT"];
        
        // React Element For Copy-to-Clipboard Drawer
        this.msgElement = React.createRef();

        // 
        this.charRef0 = React.createRef();
    }


    // Class state
    state = {
        isOpen: false, // Modal display state
        LXObj: null, // Class instance object for LocoXtreme.js
        scanning: false, // Flag for scanning state
        scan_time: 4000, // scan duration, milliseconds
        disable_for_scan: false, // Flag for scanning state to control buttons enable states
        robotNames: [], // List of found robots
        connectionState: 0, // What layout the modal should display as
        stateMessage: STATE_TEXT_OPTS[0], // Top message in modal
        robot_name_input: "", // Input field state value for robot name change in SETTINGS mode
        circleGrids: [0, 1, 2, 3, 4, 5, 6, 7], // Index values for LED render circles
        circleGridWidths: [12, 6, 6, 6, 6, 6, 6, 12], // Grid widths for LED render circles
        colorMarginsL: ['0px', '92px', '0px', '62px', '0px', '92px', '0px', '0px', ], // Left-hand margin values for LED render circles
        colorMarginsR: ['0px', '0px', '92px', '0px', '62px', '0px', '92px', '0px', ], // Right-hand margin values for LED render circles
        colorsPosShift: ['11px', '0px', '0px', '0px', '0px', '0px', '0px', '-11px', ], // Something
        colorsMap: {0: 0, 1: 2, 2: 4, 3: 6, 4: 7, 5: 5, 6: 3, 7: 1}, // Relates circle index in render to list index
        circleColors: {}, // Stores RGB circle colors
        leftMotor: "", // No longer user
        rightMotor: "", // No longer user
        robot_filter_input: "", // Ultrasonic filter input field state storage
        functionality_mode: "", // Modal behavior string state storage
        robotSelected: "", // Stores robot name, seems unused
        cancelMsg: "Cancel", 
        plotUltrasonicStr: ULTRA_PLOT_BUTTON_STRS[0],
        showRawUltrasonicStr: ULTRA_PLOT_RAW_STRS[0],
        showRaw: false,
        isPlotting: false,
        plottingTimerID: null,
        maxHeightPaper: DEFAULT_HEIGHT,
        robot_vel_target_input_l: "",
        robot_vel_target_input_r: "",
        robot_r_ticks: "",
        showTuning: false,
        driveTestDist: 29,
        oldFirmwareVel: false,
    }

    componentDidMount() {
        // Make empty window.port
        window.port = {}
    }

    componentWillUnmount() {
        if (this.state.isPlotting === true) {
            clearTimeout(this.state.plottingTimerID)
            this.setState( {isPlotting: false} )
        }
    }

    // Handle Making Modal Visible with provided 'functionality_mode' state
    handleOpen = async (functionality_mode, robotObject={}) => {
        
        // Re-initialize certain state variables
        this.setState( {connectionState: 0, robotNames: [], showTuning: false} )
        
        // Create new LocoXtreme class instance
        let tempLXObj = new LocoXtreme()

        // If functionality_mode is for SETTINGS
        if (functionality_mode === this.MODE_OPTIONS[2]) {

            this.setState( {        
                plotUltrasonicStr: ULTRA_PLOT_BUTTON_STRS[0],
                showRawUltrasonicStr: ULTRA_PLOT_RAW_STRS[0],
                showRaw: false,
                isPlotting: false,
                plottingTimerID: null,
            });
            
            // Check if a robot object was passed in or not
            if (Object.keys(robotObject).length > 0) {

                // Try resetting the webserial reader
                try {
                    await tempLXObj.connect_reset_reader();
                } catch {
                    return
                }

                // Add provided robot object to relevant LocoXtreme class instance variables (because scan doesn't, because scan isn't used in this case)
                tempLXObj.robots.push(robotObject)
                tempLXObj.sharedData.robots[robotObject.name] = {
                    macAddr: robotObject.macAddr, macType: robotObject.macType, 
                    Data: {}, Program: {}, Preference: {}, Calibration: {}
                };
                // Update state
                this.setState( {LXObj: tempLXObj} )
                this.setState( {disable_for_scan: true, stateMessage: STATE_TEXT_OPTS[3]} )
                this.setState( {robot_name_input: ""} );
                
                // Connect to LX robot, only robot object passed in inside relevant variables, so connect_ble() input index is 0
                tempLXObj.connect_ble(0).then(async () => {

                    // Get robot data needed to populate modal
                    let uss = await tempLXObj.get_ultrasonic_filter();
                    if (uss === 100) {
                        this.msgElement.current.handleOpen(FILTER_SIZE_ERR['COLOR'], FILTER_SIZE_ERR['MSG'], FILTER_SIZE_ERR['TIME'])
                        await this.state.LXObj.set_ultrasonic_filter(20);
                        uss = await tempLXObj.get_ultrasonic_filter();
                    }
                    
                    let data_check = await this.verifyData(uss)
                    if (data_check === "fail") {this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); 
                        this.msgElement.current.handleOpen(DATA_ERROR['COLOR'], DATA_ERROR['MSG'], DATA_ERROR['TIME'])
                        return
                    }

                    
                    let vel_targets = await tempLXObj.motor_controllers(
                        Preference.VelTargets, 
                        PreferenceSettingC.PreferenceGet
                    )
                    if (vel_targets === "timeout") {
                        this.setState( {oldFirmwareVel: true} )
                    }
                    
                    //data_check = await this.verifyData(vel_targets)
                    // Check for timeout
                    //if (data_check === "fail") {
                    //    this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); 
                    //    this.msgElement.current.handleOpen(DATA_ERROR['COLOR'], DATA_ERROR['MSG'], DATA_ERROR['TIME'])
                    //    return
                    //}


                    // Check Values
                    let r_ticks = await this.state.LXObj.motor_controllers(
                        Preference.RTicks, 
                        PreferenceSettingC.PreferenceGet
                    )
                    
                    data_check = await this.verifyData(r_ticks)
                    if (data_check === "fail") {this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); 
                        this.msgElement.current.handleOpen(DATA_ERROR['COLOR'], DATA_ERROR['MSG'], DATA_ERROR['TIME'])
                        return
                    }
                    
                    // Update state
                    if (vel_targets !== "timeout") {
                        this.setState( {
                            robot_vel_target_input_l: vel_targets.v_target_l.toString(),
                            robot_vel_target_input_r: vel_targets.v_target_r.toString(),
                            robot_filter_input: uss.toString(),
                            robot_r_ticks: r_ticks.toString(),
                            connectionState: 2, stateMessage: STATE_TEXT_OPTS[4], cancelMsg: "Close",
                            disable_for_scan: false
                        } )
                    } else {
                        this.setState( {
                            robot_filter_input: uss.toString(),
                            robot_r_ticks: r_ticks.toString(),
                            connectionState: 2, stateMessage: STATE_TEXT_OPTS[4], cancelMsg: "Close",
                            disable_for_scan: false
                        } )
                    }
    
                }).catch((err) => {
                    // Could not connect
                    this.msgElement.current.handleOpen(CONNECT_FAIL['COLOR'], CONNECT_FAIL['MSG'], CONNECT_FAIL['TIME'])
                    this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[0]} );
                    this.setState( {disable_for_scan: false} )
                });
            }
        } 
        // If functionality_mode is for PICK
        else if (functionality_mode === this.MODE_OPTIONS[3]) {
            // Check for robot object input, which is needed
            if (Object.keys(robotObject).length < 1) {
                this.setState( {stateMessage: STATE_TEXT_ERRS[0]} )
            } else {

                // Get robots found
                let robotNamesTemp = []
                for (let i = 0; i < robotObject.length; i++) {
                    let newName = robotObject[i]['name']
                    if (robotNamesTemp.includes(newName) === false) {
                        robotNamesTemp.push(newName)
                    }
                }
                // Generate colors for render of robots found
                let tempCircleColors = this.state.circleColors;
                for (let i = 0; i < robotNamesTemp.length; i++) {
                    let circleColors = nameToColors(robotNamesTemp[i]);                   
                    let hexColors = ['#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'];
                    for (let k = 0; k < circleColors.length; k++) {
                        hexColors[this.state.colorsMap[k]] = rgbToHex(circleColors[k][0], circleColors[k][1], circleColors[k][2])
                        if (hexColors[this.state.colorsMap[k]] === '#000000') {
                            hexColors[this.state.colorsMap[k]] = '#FFFFFF'
                        }
                    }        
                    tempCircleColors[robotNamesTemp[i]] = hexColors;
                }
                // Update state for robots, colors, modal top message
                this.setState( {circleColors: tempCircleColors, robotNames: robotNamesTemp, stateMessage: STATE_TEXT_OPTS[2]} )
            }
            // Update modal layout with state
            this.setState( {connectionState: 1} )
        }
        // Update modal as viewable and store LocoXtreme class instance object and functionality_mode of modal
        this.setState( {isOpen: true, LXObj: tempLXObj, functionality_mode: functionality_mode} );
    }

    // Handle the closing of the modal (visibility)
    handleClose = async (robotSelected = {}) => {
        
        // Make robotSelect = {} if odd input for robotSelected
        if (robotSelected.length < 3) {
            robotSelected = {}
        }
        // Disable linked items
        this.setState( {disable_for_scan: true} );
        // For certain modal behavior modes, call props function with robotSelected
        if ((this.state.functionality_mode === this.MODE_OPTIONS[0]) || 
            (this.state.functionality_mode === this.MODE_OPTIONS[3]) || 
            (this.state.functionality_mode === this.MODE_OPTIONS[4]) ||
            (this.state.functionality_mode === this.MODE_OPTIONS[5])) {
            
            if (typeof robotSelected === "string" || robotSelected instanceof String || 'macType' in robotSelected) {
                this.props.onClose(robotSelected);
            } else {
                if (this.state.functionality_mode === this.MODE_OPTIONS[3]) {
                    this.props.onClose("");
                }
            }

        } 
        // For certain modal behavior modes, clean up connection and timeouts, then call props function with last chosen robot
        else if ((this.state.functionality_mode === this.MODE_OPTIONS[2]) || (this.state.functionality_mode === this.MODE_OPTIONS[1])) {
            try {        
                // Reset BLE?
                await this.state.LXObj.reset_ble();
                //await this.state.LXObj.disconnect_usb();
                this.state.LXObj.clearLXTimeouts();
                if (this.state.connectionState === 2) {
                    this.msgElement.current.handleOpen(DISCONNECT_ROBOT['COLOR'], DISCONNECT_ROBOT['MSG'], DISCONNECT_ROBOT['TIME'])
                }
            } catch {}
        }
        if (this.state.functionality_mode !== this.MODE_OPTIONS[3]) {       
            await this.state.LXObj.disconnect_usb();
        }
        // Reset state variables
        this.setState( {isOpen: false, robotNames: [], circleColors: {}, scanning: false, disable_for_scan: false, stateMessage: STATE_TEXT_OPTS[0]} );
    }

    // webserial connection process
    handleConnect = async () => {
        if (Object.keys(window.port).length === 0) {
            try {
                await this.state.LXObj.connect_usb()
            } catch {
                return
            }            
        }
        if (window.port) {
            this.setState( {disable_for_scan: true} )
            this.handleScan()
        }
    }

    // Handle scanning for robots button
    handleScan = () => {
        // Update state
        this.setState( {scanning: true, stateMessage: STATE_TEXT_OPTS[1]} );

        // Start scanning and initialize timer for stop scanning
        this.state.LXObj.scan_start(this.state.scan_time);
        setTimeout(this.stopScan, this.state.scan_time)
    }

    // Stop scanning
    stopScan = () => {

        // Get robots found
        let robotsFound = [...this.state.LXObj.robots]
        let robotNamesTemp = []
        for (let i = 0; i < robotsFound.length; i++) {
            let newName = robotsFound[i]['name']
            if (robotNamesTemp.includes(newName) === false) {
                robotNamesTemp.push(newName)
            }
        }
        // Generate colors for render of robots found
        let tempCircleColors = this.state.circleColors;
        for (let i = 0; i < robotNamesTemp.length; i++) {
            let circleColors = nameToColors(robotNamesTemp[i]);
            let hexColors = ['#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'];
            for (let k = 0; k < circleColors.length; k++) {
                hexColors[this.state.colorsMap[k]] = rgbToHex(circleColors[k][0], circleColors[k][1], circleColors[k][2])
                if (hexColors[this.state.colorsMap[k]] === '#000000') {
                    hexColors[this.state.colorsMap[k]] = '#FFFFFF'
                }
            }
            tempCircleColors[robotNamesTemp[i]] = hexColors;
        }

        // Handle if any robots found or not state update
        if (robotNamesTemp.length !== 0) {
            this.setState( {connectionState: 1, stateMessage: STATE_TEXT_OPTS[2]} )
        } else {
            this.setState( {stateMessage: STATE_TEXT_ERRS[0]} )
        }
        // Update state for scan over, any robots found
        this.setState( {disable_for_scan: false, robotNames: robotNamesTemp} )
        this.setState( {scanning: false} );
    }

    // Check for Timeout for Robot Settings Data
    verifyData = async (data) => {
        // If there was a timeout, clean up
        if (data === this.state.LXObj.TIMEOUT_STR) {            
            try {        
                // Reset BLE?
                await this.state.LXObj.reset_ble();
                await this.state.LXObj.disconnect_usb();
                this.state.LXObj.clearLXTimeouts();
            } catch {}  
            this.setState( {robot_name_input: ""} )
        }
        // Resolve as pass or fail
        let me = this
        return new Promise(function (resolve, reject) { 
            if (data === me.state.LXObj.TIMEOUT_STR) {
                resolve("fail")
            } else {
                resolve("pass")
            }
        });
    }

    // Robot button selected
    handleBtn = (event, name) => {

        // If scan isn't active
        if (this.state.disable_for_scan === false) {

            // If certain modal behavior mode, pass out robot name to handleClose()
            if ((this.state.functionality_mode === this.MODE_OPTIONS[0]) || 
                (this.state.functionality_mode === this.MODE_OPTIONS[3]) ||
                (this.state.functionality_mode === this.MODE_OPTIONS[4])) {
                this.setState( { robotSelected: name } )
                this.handleClose(name);

            // If certain modal behavior mode, pass out robot object to handleClose() if possible
            } else if (this.state.functionality_mode === this.MODE_OPTIONS[5]) {
                // Find LX robot name in list, send that index
                let found = -1;
                for (let i = 0; i < Object.keys(this.state.LXObj.robots).length; i++) {
    
                    if (this.state.LXObj.robots[i].name === name) {
                        found = i;
                        break;
                    }
                }
                // If Not Found, Return Empty Dictionary, If Found, Return Information
                if (found === -1)  {
                    this.handleClose();
                } else  {
                    this.handleClose(this.state.LXObj.robots[found]);
                }
            // If certain modal behavior mode, copy to clipboard and display drawer message
            } else if (this.state.functionality_mode === this.MODE_OPTIONS[1]) {
                let quoteName = "\"" + name + "\""
                // Copy to Clipboard
                navigator.clipboard.writeText(quoteName)
        
                // Drawer String
                let msgStr = "Copied robot named " + quoteName + " to the clipboard"
        
                // Open Temporary Drawer (pass name)
                this.msgElement.current.handleOpen("green", msgStr, 1000)
                this.handleClose();

            // If modal behavior mode for Robot Settings, get selected robot's name, connect to robot, get settings data
            } else if (this.state.functionality_mode === this.MODE_OPTIONS[2]) {
                let targetIndex = -1;
                for (let i = 0; i < this.state.LXObj.robots.length; i++) {
                    if (this.state.LXObj.robots[i]['name'] === name) {
                        targetIndex = i
                        break
                    }
                }
                // Update state
                this.setState( {disable_for_scan: true, stateMessage: STATE_TEXT_OPTS[3]} )
                this.setState( {robot_name_input: ""} );
                // Connect to LX
                this.state.LXObj.connect_ble(targetIndex).then(async () => {

                    /* 
                    let lval = await this.state.LXObj.get_motor("left");
                    let data_check = await this.verifyData(lval)
                    if (data_check === "fail") {this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); return}
                    
                    let rval = await this.state.LXObj.get_motor("right");
                    data_check = await this.verifyData(rval)
                    if (data_check === "fail") {this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); return}
                    */
                    // Ultrasonic filter data
                    let uss = await this.state.LXObj.get_ultrasonic_filter();
                    let data_check = await this.verifyData(uss)
                    if (data_check === "fail") {this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); 
                        this.msgElement.current.handleOpen(DATA_ERROR['COLOR'], DATA_ERROR['MSG'], DATA_ERROR['TIME'])
                        return
                    }
                    //this.setState( {leftMotor: MOTORS_MAP[lval], rightMotor: MOTORS_MAP[rval], robot_filter_input: uss.toString()} )
                    
                    let vel_targets = await this.state.LXObj.motor_controllers(
                        Preference.VelTargets, 
                        PreferenceSettingC.PreferenceGet
                    )
                    
                    if (vel_targets === "timeout") {
                        this.setState( {oldFirmwareVel: true} )
                    }
                    //data_check = await this.verifyData(vel_targets)
                    // Check for timeout
                    //if (data_check === "fail") {
                    //    this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); 
                    //    this.msgElement.current.handleOpen(DATA_ERROR['COLOR'], DATA_ERROR['MSG'], DATA_ERROR['TIME'])
                    //    return
                    //}

                    // Check Values
                    let r_ticks = await this.state.LXObj.motor_controllers(
                        Preference.RTicks, 
                        PreferenceSettingC.PreferenceGet
                    )

                    data_check = await this.verifyData(r_ticks)
                    if (data_check === "fail") {this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); 
                        this.msgElement.current.handleOpen(DATA_ERROR['COLOR'], DATA_ERROR['MSG'], DATA_ERROR['TIME'])
                        return
                    }


                    this.msgElement.current.handleOpen(CONNECT_ROBOT['COLOR'], CONNECT_ROBOT['MSG'], CONNECT_ROBOT['TIME'])
                    
                    // Update state
                    if (vel_targets !== "timeout") {
                        this.setState( {
                            robot_vel_target_input_l: vel_targets.v_target_l.toString(),
                            robot_vel_target_input_r: vel_targets.v_target_r.toString(),
                            robot_filter_input: uss.toString(),
                            robot_r_ticks: r_ticks.toString(),
                            connectionState: 2, stateMessage: STATE_TEXT_OPTS[4], cancelMsg: "Close", 
                            lastSelected: this.state.LXObj.robots[targetIndex],
                            disable_for_scan: false
                        } );
                    } else {
                        this.setState( {
                            robot_filter_input: uss.toString(),
                            robot_r_ticks: r_ticks.toString(),
                            connectionState: 2, stateMessage: STATE_TEXT_OPTS[4], cancelMsg: "Close", 
                            lastSelected: this.state.LXObj.robots[targetIndex],
                            disable_for_scan: false
                        } );
                    }
                    this.props.onClose(this.state.LXObj.robots[targetIndex]);
                }).catch((err) => {
                    // Could not connect
                    this.msgElement.current.handleOpen(CONNECT_FAIL['COLOR'], CONNECT_FAIL['MSG'], CONNECT_FAIL['TIME'])
                    this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[0]} );
                    this.setState( {disable_for_scan: false} )
                });
            }                
        }
    }

    // Text Input Field handler for robot name
    handleNameUpdate = event => {
        let m = event.target.value;
        if (m.length > 8) {
            m = m.slice(0, 8);
        }
        let re = /^[a-zA-Z0-9]*$/;
        if (re.test(m)) {
            m = m.replace(/\s+/gi, '')
            this.setState({[event.target.name]: m});
        }
    };

    // Text Input Field handler for Ultrasonic filter equation coefficent
    handleFilterUpdate = event => {
        let m = event.target.value;
        let re = /^[0-9-\s]*$/;
        if (re.test(m)) {            
            let n = Number(m)
            if (n > 99) {
                n = 99
            } else if (n < 0) {
                n =0
            }
            m = n.toString()
            this.setState({[event.target.name]: m});
        }
    }

    handleVelTargetUpdate = event => {
        let m = event.target.value;
        let re = /^[0-9-\s]*$/;
        if (re.test(m)) {
            this.setState({[event.target.name]: m});
        }
    }

    handleRTicksUpdate = event => {
        let m = event.target.value;
        let re = /^[0-9-\s]*$/;
        if (re.test(m)) {
            this.setState({[event.target.name]: m});
        }
    }


    handleSetDist = (event) => {
        let m = event.target.value;
        let re = /^[0-9-\s]*$/;
        if (re.test(m)) {
            this.setState({[event.target.name]: m});
        }

    }

    handleInOnBlurDist = (event, name) => {
        if (!this.state.hasOwnProperty(name)) {
            return
        }

        let m = this.state[name]
        let re = /^[0-9-\s]*$/;

        if (re.test(m)) {            
            let n = Number(m)
            if (n < 0) {
                n =0
            }
            m = n.toString()
            this.setState({[name]: m});
        }

    }

    
    handleInOnBlur = async (event, name) => {
        if (!this.state.hasOwnProperty(name)) {
            return
        }

        let m = this.state[name]
        let re = /^[0-9-\s]*$/;

        if (re.test(m)) {            
            let n = Number(m)
            if (n > 400) {
                n = 400
            } else if (n < 300) {
                n =300
            }
            m = n.toString()
            this.setState({[name]: m});
        }
    }

    handleInOnBlurRTicks = async (event, name) => {

        if (!this.state.hasOwnProperty(name)) {
            return
        }

        let m = this.state[name]
        let re = /^[0-9-\s]*$/;

        if (re.test(m)) {            
            let n = Number(m)
            if (n > 1000) {
                n = 1000
            } else if (n < 700) {
                n =700
            }
            m = n.toString()
            this.setState({[name]: m});
        }
    }

    // Button Handle for Update LX Robot's Name
    handleSetName = async () => {

        // Update state and set robot name
        this.setState( {disable_for_scan: true} );
        await this.state.LXObj.set_name(this.state.robot_name_input);
        this.props.onClose({});
        this.setState( {robot_name_input: "", lastSelected: this.state.robot_name_input} );
        
        // Try cleaning up connection
        try {        
            // Reset BLE?
            await this.state.LXObj.reset_ble();
            await this.state.LXObj.disconnect_usb();
            this.state.LXObj.clearLXTimeouts();
        } catch {}
        this.msgElement.current.handleOpen(NAME_ROBOT['COLOR'], NAME_ROBOT['MSG'], NAME_ROBOT['TIME'])
        setTimeout( this.msgElement.current.handleOpen.bind(this, DISCONNECT_ROBOT['COLOR'], DISCONNECT_ROBOT['MSG'], DISCONNECT_ROBOT['TIME']), NAME_ROBOT['TIME'] )
        // Reset state variables
        this.setState( {connectionState: 0, robotNames: [], circleColors: {}, scanning: false, disable_for_scan: false, stateMessage: STATE_TEXT_OPTS[0]} );
        this.setState( {LXObj: new LocoXtreme()} );
    }

    // Handle Cancel Button Press
    handleCancel = () => {
        // For certain modal modes
        if ((this.state.functionality_mode === this.MODE_OPTIONS[0]) || (this.state.functionality_mode === this.MODE_OPTIONS[2])) {
            // Reset BLE
            if (this.state.connectionState === 2) {
                this.setState( {disable_for_scan: true} )
                this.state.LXObj.reset_ble().then(() => {
                    this.msgElement.current.handleOpen(DISCONNECT_ROBOT['COLOR'], DISCONNECT_ROBOT['MSG'], DISCONNECT_ROBOT['TIME'])
                    // Update modal render state
                    //this.setState( {connectionState: 0, stateMessage: STATE_TEXT_OPTS[0]} );
                    this.handleClose();
                });
            // No Reset BLE
            } else { 
                // Handle close/state update based on connectionState of state
                if (this.state.connectionState > 0) {
                    if ((this.state.functionality_mode === this.MODE_OPTIONS[3]) || 
                        (this.state.functionality_mode === this.MODE_OPTIONS[4]) || 
                        (this.state.functionality_mode === this.MODE_OPTIONS[5])) {
                        this.handleClose();
                    } else {
                        this.setState( {connectionState: 0, stateMessage: STATE_TEXT_OPTS[0]} );
                    }
                } else {
                    this.handleClose();
                }
            }
        // For rest of modal modes
        } else {
            // Handle close based on connectionState of state and modal functionality_mode
            if (this.state.connectionState > 0) {
                if ((this.state.functionality_mode === this.MODE_OPTIONS[3]) || (this.state.functionality_mode === this.MODE_OPTIONS[4])) {
                    this.handleClose();
                } else {
                    this.setState( {connectionState: 0, stateMessage: STATE_TEXT_OPTS[0]} );
                }
            } else {
                this.handleClose();
            }
        }
    }

    // Handle Update Ultrasonic filter value button press
    handleSetUltrasonic = async () => {

        this.setState( {disable_for_scan: true} );
        // Set Filter coefficient on robot
        await this.state.LXObj.set_ultrasonic_filter(this.state.robot_filter_input);
        // Read back filter coefficient from robot
        let uss = await this.state.LXObj.get_ultrasonic_filter();
        let data_check = await this.verifyData(uss)
        // Check for timeout
        if (data_check === "fail") {
            this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); 
            this.msgElement.current.handleOpen(DATA_ERROR['COLOR'], DATA_ERROR['MSG'], DATA_ERROR['TIME'])
            return
        }

        this.msgElement.current.handleOpen(ULTRASONIC_SET['COLOR'], ULTRASONIC_SET['MSG'], ULTRASONIC_SET['TIME'])

        // Update state
        this.setState( {robot_filter_input: uss.toString(), disable_for_scan: false} );
    }

    handleSetVelTargets = async () => {

        this.setState( {disable_for_scan: true} );

        // Set Values
        const data = [
            this.state.robot_vel_target_input_l,
            this.state.robot_vel_target_input_r
        ]

        await this.state.LXObj.motor_controllers(
            Preference.VelTargets,
            PreferenceSettingC.PreferenceSet,
            data    
        )

        // Check Values
        let vel_targets = await this.state.LXObj.motor_controllers(
            Preference.VelTargets, 
            PreferenceSettingC.PreferenceGet
        )
        let data_check = await this.verifyData(vel_targets)
        // Check for timeout
        if (data_check === "fail") {
            this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); 
            this.msgElement.current.handleOpen(DATA_ERROR['COLOR'], DATA_ERROR['MSG'], DATA_ERROR['TIME'])
            return
        }
        
        this.msgElement.current.handleOpen(VEL_TARGETS_SET['COLOR'], VEL_TARGETS_SET['MSG'], VEL_TARGETS_SET['TIME'])

        this.setState( {
            robot_vel_target_input_l: vel_targets.v_target_l.toString(),
            robot_vel_target_input_r: vel_targets.v_target_r.toString(), 
            disable_for_scan: false
        } );
    }

    handleSetRTicks = async () => {

        this.setState( {disable_for_scan: true} );
        
        await this.state.LXObj.motor_controllers(
            Preference.RTicks, 
            PreferenceSettingC.PreferenceSet,
            this.state.robot_r_ticks,
        )
        
        // Check Values
        let r_ticks = await this.state.LXObj.motor_controllers(
            Preference.RTicks, 
            PreferenceSettingC.PreferenceGet
        )
        
        const data_check = await this.verifyData(r_ticks)
        if (data_check === "fail") {this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); 
            this.msgElement.current.handleOpen(DATA_ERROR['COLOR'], DATA_ERROR['MSG'], DATA_ERROR['TIME'])
            return
        }

        this.msgElement.current.handleOpen(R_TICKS_SET['COLOR'], R_TICKS_SET['MSG'], R_TICKS_SET['TIME'])

        this.setState( {
            robot_r_ticks: r_ticks.toString(), 
            disable_for_scan: false
        } );
    }

    handleTestDrive = async () => {

        this.setState( {disable_for_scan: true} );

        await this.state.LXObj.activate_motors()

        await this.state.LXObj.setup_wait(WaitTypeC.Distance, this.state.driveTestDist)
        const move_resp = await this.state.LXObj.move(
            MotorDirectionC.Forward, MotorDirectionC.Forward, 
            1, 1, true);
        const data_check = await this.verifyData(move_resp)
        if (data_check === "fail") {this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); 
            this.msgElement.current.handleOpen(DATA_ERROR['COLOR'], DATA_ERROR['MSG'], DATA_ERROR['TIME'])
            return
        }

        await this.state.LXObj.deactivate_motors()

        this.setState( {disable_for_scan: false} );
    }

    handleTestRotate = async () => {

        this.setState( {disable_for_scan: true} );

        await this.state.LXObj.activate_motors()
     
        await this.state.LXObj.setup_wait(WaitTypeC.Rotation, 360)
        const move_resp = await this.state.LXObj.move(
            MotorDirectionC.Backward, MotorDirectionC.Forward, 
            1, 1, true);
        const data_check = await this.verifyData(move_resp)
        if (data_check === "fail") {this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); 
            this.msgElement.current.handleOpen(DATA_ERROR['COLOR'], DATA_ERROR['MSG'], DATA_ERROR['TIME'])
            return
        }

        await this.state.LXObj.deactivate_motors()

        this.setState( {disable_for_scan: false} );
    }


    // Unused
    handleFlipLeft = async () => {        
        this.setState( {disable_for_scan: true} );
        await this.state.LXObj.set_motor("left");
        let lval = await this.state.LXObj.get_motor("left");
        let data_check = await this.verifyData(lval)
        if (data_check === "fail") {this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); return}
        this.setState( {leftMotor: MOTORS_MAP[lval], disable_for_scan: false} );
    }
    // Unused
    handleFlipRight = async () => {        
        this.setState( {disable_for_scan: true} );
        await this.state.LXObj.set_motor("right");
        let rval = await this.state.LXObj.get_motor("right");
        let data_check = await this.verifyData(rval)
        if (data_check === "fail") {this.setState( {connectionState: 0, stateMessage: STATE_TEXT_ERRS[2], disable_for_scan: false} ); return}
        this.setState( {rightMotor: MOTORS_MAP[rval], disable_for_scan: false} );
    }

    handleFilterPlot = async () => {
        if (this.state.plotUltrasonicStr === ULTRA_PLOT_BUTTON_STRS[0]) {
            this.setState( {plotUltrasonicStr: ULTRA_PLOT_BUTTON_STRS[1], isPlotting: true, maxHeightPaper: LG_HEIGHT} )

            // Enable Sensor Data
            await this.state.LXObj.enable_sensor(this.state.LXObj.self[this.state.LXObj.options['Ultrasonic']['refer']].value, 1)
            await this.state.LXObj.enable_sensor(this.state.LXObj.self[this.state.LXObj.options['UltrasonicFiltered']['refer']].value, 1)
            this.createPlot()

        } else {

            // Disable Sensor Data            
            await this.state.LXObj.enable_sensor(this.state.LXObj.self[this.state.LXObj.options['Ultrasonic']['refer']].value, 0)
            await this.state.LXObj.enable_sensor(this.state.LXObj.self[this.state.LXObj.options['UltrasonicFiltered']['refer']].value, 0)

            clearTimeout(this.state.plottingTimerID)
            this.setState( {plotUltrasonicStr: ULTRA_PLOT_BUTTON_STRS[0], isPlotting: false, plottingTimerID: null, maxHeightPaper: DEFAULT_HEIGHT} )
            
        }
    }

    createPlot = () => {

        this.state.LXObj.bufferSize = 30

        let traceFilt = {
            y: [0],
            type: 'scatter',
            line: {color: 'black',},
            mode: 'lines',
            name: 'Filtered',
            showlegend: true,
        }

        let traceRaw = {
            y: [0],
            type: 'scatter',
            line: {color: 'orange',},
            mode: 'lines',
            name: 'Raw',
            visibile: this.state.showRaw,
            showlegend: this.state.showRaw,
        }
        
        let marginObj = {
            t: 50, b: 50 
        };
        let heightVal = 200;
        let widthVal = 450;

        let layout = {
            //xaxis: xLabelObj, 
            height: heightVal,
            width: widthVal,
            margin: marginObj,
            mode: 'lines',
            //font: fontObj,
            yaxis: { title: this.state.LXObj.self[this.state.LXObj.options['Ultrasonic'].refer].ylabels[0], } 
        };

        let data = [traceFilt, traceRaw];
        //let data = [traceFilt]
        window.Plotly.newPlot(this.charRef0.current, data, layout)
        
        let timerId = setTimeout(this.updatePlot, PLOT_RATE_MS)
        this.setState({ plottingTimerID: timerId })
    }

    handleRawPlot = () => {
        if (this.state.showRawUltrasonicStr === ULTRA_PLOT_RAW_STRS[0]) {
            this.setState( {showRawUltrasonicStr: ULTRA_PLOT_RAW_STRS[1], showRaw: true} )
        } else {
            this.setState( {showRawUltrasonicStr: ULTRA_PLOT_RAW_STRS[0], showRaw: false} )
        }
    }

    updatePlot = () => {
        
        try {
            let dataArrayFilt = this.state.LXObj.get_data_array(this.state.LXObj.self[this.state.LXObj.options['UltrasonicFiltered'].refer].value);
            let dataArrayRaw = this.state.LXObj.get_data_array(this.state.LXObj.self[this.state.LXObj.options['Ultrasonic'].refer].value);

            let dataFilty = {
                y: [dataArrayFilt[0]],
                showlegend: true,
            }
            let dataRawy = {
                y: [dataArrayRaw[0]],
                visible: this.state.showRaw,
                showlegend: this.state.showRaw,
            }


            let layout = {
                yaxis: {
                    title: this.state.LXObj.self[this.state.LXObj.options['Ultrasonic'].refer].ylabels[0], 
                    range: [0, Math.max(dataArrayFilt[0], dataArrayRaw[0]) + 3]
                },
            }

            window.Plotly.update(this.charRef0.current, dataFilty, layout, [0]);
            window.Plotly.update(this.charRef0.current, dataRawy, layout, [1]);
        } catch {
        }


        if (this.state.isPlotting) {
            let timerId = setTimeout(this.updatePlot, PLOT_RATE_MS)
            this.setState({ plottingTimerID: timerId})
        }
    }

    handleShowTuning = () => {
        this.setState({showTuning: true})
    }

    handleHideTuning = () => {
        this.setState({showTuning: false})    
    }

    // Render HTML
    render() {
        
        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display
        return (
            <div className={classes.root}>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper} style = {{maxHeight: this.state.maxHeightPaper}}>
                        <Grid
                            spacing = {1}
                            direction="row"
                            className={classes.grid}
                            container
                        >
                        <Grid item xs={9}>
                            <Typography>
                                {this.state.stateMessage}
                            </Typography> 
                        </Grid>
                        <Grid item xs={3} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.cancelButton}
                                onClick={this.handleCancel}
                                disabled={this.state.disable_for_scan}
                            >
                                {this.state.cancelMsg}
                            </Button>  
                        </Grid>
                        {(this.state.connectionState === 0) && (
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.actionButton}
                                onClick={this.handleConnect}
                                disabled={this.state.disable_for_scan}
                            >
                                Scan for Robots
                            </Button>
                            
                        )}
                        {(this.state.connectionState === 1) && (
                            
                            <Grid item xs={12} style = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Paper
                                style = {{overflowY: 'auto', overflowX: 'hidden', maxHeight: '275px'}}
                            >
                            {this.state.robotNames.map((r) => 
                                <Card 
                                    style = {{border: '1px solid black', marginTop: '5px', width: '325px'}}
                                    key = {r}
                                >
                                <Grid 
                                    container 
                                    spacing={1}
                                    className={classes.grid}
                                    direction="row"
                                    align = "center" justifyContent = "center"
                                    key = {'rg' + r}
                                    style = {{backgroundColor: '#34495e'}}
                                >
                                <Grid item xs={12}
                                    onClick={(e) => this.handleBtn(e, r)}
                                    key = {'r' + r}
                                >
                                    <Typography
                                        className={classes.titleText}
                                        key = {'n' + r}
                                    > 
                                        {r}
                                    </Typography>
                                    <Grid 
                                        container 
                                        spacing={0}
                                        align = "center" justifyContent = "center"
                                        direction="row"
                                    >
                                    {this.state.circleGrids.map((val) => {
                                        return (
                                            <Grid 
                                                item xs={this.state.circleGridWidths[val]} 
                                                key = {'gc' + r + val}
                                            >
                                                <ListItem
                                                    className = {classes.lightIndices}
                                                    key = {'c' + r + val.toString()}
                                                    style = {{
                                                        border: '2px solid #000',
                                                        marginLeft: this.state.colorMarginsL[val],
                                                        marginRight: this.state.colorMarginsR[val],
                                                        position: "relative",
                                                        top: this.state.colorsPosShift[val],
                                                        backgroundColor: this.state.circleColors[r][val],
                                                    }}
                                                >
                                                </ListItem>
                                            </Grid>
                                        )
                                    })}
                                    </Grid>
                                </Grid>
                                </Grid>  
                                </Card>
                            )}
                            </Paper>
                            </Grid>
                        )}
                        {(this.state.connectionState === 2) && (  
                            <React.Fragment>
                                
                            <Grid item xs={7}>
                                <FormControl>
                                    <Input 
                                        id="standard-adornment-pn"
                                        aria-describedby="standard-pn-helper-text"
                                        name="robot_name_input"
                                        value={this.state.robot_name_input}
                                        inputProps={{
                                            'aria-label': 'pn',
                                        }}
                                        onChange = {this.handleNameUpdate}
                                        disabled={this.state.disable_for_scan || this.state.isPlotting}
                                        autoComplete="off"
                                    />
                                    <FormHelperText className={classes.inputHelper} id="standard-pn-helper-text">New Robot Name</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={5} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={this.handleSetName}
                                    disabled={this.state.disable_for_scan || this.state.isPlotting}
                                >
                                    Set Name
                                </Button>   
                            </Grid>
                            <Grid item xs={4} style = {{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                <Typography
                                
                                >
                                    Ultrasonic Filter: 
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style = {{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                <FormControl fullWidth className={classes.form}>
                                    <Input className={classes.inputField}
                                        id="standard-adornment-pn"
                                        aria-describedby="standard-pn-helper-text"
                                        name="robot_filter_input"
                                        value={this.state.robot_filter_input}
                                        inputProps={{
                                            'aria-label': 'pn',
                                        }}
                                        onChange = {this.handleFilterUpdate}
                                        disabled={this.state.disable_for_scan || this.state.isPlotting}
                                        autoComplete="off"
                                        style = {{maxWidth: "40px"}}
                                    />
                                    <FormHelperText className={classes.inputHelper} id="standard-pn-helper-text">
                                        (0-99)
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleSetUltrasonic}
                                    disabled={this.state.disable_for_scan || this.state.isPlotting}
                                >
                                    Set
                                </Button>  
                            </Grid>     
                            <Grid item xs={6} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick = {this.handleFilterPlot}
                                    disabled={this.state.disable_for_scan}
                                >
                                    {this.state.plotUltrasonicStr}
                                </Button>
                            </Grid>
                            <Grid item xs={6} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick = {this.handleRawPlot}
                                    disabled={this.state.disable_for_scan}
                                >
                                    {this.state.showRawUltrasonicStr}
                                </Button>
                            </Grid>
                            
                            {((this.context.AuthInstance.account_type !== 'student') &&
                            (this.state.showTuning === false)) && (
                                <React.Fragment>
                                <Grid item xs={12} style = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick = {this.handleShowTuning}
                                        disabled={this.state.disable_for_scan || this.state.isPlotting}
                                    >
                                        Show Robot Tuning
                                    </Button>
                                </Grid>
                                </React.Fragment>
                            )}
                            {((this.context.AuthInstance.account_type !== 'student') &&
                            (this.state.showTuning === true)) && (
                                <React.Fragment>
                                <Divider style={{width:'100%'}} />
                                <Grid item xs={12} style = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick = {this.handleHideTuning}
                                        disabled={this.state.disable_for_scan || this.state.isPlotting}
                                    >
                                        Hide Robot Tuning
                                    </Button>
                                </Grid>
                                <Divider style={{width:'100%'}} />
                                <Grid item xs={12} style = {{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                    <Typography
                                    
                                    >
                                        See the Course References section of your curriculum to access the Robot Tuning Guide. 
                                    </Typography>
                                </Grid>
                                {(this.state.oldFirmwareVel === false) && (
                                    <React.Fragment>
                                    <Grid item xs={3} style = {{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                        <Typography>
                                            Left Vel. Target: 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} style = {{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                        <FormControl fullWidth className={classes.form}>
                                            <Input className={classes.inputField}
                                                id="standard-adornment-pn"
                                                aria-describedby="standard-pn-helper-text"
                                                name="robot_vel_target_input_l"
                                                value={this.state.robot_vel_target_input_l}
                                                inputProps={{
                                                    'aria-label': 'pn',
                                                }}
                                                onChange = {this.handleVelTargetUpdate}
                                                onBlur = {(e) => this.handleInOnBlur(e, 'robot_vel_target_input_l')}
                                                disabled={this.state.disable_for_scan || this.state.isPlotting}
                                                autoComplete="off"
                                                style = {{maxWidth: "40px"}}
                                            />
                                            <FormHelperText className={classes.inputHelper} id="standard-pn-helper-text">
                                                (default: 364)
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid> 
                                    <Grid item xs={3} style = {{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                        <Typography>
                                            Right Vel. Target: 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} style = {{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                        <FormControl fullWidth className={classes.form}>
                                            <Input className={classes.inputField}
                                                id="standard-adornment-pn"
                                                aria-describedby="standard-pn-helper-text"
                                                name="robot_vel_target_input_r"
                                                value={this.state.robot_vel_target_input_r}
                                                inputProps={{
                                                    'aria-label': 'pn',
                                                }}
                                                onChange = {this.handleVelTargetUpdate}
                                                onBlur = {(e) => this.handleInOnBlur(e, 'robot_vel_target_input_r')}
                                                disabled={this.state.disable_for_scan || this.state.isPlotting}
                                                autoComplete="off"
                                                style = {{maxWidth: "40px"}}
                                            />
                                            <FormHelperText className={classes.inputHelper} id="standard-pn-helper-text">
                                                (default: 364)
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid> 
                                    <Divider style={{width:'100%'}} />
                                    <Grid item xs={6} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className={classes.actionButton}
                                            onClick = {this.handleSetVelTargets}
                                            disabled={this.state.disable_for_scan || this.state.isPlotting}
                                        >
                                            Set Velocity Targets
                                        </Button>
                                    </Grid>
                                <Grid item xs={3} style = {{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                    <FormControl fullWidth className={classes.form}>
                                        <Input className={classes.inputField}
                                            id="standard-adornment-pn"
                                            aria-describedby="standard-pn-helper-text"
                                            name="driveTestDist"
                                            value={this.state.driveTestDist}
                                            inputProps={{
                                                'aria-label': 'pn',
                                            }}
                                            onChange = {this.handleSetDist}
                                            onBlur = {(e) => this.handleInOnBlurDist(e, 'driveTestDist')}
                                            disabled={this.state.disable_for_scan || this.state.isPlotting}
                                            autoComplete="off"
                                            style = {{maxWidth: "40px"}}
                                        />
                                        <FormHelperText className={classes.inputHelper} id="standard-pn-helper-text">
                                            Distance (cm) 
                                        </FormHelperText>
                                    </FormControl>
                                </Grid> 
                                <Grid item xs={3} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick = {this.handleTestDrive}
                                        disabled={this.state.disable_for_scan || this.state.isPlotting}
                                    >
                                        Test Drive
                                    </Button>
                                </Grid>
                                </React.Fragment>
                                )}
                                <Divider style={{width:'100%'}} />
                                <Grid item xs={6} style = {{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                    <Typography>
                                        Rotation Conversion: 
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style = {{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                    <FormControl fullWidth className={classes.form}>
                                        <Input className={classes.inputField}
                                            id="standard-adornment-pn"
                                            aria-describedby="standard-pn-helper-text"
                                            name="robot_r_ticks"
                                            value={this.state.robot_r_ticks}
                                            inputProps={{
                                                'aria-label': 'pn',
                                            }}
                                            onChange = {this.handleRTicksUpdate}
                                            onBlur = {(e) => this.handleInOnBlurRTicks(e, 'robot_r_ticks')}
                                            disabled={this.state.disable_for_scan || this.state.isPlotting}
                                            autoComplete="off"
                                            style = {{maxWidth: "40px"}}
                                        />
                                        <FormHelperText className={classes.inputHelper} id="standard-pn-helper-text">
                                            (default: 820)
                                        </FormHelperText>
                                    </FormControl>
                                </Grid> 
                                <Grid item xs={6} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick = {this.handleSetRTicks}
                                        disabled={this.state.disable_for_scan || this.state.isPlotting}
                                    >
                                        Set Rotation Conversion
                                    </Button>
                                </Grid>
                                <Grid item xs={6} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick = {this.handleTestRotate}
                                        disabled={this.state.disable_for_scan || this.state.isPlotting}
                                    >
                                        Test Rotate
                                    </Button>
                                </Grid>
                                </React.Fragment>
                            )}


                            {this.state.isPlotting === true && (
                            <Grid item xs={12} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <div ref={this.charRef0}></div>
                            </Grid>
                            )}
                            </React.Fragment>                                      
                        )}                        
                        </Grid>
                    </div>
                </Fade>
                </Modal>
                <GeneralMessage
                    ref = {this.msgElement}
                    onClose = {() => void 0}
                />
            </div>
        );
    }
    


}


export default withStyles(styles)(LXSettingsModal);