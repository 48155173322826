// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import GeneralMessage from "../drawers/GeneralMessage"

import { LC_DEF_RADDR, LC_DEF_RCHAN, LC_RCHAN_MAX, LC_RCHAN_MIN } from '../../libraries/LocoCraze/LocoCraze';



// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 500,//400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: 'visible',
      maxHeight: '375px',
      borderRadius: '8px', // Add borderRadius for rounded corners
    },
    actionButton: {
      //justify: "center",
      margin: theme.spacing(0),
      color: "white",
    },  
    cancelButton: {
        marginTop: theme.spacing(-0.5),
        color: "white",
    },
    titleText: {
      color: "white",
      margin: theme.spacing(1),
    },
    lightIndices: {
        borderRadius: '70%',
        width: '25px',
        height: '25px',
    }, 
    inputField: {
        width: "90%",
        marginLeft: theme.spacing(2),
    },
    gridButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    
});


// Component Class
class LCInputsModal extends React.Component {

    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);

        this.inputsMsgElement = React.createRef();
        
    }

    // Class state
    state = {
        isOpen: false,
        radioAddress: '',
        radioChannel: '',
        authInstanceObj: null,
    }

    componentDidMount() {
        
    };

    handleOpen = (authInstanceObj) => {
        // 
        if (!(authInstanceObj.loadedState.hasOwnProperty('LocoCraze'))) {
            authInstanceObj.loadedState.LocoCraze = {
                channel: LC_DEF_RCHAN,
                address: LC_DEF_RADDR,
            };
        }

        this.setState({
            isOpen: true, 
            authInstanceObj: authInstanceObj,
            radioAddress: authInstanceObj.loadedState.LocoCraze.address,
            radioChannel: authInstanceObj.loadedState.LocoCraze.channel,
        });
    };

    
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleContinue = () => {
        
        // check if inputs are valid
        // check if radio channel is an integer
        if (this.state.radioChannel === '' || !Number.isInteger(Number(this.state.radioChannel))) {
            //backColor, textMsg, delay
            this.inputsMsgElement.current.handleOpen("red", "Radio Channel must be an integer.", 2000);
            return;
        }
        const radioChannel = parseInt(this.state.radioChannel);
        // check if radio channel is in range
        if ((radioChannel < LC_RCHAN_MIN) || (radioChannel > LC_RCHAN_MAX)) {
            this.inputsMsgElement.current.handleOpen(
                "red", 
                `Radio Channel must be between ${LC_RCHAN_MIN} and ${LC_RCHAN_MAX}.`, 
                2000
            );
            return;
        }

        // check if radio address is a 5-element hex string
        const str = this.state.radioAddress;
        if (str.length !== 10) {
            this.inputsMsgElement.current.handleOpen(
                "red", 
                "Radio Address must be a 5-element hex string.", 
                2000
            );
            return;
        }
        
        // Regular expression to check if the string is a valid 5-element hex string
        const hexRegex = /^[0-9A-Fa-f]{10}$/;

        if (!hexRegex.test(str)) {
            this.inputsMsgElement.current.handleOpen(
                "red", 
                "Radio Address must be a 5-element hex string.", 
                2000
            );
            return;
        }

        const authInstanceTemp = this.state.authInstanceObj;
        
        authInstanceTemp.loadedState.LocoCraze = {
            channel: this.state.radioChannel,
            address: this.state.radioAddress,
        };

        this.props.onClose();
        this.handleClose();

    };

    handleCancel = () => {

        this.handleClose();
    };

    handleClose = () => {
        
        this.setState({isOpen: false});
    };

    
    // render HTML
    render() {
        
        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display
        return (
            <div className={classes.root}>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Radio Address"
                                    name="radioAddress"
                                    value={this.state.radioAddress}
                                    onChange={this.handleInputChange}
                                    fullWidth
                                    className={classes.inputField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Radio Channel"
                                    name="radioChannel"
                                    value={this.state.radioChannel}
                                    onChange={this.handleInputChange}
                                    fullWidth
                                    className={classes.inputField}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.gridButton}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleContinue}
                                    className={classes.button}
                                >
                                    Continue
                                </Button>
                            </Grid>
                            <Grid item xs={6} className={classes.gridButton}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.handleCancel}
                                    className={classes.button}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    </Fade>
                </Modal>
                <GeneralMessage
                    ref = {this.inputsMsgElement}
                    onClose = {() => void 0}
                />
            </div>
        );
    }

}

export default withStyles(styles)(LCInputsModal);
