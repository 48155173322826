// Child Component of: 
// --- BaseBlock

// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    paper: {
      width: 515,//600
      height: 200,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
    },
    grid: {
        justify: 'flex-start',
    },
    titleText: {
        color: "white",
        margin: theme.spacing(1),
    },
    inputField: {
        width: "100%",
        color: "white",
        marginTop: theme.spacing(0.6),
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    inputFieldRC: {
        width: "100%",
        color: "white",
        marginTop: theme.spacing(0.6),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        textAlign: "center",
    },
    regularText: {
        color: "white",
        margin: theme.spacing(1),
    },
    textButton: {
        color: "white",
        margin: theme.spacing(0),
        border: '1px solid #000',
    },
});




// Component Class
class LocoDroneTBlocks extends React.Component {

    state = {
        LDBlocksOptions: {
            itakeoff: 
            {
                id: 0,
                title: 'Takeoff',
                height: 200, 
                color: '#ec4078',
                control: {},
                code: ['# Command Drone to Takeoff', 'loco_drone.drone_takeoff()'],
            },
            iland: 
            {
                id: 0,
                title: 'Land',
                height: 200, 
                color: '#ec4078',
                control: {},
                code: ['# Command Drone to Land', 'loco_drone.drone_land()'],
            },
            iloop:
            {
                id: 0,
                title: 'Loop',
                height: 200, 
                color: '#8057c1',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Loop for ${ctrl.in1} times`, `for i in range(${ctrl.in1}):`]
                },
                code: [],
                inWidth: '40px',
                in1Type: 'int',
                text1: 'Repeat',
                text2: 'times',
            },
            iloopend:
            {        
                id: 0,
                title: 'LoopEnd',
                height: 200, 
                color: '#8057c1',
                control: {},
                code: [],
            },
            ihover:
            {
                id: 0,
                title: 'Hover',
                height: 200, 
                color: '#fbc22d',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Hover for ${ctrl.in1} seconds`, `loco_drone.drone_rc(0, 0, 0, 0)`, `time.sleep(${ctrl.in1})`]
                },
                code: [],
                inWidth: '40px',
                in1Type: 'float',
                text1: 'Hover for',
                text2: 'seconds',
                in1Limits: [0, 10],
            },
            iflip:
            {
                id: 0,
                title: 'Flip',
                height: 200, 
                color: '#FF2424',
                control: {btn1: 0, btn1Txt: 'LEFT', },
                codeRef(ctrl) {
                    return [`# Flip ${ctrl.btn1Txt}`, `loco_drone.drone_flip(loco_drone.FLIP_${ctrl.btn1Txt})`]
                },
                code: [],
                text1: 'Flip Drone ',
                btn1Options: ['LEFT', 'RIGHT', 'FORWARD', 'BACK'],
                btn1map: {'LEFT':'LEFT', 'RIGHT':'RIGHT', 'FORWARD':'FORWARD', 'BACK':'BACK'},
            },
            iyaw: {
                id: 0,
                title: 'Yaw',
                height: 200, 
                color: '#2196f3',
                control: {btn1: 'YAW_CW', btn1Txt: 'Clockwise', in1: 1, },
                codeRef(ctrl) {
                    return [`# Yaw ${ctrl.btn1Txt} for ${ctrl.in1} degrees`, `loco_drone.drone_yaw(loco_drone.${ctrl.btn1}, ${ctrl.in1})`]
                },
                code: [],
                text1: 'Yaw ',
                text2: 'for',
                text3: 'degrees',
                btn1Options: ['Clockwise', 'Counter-clockwise'],
                btn1map: {'Clockwise': 'YAW_CW', 'Counter-clockwise':'YAW_CCW'},
                inWidth: '40px',
                in1Type: 'int',
                in1Limits: [1, 360],
            },
            imove: {
                id: 0,
                title: 'Move',
                height: 200, 
                color: '#009788',
                control: {btn1: '1', btn1Txt: 'UP', in1: 20, },
                codeRef(ctrl) {
                    return [`# Move ${ctrl.btn1Txt} for ${ctrl.in1} centimeters`, `loco_drone.drone_move(loco_drone.MOVE_${ctrl.btn1Txt}, ${ctrl.in1})`]
                },
                code: [],
                text1: 'Move ',
                text2: 'for',
                text3: 'centimeters',
                btn1Options: ['UP', 'DOWN', 'LEFT', 'RIGHT', 'FORWARD', 'BACK'],
                btn1map: {'UP':'UP', 'DOWN':'DOWN', 'LEFT':'LEFT', 'RIGHT':'RIGHT', 'FORWARD':'FORWARD', 'BACK':'BACK'},
                inWidth: '40px',
                in1Type: 'int',
                in1Limits: [20, 500],
            },
            igo: {
                id: 0,
                title: 'Go To',
                height: 200, 
                color: '#4DAF4E',
                control: {in1: 0, in2: 0, in3: 0, in4: 10, },
                codeRef(ctrl) {
                    return [`# Go to (${ctrl.in1}, ${ctrl.in2}, ${ctrl.in3}) at ${ctrl.in4} cm/s`, `loco_drone.drone_go([${ctrl.in1},${ctrl.in2},${ctrl.in3}], ${ctrl.in4})`]
                },
                code: [],
                text1: 'Go to (',
                text2: ')',
                text3: 'at ',
                text4: 'cm/s',
                inWidth: '40px',
                in1Type: 'int',
                //in1Limits: [-500, 500],
                in2Type: 'int',
                //in2Limits: [-500, 500],
                in3Type: 'int',
                //in3Limits: [-500, 500],
                in4Type: 'int',
                in4Limits: [10, 100],
                in1Cust: '',
                in2Cust: '',
                in3Cust: '',
                boundRef(x) {
                    let m = x
                    if (m < -500) {
                        m = -500
                    } else if (m > 500) {
                        m = 500
                    } else if ((m >= 1) && (m <= 9)) {
                        m = 0
                    } else if ((m <= -1) && (m >= -9)) {
                        m = 0
                    } else if ((m <= -10) && (m >= -19)) {
                        m = -20
                    } else if ((m >= 10) && (m <= 19)) {
                        m = 20
                    }
                    return m;
                },
            },
            irc: {                
                id: 0,
                title: 'Virtual Joystick',
                height: 200, 
                color: '#f16749',
                control: {in1: 0, in2: 0, in3: 0, in4: 0, in5: 0, btn1: 1, btn2: 1, btn3: 1, btn4: 1},
                codeRef(ctrl) {
                    return [`# RC Control ${ctrl.in1}, ${ctrl.in2}, ${ctrl.in3}, ${ctrl.in4} for ${ctrl.in5} seconds`, 
                            `loco_drone.drone_rc(${Number(ctrl.btn1) * Number(ctrl.in1)}, ${Number(ctrl.btn2) * Number(ctrl.in2)}, ${Number(ctrl.btn3) * Number(ctrl.in3)}, ${Number(ctrl.btn4) * Number(ctrl.in4)})`,
                            `time.sleep(${ctrl.in5})`,
                            `loco_drone.drone_rc(0, 0, 0, 0)`]
                },
                code: [],
                inWidth: '40px',
                in1Type: 'int',
                in1Limits: [0, 100],
                in2Type: 'int',
                in2Limits: [0, 100],
                in3Type: 'int',
                in3Limits: [0, 100],
                in4Type: 'int',
                in4Limits: [0, 100],
                in5Type: 'float',
                in5Limits: [1, 14],
                text1: 'for',
                text2: 'seconds',
                btn1Options: ['Left', 'Right'],
                btn1map: {'Left':-1, 'Right':1},
                btn2Options: ['Forward', 'Backward'],
                btn2map: {'Forward':1, 'Backward':-1},
                btn3Options: ['Up', 'Down'],
                btn3map: {'Up':1, 'Down':-1},
                btn4Options: ['Yaw CW', 'Yaw CCW'],
                btn4map: {'Yaw CW':1, 'Yaw CCW':-1, 'Yaw Clockwise':1, 'Yaw Couter-Clockwise':-1},
            }
        },
        renderType: this.props.blockType,
        btn1: 0,
        btn2: 0,
        btn3: 0,
        btn4: 0,
        btn1Txt: '',
        btn2Txt: '',
        btn3Txt: '',
        btn4Txt: '',
        in1: 0,
        in2: 0,
        in3: 0,
        in4: 0,
        in5: 0,
    }

    componentDidMount() {
        let updates = {control: this.props.control};
        this.componentRefresh(updates);
    }

    componentRefresh = (updates) => {
        let tempRenderType = this.state.renderType;
        
        if ('name' in updates) {
            tempRenderType = updates.name;
            this.setState( {renderType: updates.name} );
        }

        let tempLDBlocks = this.state.LDBlocksOptions;
        
        tempLDBlocks[tempRenderType].id = this.props.id
        if ((typeof updates.control !== "undefined") && (Object.keys(updates.control).length !== 0)) {
            let keyNames = Object.keys(updates.control);
            
            keyNames.forEach((val, i) => {
                tempLDBlocks[tempRenderType].control[val] = updates.control[val];
                this.setState( {[val]: updates.control[val]} );
                if (tempLDBlocks[tempRenderType].hasOwnProperty('codeRef')) {
                    tempLDBlocks[tempRenderType].code = tempLDBlocks[tempRenderType].codeRef(tempLDBlocks[tempRenderType].control);
                }
            });
        } else {
            if (tempLDBlocks[tempRenderType].hasOwnProperty('codeRef')) {
                tempLDBlocks[tempRenderType].code = tempLDBlocks[tempRenderType].codeRef(tempLDBlocks[tempRenderType].control);
            }

            if ('btn1' in tempLDBlocks[tempRenderType].control) {
                tempLDBlocks[tempRenderType].control.btn1 = tempLDBlocks[tempRenderType].btn1map[tempLDBlocks[tempRenderType].btn1Options[0]];
                tempLDBlocks[tempRenderType].control.btn1Txt = tempLDBlocks[tempRenderType].btn1Options[0];
                this.setState( {btn1Txt: tempLDBlocks[tempRenderType].control.btn1Txt} );
            }
            if ('btn2' in tempLDBlocks[tempRenderType].control) {
                tempLDBlocks[tempRenderType].control.btn2 = tempLDBlocks[tempRenderType].btn2map[tempLDBlocks[tempRenderType].btn2Options[0]];
                tempLDBlocks[tempRenderType].control.btn2Txt = tempLDBlocks[tempRenderType].btn2Options[0];
                this.setState( {btn2Txt: tempLDBlocks[tempRenderType].control.btn2Txt} );
            }
            if ('btn3' in tempLDBlocks[tempRenderType].control) {
                tempLDBlocks[tempRenderType].control.btn3 = tempLDBlocks[tempRenderType].btn3map[tempLDBlocks[tempRenderType].btn3Options[0]];
                tempLDBlocks[tempRenderType].control.btn3Txt = tempLDBlocks[tempRenderType].btn3Options[0];
                this.setState( {btn3Txt: tempLDBlocks[tempRenderType].control.btn3Txt} );
            }
            if ('btn4' in tempLDBlocks[tempRenderType].control) {
                tempLDBlocks[tempRenderType].control.btn4 = tempLDBlocks[tempRenderType].btn4map[tempLDBlocks[tempRenderType].btn4Options[0]];
                tempLDBlocks[tempRenderType].control.btn4Txt = tempLDBlocks[tempRenderType].btn4Options[0];
                this.setState( {btn4Txt: tempLDBlocks[tempRenderType].control.btn4Txt} );
            }
            if ('in1' in tempLDBlocks[tempRenderType].control) {
                if ('in1Limits' in tempLDBlocks[tempRenderType]) {
                    tempLDBlocks[tempRenderType].control.in1 = Math.max(tempLDBlocks[tempRenderType].in1Limits[0], 0);
                } else {
                    tempLDBlocks[tempRenderType].control.in1 = 0;
                }
                this.setState( {in1: tempLDBlocks[tempRenderType].control.in1} );
            }
            if ('in2' in tempLDBlocks[tempRenderType].control) {
                if ('in2Limits' in tempLDBlocks[tempRenderType]) {
                    tempLDBlocks[tempRenderType].control.in2 = Math.max(tempLDBlocks[tempRenderType].in2Limits[0], 0);
                } else {
                    tempLDBlocks[tempRenderType].control.in2 = 0;
                }
                this.setState( {in2: tempLDBlocks[tempRenderType].control.in2} );
            }
            if ('in3' in tempLDBlocks[tempRenderType].control) {
                if ('in3Limits' in tempLDBlocks[tempRenderType]) {
                    tempLDBlocks[tempRenderType].control.in3 = Math.max(tempLDBlocks[tempRenderType].in3Limits[0], 0);
                } else {
                    tempLDBlocks[tempRenderType].control.in3 = 0;
                }
                this.setState( {in3: tempLDBlocks[tempRenderType].control.in3} );
            }
            if ('in4' in tempLDBlocks[tempRenderType].control) {
                if ('in4Limits' in tempLDBlocks[tempRenderType]) {
                    tempLDBlocks[tempRenderType].control.in4 = Math.max(tempLDBlocks[tempRenderType].in4Limits[0], 0);
                } else {
                    tempLDBlocks[tempRenderType].control.in4 = 0;
                }
                this.setState( {in4: tempLDBlocks[tempRenderType].control.in4} );
            }
            if ('in5' in tempLDBlocks[tempRenderType].control) {
                if ('in5Limits' in tempLDBlocks[tempRenderType]) {
                    tempLDBlocks[tempRenderType].control.in5 = Math.max(tempLDBlocks[tempRenderType].in5Limits[0], 0);
                } else {
                    tempLDBlocks[tempRenderType].control.in5 = 0;
                }
                this.setState( {in5: tempLDBlocks[tempRenderType].control.in5} );
            }
        }
        
        this.setState( {LDBlocksOptions: tempLDBlocks} );
        this.props.handleSubState(tempLDBlocks[tempRenderType]);
    }


    handleInOnBlur = (event, name) => {
        if (!this.state.hasOwnProperty(name)) {
            return
        }
        //let m = event.target.value
        //let inName = event.target.name

        let m = this.state[name]
        let inName = name

        let inLimits = inName + 'Limits'
        let inCust = inName + 'Cust'
        let inType = inName + "Type"

        let tempLDBlocks = this.state.LDBlocksOptions;
        if (!isNaN(parseFloat(m)) && isFinite(m)) {
            let n = Number(m);
            
            if (inLimits in tempLDBlocks[this.state.renderType]) {
                if (n < tempLDBlocks[this.state.renderType][inLimits][0]) {
                    if (tempLDBlocks[this.state.renderType][inLimits][0] < 0) {
                        n = 0
                        m = "0"
                    } else {
                        n = tempLDBlocks[this.state.renderType][inLimits][0]
                        m = String(tempLDBlocks[this.state.renderType][inLimits][0])
                    }
                } else if (n > tempLDBlocks[this.state.renderType][inLimits][1]) {
                    n = tempLDBlocks[this.state.renderType][inLimits][1]
                    m = String(tempLDBlocks[this.state.renderType][inLimits][1])
                }
            } else if (inCust in tempLDBlocks[this.state.renderType]) {
                n = tempLDBlocks[this.state.renderType].boundRef(n)
                m = String(n)
            }
            if (tempLDBlocks[this.state.renderType][inType] === 'int') {
                tempLDBlocks[this.state.renderType].control[inName] = Math.floor(n);
                m = String(Math.floor(n))
                tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            } else {
                tempLDBlocks[this.state.renderType].control[inName] = n;
                tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            }
        } else {
            if (inLimits in tempLDBlocks[this.state.renderType]) {
                if (tempLDBlocks[this.state.renderType][inLimits][0] < 0) {
                    m = "0"
                } else {
                    m = String(tempLDBlocks[this.state.renderType][inLimits][0])
                }
            } else {
                m = "0"
            }
        }

        //this.setState( {LDBlocksOptions: tempLDBlocks, [event.target.name]: m} );
        this.setState( {LDBlocksOptions: tempLDBlocks, [name]: m} );
        this.props.handleSubState(tempLDBlocks[this.state.renderType]);
    }


    handleIn = (event) => {
        /*
        let inName = event.target.name
        let inLimits = inName + 'Limits'
        let inType = inName + "Type"

        
        let tempLDBlocks = this.state.LDBlocksOptions;
        */
        let m = event.target.value;
        this.setState( {[event.target.name]: m} );
        /*
        if (typeof m[1] !== 'undefined') {
            if (m[1] !== ".") {
                m = m.replace(/^0+/, '');
            }
        } 
        if (m === "") {
            if (inLimits in tempLDBlocks[this.state.renderType]) {
                m = String(tempLDBlocks[this.state.renderType][inLimits][0])
            } else {
                m = "0"
            }
        }

        if (!isNaN(parseFloat(m)) && isFinite(m)) {
            let n = Number(m);
            
            if (inLimits in tempLDBlocks[this.state.renderType]) {
                if (n < tempLDBlocks[this.state.renderType][inLimits][0]) {
                    n = tempLDBlocks[this.state.renderType][inLimits][0];
                    //m = tempLDBlocks[this.state.renderType][inLimits][0];
                } else if (n > tempLDBlocks[this.state.renderType][inLimits][1]) {
                    n = tempLDBlocks[this.state.renderType][inLimits][1];
                    //m = tempLDBlocks[this.state.renderType][inLimits][1];
                }
            }

            if (tempLDBlocks[this.state.renderType][inType] === 'int') {
                tempLDBlocks[this.state.renderType].control[inName] = Math.floor(n);
                tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            } else {
                tempLDBlocks[this.state.renderType].control[inName] = n;
                tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            }
            this.setState( {[event.target.name]: m} );
        } else {
            tempLDBlocks[this.state.renderType].control[inName] = 0;
            tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
            this.setState( {[event.target.name]: 0} );
        }
        this.setState( {LDBlocksOptions: tempLDBlocks} );
        this.props.handleSubState(tempLDBlocks[this.state.renderType]);
        */
    }

    handleBtn = (num) => {

        let numStr = String(num);
        let btnMap = 'btn' + numStr + 'map'
        let btnOptions = 'btn' + numStr + 'Options'
        let btnName = 'btn' + numStr
        let btnText = 'btn' + numStr + 'Txt'

        let tempLDBlocks = this.state.LDBlocksOptions;

        let nowIndex = tempLDBlocks[this.state.renderType][btnOptions].indexOf(tempLDBlocks[this.state.renderType].control[btnText])
        nowIndex += 1
        if (nowIndex >= tempLDBlocks[this.state.renderType][btnOptions].length) {
            nowIndex = 0
        }
        tempLDBlocks[this.state.renderType].control[btnName] = 
                    tempLDBlocks[this.state.renderType][btnMap][tempLDBlocks[this.state.renderType][btnOptions][nowIndex]];

        if (btnMap in tempLDBlocks[this.state.renderType]) {
            tempLDBlocks[this.state.renderType].control[btnText] = tempLDBlocks[this.state.renderType][btnOptions][nowIndex];
            this.setState( {[btnText]: tempLDBlocks[this.state.renderType].control[btnText]} );
        } else {
            this.setState( {[btnText]: tempLDBlocks[this.state.renderType].control[btnName]} );
        }
        tempLDBlocks[this.state.renderType].code = tempLDBlocks[this.state.renderType].codeRef(tempLDBlocks[this.state.renderType].control);
        
        this.setState( {LDBlocksOptions: tempLDBlocks} );
        this.props.handleSubState(tempLDBlocks[this.state.renderType]);
    }

    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Box 
                    height={this.state.LDBlocksOptions[this.state.renderType].height}
                    style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                >
                    <Typography 
                        className={classes.titleText}
                        variant='h4'
                        style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                    >
                        {this.state.LDBlocksOptions[this.state.renderType].title}
                    </Typography>
                    {
                    ((this.state.renderType === 'iloop') 
                    || (this.state.renderType === 'ihover')) && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{
                                    width: this.state.LDBlocksOptions[this.state.renderType].inWidth,
                                  }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        </Grid>
                    )}
                    {this.state.renderType === 'iflip' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        </Grid>
                    )}
                    {((this.state.renderType === 'iyaw') || (this.state.renderType === 'imove')) && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{
                                        width: this.state.LDBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text3}
                            </Typography>
                        </Grid>

                        </Grid>
                    )}
                    {(this.state.renderType === 'igo') && (
                        <React.Fragment>
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{
                                        width: this.state.LDBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in2'
                                style={{
                                        width: this.state.LDBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in2')}
                                value={this.state.in2}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in3'
                                style={{
                                        width: this.state.LDBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in3')}
                                value={this.state.in3}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        </Grid>
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text3}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in4'
                                style={{
                                        width: this.state.LDBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in4')}
                                value={this.state.in4}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text4}
                            </Typography>
                        </Grid>

                    </Grid>
                    </React.Fragment>
                    )}
                    {(this.state.renderType === 'irc') && (
                        <React.Fragment>
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputFieldRC}
                                name='in1'
                                style={{
                                        width: this.state.LDBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(2)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn2Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputFieldRC}
                                name='in2'
                                style={{
                                        width: this.state.LDBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in2')}
                                value={this.state.in2}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        </Grid>
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(3)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn3Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputFieldRC}
                                name='in3'
                                style={{
                                        width: this.state.LDBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in3')}
                                value={this.state.in3}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(4)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn4Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in4'
                                style={{
                                        width: this.state.LDBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in4')}
                                value={this.state.in4}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        </Grid>
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in5'
                                style={{
                                        width: this.state.LDBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in5')}
                                value={this.state.in5}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        </Grid>
                        </React.Fragment>
                    )}

                </Box>
            </React.Fragment>
        );
    }



}


export default withStyles(styles)(LocoDroneTBlocks);

