export const log_toc_cached = 
{
    "activeMarker": {
        "btSns": {
            "ident": 0,
            "group": "activeMarker",
            "name": "btSns",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "i2cOk": {
            "ident": 1,
            "group": "activeMarker",
            "name": "i2cOk",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "motion": {
        "motion": {
            "ident": 2,
            "group": "motion",
            "name": "motion",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "deltaX": {
            "ident": 3,
            "group": "motion",
            "name": "deltaX",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "deltaY": {
            "ident": 4,
            "group": "motion",
            "name": "deltaY",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "shutter": {
            "ident": 5,
            "group": "motion",
            "name": "shutter",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "maxRaw": {
            "ident": 6,
            "group": "motion",
            "name": "maxRaw",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "minRaw": {
            "ident": 7,
            "group": "motion",
            "name": "minRaw",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "Rawsum": {
            "ident": 8,
            "group": "motion",
            "name": "Rawsum",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "outlierCount": {
            "ident": 9,
            "group": "motion",
            "name": "outlierCount",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "squal": {
            "ident": 10,
            "group": "motion",
            "name": "squal",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "std": {
            "ident": 11,
            "group": "motion",
            "name": "std",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "ring": {
        "fadeTime": {
            "ident": 12,
            "group": "ring",
            "name": "fadeTime",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "loco": {
        "mode": {
            "ident": 13,
            "group": "loco",
            "name": "mode",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "spiWr": {
            "ident": 14,
            "group": "loco",
            "name": "spiWr",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "spiRe": {
            "ident": 15,
            "group": "loco",
            "name": "spiRe",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "ranging": {
        "state": {
            "ident": 16,
            "group": "ranging",
            "name": "state",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "distance0": {
            "ident": 17,
            "group": "ranging",
            "name": "distance0",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "distance1": {
            "ident": 18,
            "group": "ranging",
            "name": "distance1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "distance2": {
            "ident": 19,
            "group": "ranging",
            "name": "distance2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "distance3": {
            "ident": 20,
            "group": "ranging",
            "name": "distance3",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "distance4": {
            "ident": 21,
            "group": "ranging",
            "name": "distance4",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "distance5": {
            "ident": 22,
            "group": "ranging",
            "name": "distance5",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "distance6": {
            "ident": 23,
            "group": "ranging",
            "name": "distance6",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "distance7": {
            "ident": 24,
            "group": "ranging",
            "name": "distance7",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pressure0": {
            "ident": 25,
            "group": "ranging",
            "name": "pressure0",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pressure1": {
            "ident": 26,
            "group": "ranging",
            "name": "pressure1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pressure2": {
            "ident": 27,
            "group": "ranging",
            "name": "pressure2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pressure3": {
            "ident": 28,
            "group": "ranging",
            "name": "pressure3",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pressure4": {
            "ident": 29,
            "group": "ranging",
            "name": "pressure4",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pressure5": {
            "ident": 30,
            "group": "ranging",
            "name": "pressure5",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pressure6": {
            "ident": 31,
            "group": "ranging",
            "name": "pressure6",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pressure7": {
            "ident": 32,
            "group": "ranging",
            "name": "pressure7",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "tdoa2": {
        "d7-0": {
            "ident": 33,
            "group": "tdoa2",
            "name": "d7-0",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "d0-1": {
            "ident": 34,
            "group": "tdoa2",
            "name": "d0-1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "d1-2": {
            "ident": 35,
            "group": "tdoa2",
            "name": "d1-2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "d2-3": {
            "ident": 36,
            "group": "tdoa2",
            "name": "d2-3",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "d3-4": {
            "ident": 37,
            "group": "tdoa2",
            "name": "d3-4",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "d4-5": {
            "ident": 38,
            "group": "tdoa2",
            "name": "d4-5",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "d5-6": {
            "ident": 39,
            "group": "tdoa2",
            "name": "d5-6",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "d6-7": {
            "ident": 40,
            "group": "tdoa2",
            "name": "d6-7",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cc0": {
            "ident": 41,
            "group": "tdoa2",
            "name": "cc0",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cc1": {
            "ident": 42,
            "group": "tdoa2",
            "name": "cc1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cc2": {
            "ident": 43,
            "group": "tdoa2",
            "name": "cc2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cc3": {
            "ident": 44,
            "group": "tdoa2",
            "name": "cc3",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cc4": {
            "ident": 45,
            "group": "tdoa2",
            "name": "cc4",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cc5": {
            "ident": 46,
            "group": "tdoa2",
            "name": "cc5",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cc6": {
            "ident": 47,
            "group": "tdoa2",
            "name": "cc6",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cc7": {
            "ident": 48,
            "group": "tdoa2",
            "name": "cc7",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "dist7-0": {
            "ident": 49,
            "group": "tdoa2",
            "name": "dist7-0",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "dist0-1": {
            "ident": 50,
            "group": "tdoa2",
            "name": "dist0-1",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "dist1-2": {
            "ident": 51,
            "group": "tdoa2",
            "name": "dist1-2",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "dist2-3": {
            "ident": 52,
            "group": "tdoa2",
            "name": "dist2-3",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "dist3-4": {
            "ident": 53,
            "group": "tdoa2",
            "name": "dist3-4",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "dist4-5": {
            "ident": 54,
            "group": "tdoa2",
            "name": "dist4-5",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "dist5-6": {
            "ident": 55,
            "group": "tdoa2",
            "name": "dist5-6",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "dist6-7": {
            "ident": 56,
            "group": "tdoa2",
            "name": "dist6-7",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "twr": {
        "rangingSuccessRate0": {
            "ident": 57,
            "group": "twr",
            "name": "rangingSuccessRate0",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rangingPerSec0": {
            "ident": 58,
            "group": "twr",
            "name": "rangingPerSec0",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rangingSuccessRate1": {
            "ident": 59,
            "group": "twr",
            "name": "rangingSuccessRate1",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rangingPerSec1": {
            "ident": 60,
            "group": "twr",
            "name": "rangingPerSec1",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rangingSuccessRate2": {
            "ident": 61,
            "group": "twr",
            "name": "rangingSuccessRate2",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rangingPerSec2": {
            "ident": 62,
            "group": "twr",
            "name": "rangingPerSec2",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rangingSuccessRate3": {
            "ident": 63,
            "group": "twr",
            "name": "rangingSuccessRate3",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rangingPerSec3": {
            "ident": 64,
            "group": "twr",
            "name": "rangingPerSec3",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rangingSuccessRate4": {
            "ident": 65,
            "group": "twr",
            "name": "rangingSuccessRate4",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rangingPerSec4": {
            "ident": 66,
            "group": "twr",
            "name": "rangingPerSec4",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rangingSuccessRate5": {
            "ident": 67,
            "group": "twr",
            "name": "rangingSuccessRate5",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rangingPerSec5": {
            "ident": 68,
            "group": "twr",
            "name": "rangingPerSec5",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "oa": {
        "front": {
            "ident": 69,
            "group": "oa",
            "name": "front",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "back": {
            "ident": 70,
            "group": "oa",
            "name": "back",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "up": {
            "ident": 71,
            "group": "oa",
            "name": "up",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "left": {
            "ident": 72,
            "group": "oa",
            "name": "left",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "right": {
            "ident": 73,
            "group": "oa",
            "name": "right",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "usd": {
        "spiWrBps": {
            "ident": 74,
            "group": "usd",
            "name": "spiWrBps",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "spiReBps": {
            "ident": 75,
            "group": "usd",
            "name": "spiReBps",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "fatWrBps": {
            "ident": 76,
            "group": "usd",
            "name": "fatWrBps",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "motor": {
        "m1": {
            "ident": 77,
            "group": "motor",
            "name": "m1",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 0
        },
        "m2": {
            "ident": 78,
            "group": "motor",
            "name": "m2",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 0
        },
        "m3": {
            "ident": 79,
            "group": "motor",
            "name": "m3",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 0
        },
        "m4": {
            "ident": 80,
            "group": "motor",
            "name": "m4",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 0
        },
        "m1req": {
            "ident": 81,
            "group": "motor",
            "name": "m1req",
            "ctype": "int32_t",
            "pytype": "<i",
            "access": 0
        },
        "m2req": {
            "ident": 82,
            "group": "motor",
            "name": "m2req",
            "ctype": "int32_t",
            "pytype": "<i",
            "access": 0
        },
        "m3req": {
            "ident": 83,
            "group": "motor",
            "name": "m3req",
            "ctype": "int32_t",
            "pytype": "<i",
            "access": 0
        },
        "m4req": {
            "ident": 84,
            "group": "motor",
            "name": "m4req",
            "ctype": "int32_t",
            "pytype": "<i",
            "access": 0
        }
    },
    "pm": {
        "vbat": {
            "ident": 85,
            "group": "pm",
            "name": "vbat",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vbatMV": {
            "ident": 86,
            "group": "pm",
            "name": "vbatMV",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "extVbat": {
            "ident": 87,
            "group": "pm",
            "name": "extVbat",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "extVbatMV": {
            "ident": 88,
            "group": "pm",
            "name": "extVbatMV",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "extCurr": {
            "ident": 89,
            "group": "pm",
            "name": "extCurr",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "chargeCurrent": {
            "ident": 90,
            "group": "pm",
            "name": "chargeCurrent",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "state": {
            "ident": 91,
            "group": "pm",
            "name": "state",
            "ctype": "int8_t",
            "pytype": "<b",
            "access": 0
        },
        "batteryLevel": {
            "ident": 92,
            "group": "pm",
            "name": "batteryLevel",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "radio": {
        "rssi": {
            "ident": 93,
            "group": "radio",
            "name": "rssi",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "isConnected": {
            "ident": 94,
            "group": "radio",
            "name": "isConnected",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "gyro": {
        "xRaw": {
            "ident": 95,
            "group": "gyro",
            "name": "xRaw",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "yRaw": {
            "ident": 96,
            "group": "gyro",
            "name": "yRaw",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "zRaw": {
            "ident": 97,
            "group": "gyro",
            "name": "zRaw",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "xVariance": {
            "ident": 98,
            "group": "gyro",
            "name": "xVariance",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yVariance": {
            "ident": 99,
            "group": "gyro",
            "name": "yVariance",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zVariance": {
            "ident": 100,
            "group": "gyro",
            "name": "zVariance",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "x": {
            "ident": 101,
            "group": "gyro",
            "name": "x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "y": {
            "ident": 102,
            "group": "gyro",
            "name": "y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "z": {
            "ident": 103,
            "group": "gyro",
            "name": "z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "colAv": {
        "latency": {
            "ident": 104,
            "group": "colAv",
            "name": "latency",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 0
        }
    },
    "ext_pos": {
        "X": {
            "ident": 105,
            "group": "ext_pos",
            "name": "X",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Y": {
            "ident": 106,
            "group": "ext_pos",
            "name": "Y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Z": {
            "ident": 107,
            "group": "ext_pos",
            "name": "Z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "locSrv": {
        "x": {
            "ident": 108,
            "group": "locSrv",
            "name": "x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "y": {
            "ident": 109,
            "group": "locSrv",
            "name": "y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "z": {
            "ident": 110,
            "group": "locSrv",
            "name": "z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "qx": {
            "ident": 111,
            "group": "locSrv",
            "name": "qx",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "qy": {
            "ident": 112,
            "group": "locSrv",
            "name": "qy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "qz": {
            "ident": 113,
            "group": "locSrv",
            "name": "qz",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "qw": {
            "ident": 114,
            "group": "locSrv",
            "name": "qw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "locSrvZ": {
        "tick": {
            "ident": 115,
            "group": "locSrvZ",
            "name": "tick",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "crtp": {
        "rxRate": {
            "ident": 116,
            "group": "crtp",
            "name": "rxRate",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "txRate": {
            "ident": 117,
            "group": "crtp",
            "name": "txRate",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "extrx": {
        "thrust": {
            "ident": 118,
            "group": "extrx",
            "name": "thrust",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll": {
            "ident": 119,
            "group": "extrx",
            "name": "roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch": {
            "ident": 120,
            "group": "extrx",
            "name": "pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rollRate": {
            "ident": 121,
            "group": "extrx",
            "name": "rollRate",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitchRate": {
            "ident": 122,
            "group": "extrx",
            "name": "pitchRate",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yawRate": {
            "ident": 123,
            "group": "extrx",
            "name": "yawRate",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zVel": {
            "ident": 124,
            "group": "extrx",
            "name": "zVel",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "AltHold": {
            "ident": 125,
            "group": "extrx",
            "name": "AltHold",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "Arm": {
            "ident": 126,
            "group": "extrx",
            "name": "Arm",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "extrx_raw": {
        "ch0": {
            "ident": 127,
            "group": "extrx_raw",
            "name": "ch0",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "ch1": {
            "ident": 128,
            "group": "extrx_raw",
            "name": "ch1",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "ch2": {
            "ident": 129,
            "group": "extrx_raw",
            "name": "ch2",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "ch3": {
            "ident": 130,
            "group": "extrx_raw",
            "name": "ch3",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "ch4": {
            "ident": 131,
            "group": "extrx_raw",
            "name": "ch4",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "ch5": {
            "ident": 132,
            "group": "extrx_raw",
            "name": "ch5",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "ch6": {
            "ident": 133,
            "group": "extrx_raw",
            "name": "ch6",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "ch7": {
            "ident": 134,
            "group": "extrx_raw",
            "name": "ch7",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "health": {
        "motorVarXM1": {
            "ident": 135,
            "group": "health",
            "name": "motorVarXM1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "motorVarYM1": {
            "ident": 136,
            "group": "health",
            "name": "motorVarYM1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "motorVarXM2": {
            "ident": 137,
            "group": "health",
            "name": "motorVarXM2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "motorVarYM2": {
            "ident": 138,
            "group": "health",
            "name": "motorVarYM2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "motorVarXM3": {
            "ident": 139,
            "group": "health",
            "name": "motorVarXM3",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "motorVarYM3": {
            "ident": 140,
            "group": "health",
            "name": "motorVarYM3",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "motorVarXM4": {
            "ident": 141,
            "group": "health",
            "name": "motorVarXM4",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "motorVarYM4": {
            "ident": 142,
            "group": "health",
            "name": "motorVarYM4",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "motorPass": {
            "ident": 143,
            "group": "health",
            "name": "motorPass",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "batterySag": {
            "ident": 144,
            "group": "health",
            "name": "batterySag",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "batteryPass": {
            "ident": 145,
            "group": "health",
            "name": "batteryPass",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "motorTestCount": {
            "ident": 146,
            "group": "health",
            "name": "motorTestCount",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "memTst": {
        "errCntW": {
            "ident": 147,
            "group": "memTst",
            "name": "errCntW",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 0
        }
    },
    "range": {
        "front": {
            "ident": 148,
            "group": "range",
            "name": "front",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "back": {
            "ident": 149,
            "group": "range",
            "name": "back",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "up": {
            "ident": 150,
            "group": "range",
            "name": "up",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "left": {
            "ident": 151,
            "group": "range",
            "name": "left",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "right": {
            "ident": 152,
            "group": "range",
            "name": "right",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "zrange": {
            "ident": 153,
            "group": "range",
            "name": "zrange",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "sensfusion6": {
        "qw": {
            "ident": 154,
            "group": "sensfusion6",
            "name": "qw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "qx": {
            "ident": 155,
            "group": "sensfusion6",
            "name": "qx",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "qy": {
            "ident": 156,
            "group": "sensfusion6",
            "name": "qy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "qz": {
            "ident": 157,
            "group": "sensfusion6",
            "name": "qz",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "gravityX": {
            "ident": 158,
            "group": "sensfusion6",
            "name": "gravityX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "gravityY": {
            "ident": 159,
            "group": "sensfusion6",
            "name": "gravityY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "gravityZ": {
            "ident": 160,
            "group": "sensfusion6",
            "name": "gravityZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accZbase": {
            "ident": 161,
            "group": "sensfusion6",
            "name": "accZbase",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "isInit": {
            "ident": 162,
            "group": "sensfusion6",
            "name": "isInit",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "isCalibrated": {
            "ident": 163,
            "group": "sensfusion6",
            "name": "isCalibrated",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    },
    "acc": {
        "x": {
            "ident": 164,
            "group": "acc",
            "name": "x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "y": {
            "ident": 165,
            "group": "acc",
            "name": "y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "z": {
            "ident": 166,
            "group": "acc",
            "name": "z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "baro": {
        "asl": {
            "ident": 167,
            "group": "baro",
            "name": "asl",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "temp": {
            "ident": 168,
            "group": "baro",
            "name": "temp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pressure": {
            "ident": 169,
            "group": "baro",
            "name": "pressure",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "controller": {
        "ctr_yaw": {
            "ident": 170,
            "group": "controller",
            "name": "ctr_yaw",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "cmd_thrust": {
            "ident": 171,
            "group": "controller",
            "name": "cmd_thrust",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cmd_roll": {
            "ident": 172,
            "group": "controller",
            "name": "cmd_roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cmd_pitch": {
            "ident": 173,
            "group": "controller",
            "name": "cmd_pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cmd_yaw": {
            "ident": 174,
            "group": "controller",
            "name": "cmd_yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "r_roll": {
            "ident": 175,
            "group": "controller",
            "name": "r_roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "r_pitch": {
            "ident": 176,
            "group": "controller",
            "name": "r_pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "r_yaw": {
            "ident": 177,
            "group": "controller",
            "name": "r_yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accelz": {
            "ident": 178,
            "group": "controller",
            "name": "accelz",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "actuatorThrust": {
            "ident": 179,
            "group": "controller",
            "name": "actuatorThrust",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll": {
            "ident": 180,
            "group": "controller",
            "name": "roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch": {
            "ident": 181,
            "group": "controller",
            "name": "pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw": {
            "ident": 182,
            "group": "controller",
            "name": "yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rollRate": {
            "ident": 183,
            "group": "controller",
            "name": "rollRate",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitchRate": {
            "ident": 184,
            "group": "controller",
            "name": "pitchRate",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yawRate": {
            "ident": 185,
            "group": "controller",
            "name": "yawRate",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "ctrltarget": {
        "x": {
            "ident": 186,
            "group": "ctrltarget",
            "name": "x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "y": {
            "ident": 187,
            "group": "ctrltarget",
            "name": "y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "z": {
            "ident": 188,
            "group": "ctrltarget",
            "name": "z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vx": {
            "ident": 189,
            "group": "ctrltarget",
            "name": "vx",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vy": {
            "ident": 190,
            "group": "ctrltarget",
            "name": "vy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vz": {
            "ident": 191,
            "group": "ctrltarget",
            "name": "vz",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ax": {
            "ident": 192,
            "group": "ctrltarget",
            "name": "ax",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ay": {
            "ident": 193,
            "group": "ctrltarget",
            "name": "ay",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "az": {
            "ident": 194,
            "group": "ctrltarget",
            "name": "az",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll": {
            "ident": 195,
            "group": "ctrltarget",
            "name": "roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch": {
            "ident": 196,
            "group": "ctrltarget",
            "name": "pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw": {
            "ident": 197,
            "group": "ctrltarget",
            "name": "yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "ctrltargetZ": {
        "x": {
            "ident": 198,
            "group": "ctrltargetZ",
            "name": "x",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "y": {
            "ident": 199,
            "group": "ctrltargetZ",
            "name": "y",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "z": {
            "ident": 200,
            "group": "ctrltargetZ",
            "name": "z",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "vx": {
            "ident": 201,
            "group": "ctrltargetZ",
            "name": "vx",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "vy": {
            "ident": 202,
            "group": "ctrltargetZ",
            "name": "vy",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "vz": {
            "ident": 203,
            "group": "ctrltargetZ",
            "name": "vz",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "ax": {
            "ident": 204,
            "group": "ctrltargetZ",
            "name": "ax",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "ay": {
            "ident": 205,
            "group": "ctrltargetZ",
            "name": "ay",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "az": {
            "ident": 206,
            "group": "ctrltargetZ",
            "name": "az",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        }
    },
    "mag": {
        "x": {
            "ident": 207,
            "group": "mag",
            "name": "x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "y": {
            "ident": 208,
            "group": "mag",
            "name": "y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "z": {
            "ident": 209,
            "group": "mag",
            "name": "z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "stabilizer": {
        "roll": {
            "ident": 210,
            "group": "stabilizer",
            "name": "roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch": {
            "ident": 211,
            "group": "stabilizer",
            "name": "pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw": {
            "ident": 212,
            "group": "stabilizer",
            "name": "yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "thrust": {
            "ident": 213,
            "group": "stabilizer",
            "name": "thrust",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rtStab": {
            "ident": 214,
            "group": "stabilizer",
            "name": "rtStab",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "intToOut": {
            "ident": 215,
            "group": "stabilizer",
            "name": "intToOut",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 0
        }
    },
    "stateEstimate": {
        "x": {
            "ident": 216,
            "group": "stateEstimate",
            "name": "x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "y": {
            "ident": 217,
            "group": "stateEstimate",
            "name": "y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "z": {
            "ident": 218,
            "group": "stateEstimate",
            "name": "z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vx": {
            "ident": 219,
            "group": "stateEstimate",
            "name": "vx",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vy": {
            "ident": 220,
            "group": "stateEstimate",
            "name": "vy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "vz": {
            "ident": 221,
            "group": "stateEstimate",
            "name": "vz",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ax": {
            "ident": 222,
            "group": "stateEstimate",
            "name": "ax",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ay": {
            "ident": 223,
            "group": "stateEstimate",
            "name": "ay",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "az": {
            "ident": 224,
            "group": "stateEstimate",
            "name": "az",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll": {
            "ident": 225,
            "group": "stateEstimate",
            "name": "roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch": {
            "ident": 226,
            "group": "stateEstimate",
            "name": "pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw": {
            "ident": 227,
            "group": "stateEstimate",
            "name": "yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "qx": {
            "ident": 228,
            "group": "stateEstimate",
            "name": "qx",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "qy": {
            "ident": 229,
            "group": "stateEstimate",
            "name": "qy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "qz": {
            "ident": 230,
            "group": "stateEstimate",
            "name": "qz",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "qw": {
            "ident": 231,
            "group": "stateEstimate",
            "name": "qw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "stateEstimateZ": {
        "x": {
            "ident": 232,
            "group": "stateEstimateZ",
            "name": "x",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "y": {
            "ident": 233,
            "group": "stateEstimateZ",
            "name": "y",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "z": {
            "ident": 234,
            "group": "stateEstimateZ",
            "name": "z",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "vx": {
            "ident": 235,
            "group": "stateEstimateZ",
            "name": "vx",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "vy": {
            "ident": 236,
            "group": "stateEstimateZ",
            "name": "vy",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "vz": {
            "ident": 237,
            "group": "stateEstimateZ",
            "name": "vz",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "ax": {
            "ident": 238,
            "group": "stateEstimateZ",
            "name": "ax",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "ay": {
            "ident": 239,
            "group": "stateEstimateZ",
            "name": "ay",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "az": {
            "ident": 240,
            "group": "stateEstimateZ",
            "name": "az",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "quat": {
            "ident": 241,
            "group": "stateEstimateZ",
            "name": "quat",
            "ctype": "uint32_t",
            "pytype": "<L",
            "access": 0
        },
        "rateRoll": {
            "ident": 242,
            "group": "stateEstimateZ",
            "name": "rateRoll",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "ratePitch": {
            "ident": 243,
            "group": "stateEstimateZ",
            "name": "ratePitch",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        },
        "rateYaw": {
            "ident": 244,
            "group": "stateEstimateZ",
            "name": "rateYaw",
            "ctype": "int16_t",
            "pytype": "<h",
            "access": 0
        }
    },
    "supervisor": {
        "info": {
            "ident": 245,
            "group": "supervisor",
            "name": "info",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        }
    },
    "sys": {
        "canfly": {
            "ident": 246,
            "group": "sys",
            "name": "canfly",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "isFlying": {
            "ident": 247,
            "group": "sys",
            "name": "isFlying",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "isTumbled": {
            "ident": 248,
            "group": "sys",
            "name": "isTumbled",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "testLogParam": {
            "ident": 249,
            "group": "sys",
            "name": "testLogParam",
            "ctype": "int8_t",
            "pytype": "<b",
            "access": 0
        }
    },
    "tdoaEngine": {
        "stRx": {
            "ident": 250,
            "group": "tdoaEngine",
            "name": "stRx",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "stEst": {
            "ident": 251,
            "group": "tdoaEngine",
            "name": "stEst",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "stTime": {
            "ident": 252,
            "group": "tdoaEngine",
            "name": "stTime",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "stFound": {
            "ident": 253,
            "group": "tdoaEngine",
            "name": "stFound",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "stCc": {
            "ident": 254,
            "group": "tdoaEngine",
            "name": "stCc",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "stHit": {
            "ident": 255,
            "group": "tdoaEngine",
            "name": "stHit",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "stMiss": {
            "ident": 256,
            "group": "tdoaEngine",
            "name": "stMiss",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cc": {
            "ident": 257,
            "group": "tdoaEngine",
            "name": "cc",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "tof": {
            "ident": 258,
            "group": "tdoaEngine",
            "name": "tof",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "tdoa": {
            "ident": 259,
            "group": "tdoaEngine",
            "name": "tdoa",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "kalman_pred": {
        "predNX": {
            "ident": 260,
            "group": "kalman_pred",
            "name": "predNX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "predNY": {
            "ident": 261,
            "group": "kalman_pred",
            "name": "predNY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "measNX": {
            "ident": 262,
            "group": "kalman_pred",
            "name": "measNX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "measNY": {
            "ident": 263,
            "group": "kalman_pred",
            "name": "measNY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "lighthouse": {
        "validAngles": {
            "ident": 264,
            "group": "lighthouse",
            "name": "validAngles",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "rawAngle0x": {
            "ident": 265,
            "group": "lighthouse",
            "name": "rawAngle0x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rawAngle0y": {
            "ident": 266,
            "group": "lighthouse",
            "name": "rawAngle0y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rawAngle1x": {
            "ident": 267,
            "group": "lighthouse",
            "name": "rawAngle1x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rawAngle1y": {
            "ident": 268,
            "group": "lighthouse",
            "name": "rawAngle1y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle0x": {
            "ident": 269,
            "group": "lighthouse",
            "name": "angle0x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle0y": {
            "ident": 270,
            "group": "lighthouse",
            "name": "angle0y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle1x": {
            "ident": 271,
            "group": "lighthouse",
            "name": "angle1x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle1y": {
            "ident": 272,
            "group": "lighthouse",
            "name": "angle1y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle0x_1": {
            "ident": 273,
            "group": "lighthouse",
            "name": "angle0x_1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle0y_1": {
            "ident": 274,
            "group": "lighthouse",
            "name": "angle0y_1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle1x_1": {
            "ident": 275,
            "group": "lighthouse",
            "name": "angle1x_1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle1y_1": {
            "ident": 276,
            "group": "lighthouse",
            "name": "angle1y_1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle0x_2": {
            "ident": 277,
            "group": "lighthouse",
            "name": "angle0x_2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle0y_2": {
            "ident": 278,
            "group": "lighthouse",
            "name": "angle0y_2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle1x_2": {
            "ident": 279,
            "group": "lighthouse",
            "name": "angle1x_2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle1y_2": {
            "ident": 280,
            "group": "lighthouse",
            "name": "angle1y_2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle0x_3": {
            "ident": 281,
            "group": "lighthouse",
            "name": "angle0x_3",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle0y_3": {
            "ident": 282,
            "group": "lighthouse",
            "name": "angle0y_3",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle1x_3": {
            "ident": 283,
            "group": "lighthouse",
            "name": "angle1x_3",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle1y_3": {
            "ident": 284,
            "group": "lighthouse",
            "name": "angle1y_3",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rawAngle0xlh2": {
            "ident": 285,
            "group": "lighthouse",
            "name": "rawAngle0xlh2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rawAngle0ylh2": {
            "ident": 286,
            "group": "lighthouse",
            "name": "rawAngle0ylh2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rawAngle1xlh2": {
            "ident": 287,
            "group": "lighthouse",
            "name": "rawAngle1xlh2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rawAngle1ylh2": {
            "ident": 288,
            "group": "lighthouse",
            "name": "rawAngle1ylh2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle0x_0lh2": {
            "ident": 289,
            "group": "lighthouse",
            "name": "angle0x_0lh2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle0y_0lh2": {
            "ident": 290,
            "group": "lighthouse",
            "name": "angle0y_0lh2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle1x_0lh2": {
            "ident": 291,
            "group": "lighthouse",
            "name": "angle1x_0lh2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angle1y_0lh2": {
            "ident": 292,
            "group": "lighthouse",
            "name": "angle1y_0lh2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "width0": {
            "ident": 293,
            "group": "lighthouse",
            "name": "width0",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "width1": {
            "ident": 294,
            "group": "lighthouse",
            "name": "width1",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "width2": {
            "ident": 295,
            "group": "lighthouse",
            "name": "width2",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "width3": {
            "ident": 296,
            "group": "lighthouse",
            "name": "width3",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "comSync": {
            "ident": 297,
            "group": "lighthouse",
            "name": "comSync",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "bsAvailable": {
            "ident": 298,
            "group": "lighthouse",
            "name": "bsAvailable",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "bsReceive": {
            "ident": 299,
            "group": "lighthouse",
            "name": "bsReceive",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "bsActive": {
            "ident": 300,
            "group": "lighthouse",
            "name": "bsActive",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "bsCalUd": {
            "ident": 301,
            "group": "lighthouse",
            "name": "bsCalUd",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "bsCalCon": {
            "ident": 302,
            "group": "lighthouse",
            "name": "bsCalCon",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "status": {
            "ident": 303,
            "group": "lighthouse",
            "name": "status",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "posRt": {
            "ident": 304,
            "group": "lighthouse",
            "name": "posRt",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "estBs0Rt": {
            "ident": 305,
            "group": "lighthouse",
            "name": "estBs0Rt",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "estBs1Rt": {
            "ident": 306,
            "group": "lighthouse",
            "name": "estBs1Rt",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "x": {
            "ident": 307,
            "group": "lighthouse",
            "name": "x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "y": {
            "ident": 308,
            "group": "lighthouse",
            "name": "y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "z": {
            "ident": 309,
            "group": "lighthouse",
            "name": "z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "delta": {
            "ident": 310,
            "group": "lighthouse",
            "name": "delta",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bsGeoVal": {
            "ident": 311,
            "group": "lighthouse",
            "name": "bsGeoVal",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "bsCalVal": {
            "ident": 312,
            "group": "lighthouse",
            "name": "bsCalVal",
            "ctype": "uint16_t",
            "pytype": "<H",
            "access": 0
        },
        "disProb": {
            "ident": 313,
            "group": "lighthouse",
            "name": "disProb",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "pid_attitude": {
        "roll_outP": {
            "ident": 314,
            "group": "pid_attitude",
            "name": "roll_outP",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_outI": {
            "ident": 315,
            "group": "pid_attitude",
            "name": "roll_outI",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_outD": {
            "ident": 316,
            "group": "pid_attitude",
            "name": "roll_outD",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_outFF": {
            "ident": 317,
            "group": "pid_attitude",
            "name": "roll_outFF",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_outP": {
            "ident": 318,
            "group": "pid_attitude",
            "name": "pitch_outP",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_outI": {
            "ident": 319,
            "group": "pid_attitude",
            "name": "pitch_outI",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_outD": {
            "ident": 320,
            "group": "pid_attitude",
            "name": "pitch_outD",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_outFF": {
            "ident": 321,
            "group": "pid_attitude",
            "name": "pitch_outFF",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_outP": {
            "ident": 322,
            "group": "pid_attitude",
            "name": "yaw_outP",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_outI": {
            "ident": 323,
            "group": "pid_attitude",
            "name": "yaw_outI",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_outD": {
            "ident": 324,
            "group": "pid_attitude",
            "name": "yaw_outD",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_outFF": {
            "ident": 325,
            "group": "pid_attitude",
            "name": "yaw_outFF",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "pid_rate": {
        "roll_outP": {
            "ident": 326,
            "group": "pid_rate",
            "name": "roll_outP",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_outI": {
            "ident": 327,
            "group": "pid_rate",
            "name": "roll_outI",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_outD": {
            "ident": 328,
            "group": "pid_rate",
            "name": "roll_outD",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "roll_outFF": {
            "ident": 329,
            "group": "pid_rate",
            "name": "roll_outFF",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_outP": {
            "ident": 330,
            "group": "pid_rate",
            "name": "pitch_outP",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_outI": {
            "ident": 331,
            "group": "pid_rate",
            "name": "pitch_outI",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_outD": {
            "ident": 332,
            "group": "pid_rate",
            "name": "pitch_outD",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "pitch_outFF": {
            "ident": 333,
            "group": "pid_rate",
            "name": "pitch_outFF",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_outP": {
            "ident": 334,
            "group": "pid_rate",
            "name": "yaw_outP",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_outI": {
            "ident": 335,
            "group": "pid_rate",
            "name": "yaw_outI",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_outD": {
            "ident": 336,
            "group": "pid_rate",
            "name": "yaw_outD",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "yaw_outFF": {
            "ident": 337,
            "group": "pid_rate",
            "name": "yaw_outFF",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "ctrlINDI": {
        "cmd_thrust": {
            "ident": 338,
            "group": "ctrlINDI",
            "name": "cmd_thrust",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cmd_roll": {
            "ident": 339,
            "group": "ctrlINDI",
            "name": "cmd_roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cmd_pitch": {
            "ident": 340,
            "group": "ctrlINDI",
            "name": "cmd_pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cmd_yaw": {
            "ident": 341,
            "group": "ctrlINDI",
            "name": "cmd_yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "r_roll": {
            "ident": 342,
            "group": "ctrlINDI",
            "name": "r_roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "r_pitch": {
            "ident": 343,
            "group": "ctrlINDI",
            "name": "r_pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "r_yaw": {
            "ident": 344,
            "group": "ctrlINDI",
            "name": "r_yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "u_act_dyn_p": {
            "ident": 345,
            "group": "ctrlINDI",
            "name": "u_act_dyn_p",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "u_act_dyn_q": {
            "ident": 346,
            "group": "ctrlINDI",
            "name": "u_act_dyn_q",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "u_act_dyn_r": {
            "ident": 347,
            "group": "ctrlINDI",
            "name": "u_act_dyn_r",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "du_p": {
            "ident": 348,
            "group": "ctrlINDI",
            "name": "du_p",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "du_q": {
            "ident": 349,
            "group": "ctrlINDI",
            "name": "du_q",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "du_r": {
            "ident": 350,
            "group": "ctrlINDI",
            "name": "du_r",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ang_accel_ref_p": {
            "ident": 351,
            "group": "ctrlINDI",
            "name": "ang_accel_ref_p",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ang_accel_ref_q": {
            "ident": 352,
            "group": "ctrlINDI",
            "name": "ang_accel_ref_q",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "ang_accel_ref_r": {
            "ident": 353,
            "group": "ctrlINDI",
            "name": "ang_accel_ref_r",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rate_d[0]": {
            "ident": 354,
            "group": "ctrlINDI",
            "name": "rate_d[0]",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rate_d[1]": {
            "ident": 355,
            "group": "ctrlINDI",
            "name": "rate_d[1]",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rate_d[2]": {
            "ident": 356,
            "group": "ctrlINDI",
            "name": "rate_d[2]",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "uf_p": {
            "ident": 357,
            "group": "ctrlINDI",
            "name": "uf_p",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "uf_q": {
            "ident": 358,
            "group": "ctrlINDI",
            "name": "uf_q",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "uf_r": {
            "ident": 359,
            "group": "ctrlINDI",
            "name": "uf_r",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Omega_f_p": {
            "ident": 360,
            "group": "ctrlINDI",
            "name": "Omega_f_p",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Omega_f_q": {
            "ident": 361,
            "group": "ctrlINDI",
            "name": "Omega_f_q",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Omega_f_r": {
            "ident": 362,
            "group": "ctrlINDI",
            "name": "Omega_f_r",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "n_p": {
            "ident": 363,
            "group": "ctrlINDI",
            "name": "n_p",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "n_q": {
            "ident": 364,
            "group": "ctrlINDI",
            "name": "n_q",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "n_r": {
            "ident": 365,
            "group": "ctrlINDI",
            "name": "n_r",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "ctrlMel": {
        "cmd_thrust": {
            "ident": 366,
            "group": "ctrlMel",
            "name": "cmd_thrust",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cmd_roll": {
            "ident": 367,
            "group": "ctrlMel",
            "name": "cmd_roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cmd_pitch": {
            "ident": 368,
            "group": "ctrlMel",
            "name": "cmd_pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cmd_yaw": {
            "ident": 369,
            "group": "ctrlMel",
            "name": "cmd_yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "r_roll": {
            "ident": 370,
            "group": "ctrlMel",
            "name": "r_roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "r_pitch": {
            "ident": 371,
            "group": "ctrlMel",
            "name": "r_pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "r_yaw": {
            "ident": 372,
            "group": "ctrlMel",
            "name": "r_yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accelz": {
            "ident": 373,
            "group": "ctrlMel",
            "name": "accelz",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zdx": {
            "ident": 374,
            "group": "ctrlMel",
            "name": "zdx",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zdy": {
            "ident": 375,
            "group": "ctrlMel",
            "name": "zdy",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "zdz": {
            "ident": 376,
            "group": "ctrlMel",
            "name": "zdz",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "i_err_x": {
            "ident": 377,
            "group": "ctrlMel",
            "name": "i_err_x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "i_err_y": {
            "ident": 378,
            "group": "ctrlMel",
            "name": "i_err_y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "i_err_z": {
            "ident": 379,
            "group": "ctrlMel",
            "name": "i_err_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "posCtrlIndi": {
        "posRef_x": {
            "ident": 380,
            "group": "posCtrlIndi",
            "name": "posRef_x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "posRef_y": {
            "ident": 381,
            "group": "posCtrlIndi",
            "name": "posRef_y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "posRef_z": {
            "ident": 382,
            "group": "posCtrlIndi",
            "name": "posRef_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "velS_x": {
            "ident": 383,
            "group": "posCtrlIndi",
            "name": "velS_x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "velS_y": {
            "ident": 384,
            "group": "posCtrlIndi",
            "name": "velS_y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "velS_z": {
            "ident": 385,
            "group": "posCtrlIndi",
            "name": "velS_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "velRef_x": {
            "ident": 386,
            "group": "posCtrlIndi",
            "name": "velRef_x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "velRef_y": {
            "ident": 387,
            "group": "posCtrlIndi",
            "name": "velRef_y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "velRef_z": {
            "ident": 388,
            "group": "posCtrlIndi",
            "name": "velRef_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angS_roll": {
            "ident": 389,
            "group": "posCtrlIndi",
            "name": "angS_roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angS_pitch": {
            "ident": 390,
            "group": "posCtrlIndi",
            "name": "angS_pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angS_yaw": {
            "ident": 391,
            "group": "posCtrlIndi",
            "name": "angS_yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angF_roll": {
            "ident": 392,
            "group": "posCtrlIndi",
            "name": "angF_roll",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angF_pitch": {
            "ident": 393,
            "group": "posCtrlIndi",
            "name": "angF_pitch",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "angF_yaw": {
            "ident": 394,
            "group": "posCtrlIndi",
            "name": "angF_yaw",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accRef_x": {
            "ident": 395,
            "group": "posCtrlIndi",
            "name": "accRef_x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accRef_y": {
            "ident": 396,
            "group": "posCtrlIndi",
            "name": "accRef_y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accRef_z": {
            "ident": 397,
            "group": "posCtrlIndi",
            "name": "accRef_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accS_x": {
            "ident": 398,
            "group": "posCtrlIndi",
            "name": "accS_x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accS_y": {
            "ident": 399,
            "group": "posCtrlIndi",
            "name": "accS_y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accS_z": {
            "ident": 400,
            "group": "posCtrlIndi",
            "name": "accS_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accF_x": {
            "ident": 401,
            "group": "posCtrlIndi",
            "name": "accF_x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accF_y": {
            "ident": 402,
            "group": "posCtrlIndi",
            "name": "accF_y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accF_z": {
            "ident": 403,
            "group": "posCtrlIndi",
            "name": "accF_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accFT_x": {
            "ident": 404,
            "group": "posCtrlIndi",
            "name": "accFT_x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accFT_y": {
            "ident": 405,
            "group": "posCtrlIndi",
            "name": "accFT_y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accFT_z": {
            "ident": 406,
            "group": "posCtrlIndi",
            "name": "accFT_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accErr_x": {
            "ident": 407,
            "group": "posCtrlIndi",
            "name": "accErr_x",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accErr_y": {
            "ident": 408,
            "group": "posCtrlIndi",
            "name": "accErr_y",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "accErr_z": {
            "ident": 409,
            "group": "posCtrlIndi",
            "name": "accErr_z",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "phi_tilde": {
            "ident": 410,
            "group": "posCtrlIndi",
            "name": "phi_tilde",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "theta_tilde": {
            "ident": 411,
            "group": "posCtrlIndi",
            "name": "theta_tilde",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "T_tilde": {
            "ident": 412,
            "group": "posCtrlIndi",
            "name": "T_tilde",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "T_inner": {
            "ident": 413,
            "group": "posCtrlIndi",
            "name": "T_inner",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "T_inner_f": {
            "ident": 414,
            "group": "posCtrlIndi",
            "name": "T_inner_f",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "T_incremented": {
            "ident": 415,
            "group": "posCtrlIndi",
            "name": "T_incremented",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cmd_phi": {
            "ident": 416,
            "group": "posCtrlIndi",
            "name": "cmd_phi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "cmd_theta": {
            "ident": 417,
            "group": "posCtrlIndi",
            "name": "cmd_theta",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "posCtl": {
        "targetVX": {
            "ident": 418,
            "group": "posCtl",
            "name": "targetVX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "targetVY": {
            "ident": 419,
            "group": "posCtl",
            "name": "targetVY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "targetVZ": {
            "ident": 420,
            "group": "posCtl",
            "name": "targetVZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "targetX": {
            "ident": 421,
            "group": "posCtl",
            "name": "targetX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "targetY": {
            "ident": 422,
            "group": "posCtl",
            "name": "targetY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "targetZ": {
            "ident": 423,
            "group": "posCtl",
            "name": "targetZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bodyVX": {
            "ident": 424,
            "group": "posCtl",
            "name": "bodyVX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bodyVY": {
            "ident": 425,
            "group": "posCtl",
            "name": "bodyVY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bodyX": {
            "ident": 426,
            "group": "posCtl",
            "name": "bodyX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "bodyY": {
            "ident": 427,
            "group": "posCtl",
            "name": "bodyY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Xp": {
            "ident": 428,
            "group": "posCtl",
            "name": "Xp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Xi": {
            "ident": 429,
            "group": "posCtl",
            "name": "Xi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Xd": {
            "ident": 430,
            "group": "posCtl",
            "name": "Xd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Xff": {
            "ident": 431,
            "group": "posCtl",
            "name": "Xff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Yp": {
            "ident": 432,
            "group": "posCtl",
            "name": "Yp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Yi": {
            "ident": 433,
            "group": "posCtl",
            "name": "Yi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Yd": {
            "ident": 434,
            "group": "posCtl",
            "name": "Yd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Yff": {
            "ident": 435,
            "group": "posCtl",
            "name": "Yff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Zp": {
            "ident": 436,
            "group": "posCtl",
            "name": "Zp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Zi": {
            "ident": 437,
            "group": "posCtl",
            "name": "Zi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Zd": {
            "ident": 438,
            "group": "posCtl",
            "name": "Zd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "Zff": {
            "ident": 439,
            "group": "posCtl",
            "name": "Zff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VXp": {
            "ident": 440,
            "group": "posCtl",
            "name": "VXp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VXi": {
            "ident": 441,
            "group": "posCtl",
            "name": "VXi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VXd": {
            "ident": 442,
            "group": "posCtl",
            "name": "VXd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VXff": {
            "ident": 443,
            "group": "posCtl",
            "name": "VXff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VYp": {
            "ident": 444,
            "group": "posCtl",
            "name": "VYp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VYi": {
            "ident": 445,
            "group": "posCtl",
            "name": "VYi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VYd": {
            "ident": 446,
            "group": "posCtl",
            "name": "VYd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VYff": {
            "ident": 447,
            "group": "posCtl",
            "name": "VYff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VZp": {
            "ident": 448,
            "group": "posCtl",
            "name": "VZp",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VZi": {
            "ident": 449,
            "group": "posCtl",
            "name": "VZi",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VZd": {
            "ident": 450,
            "group": "posCtl",
            "name": "VZd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "VZff": {
            "ident": 451,
            "group": "posCtl",
            "name": "VZff",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "kalman": {
        "stateX": {
            "ident": 452,
            "group": "kalman",
            "name": "stateX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "stateY": {
            "ident": 453,
            "group": "kalman",
            "name": "stateY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "stateZ": {
            "ident": 454,
            "group": "kalman",
            "name": "stateZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "statePX": {
            "ident": 455,
            "group": "kalman",
            "name": "statePX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "statePY": {
            "ident": 456,
            "group": "kalman",
            "name": "statePY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "statePZ": {
            "ident": 457,
            "group": "kalman",
            "name": "statePZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "stateD0": {
            "ident": 458,
            "group": "kalman",
            "name": "stateD0",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "stateD1": {
            "ident": 459,
            "group": "kalman",
            "name": "stateD1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "stateD2": {
            "ident": 460,
            "group": "kalman",
            "name": "stateD2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "varX": {
            "ident": 461,
            "group": "kalman",
            "name": "varX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "varY": {
            "ident": 462,
            "group": "kalman",
            "name": "varY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "varZ": {
            "ident": 463,
            "group": "kalman",
            "name": "varZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "varPX": {
            "ident": 464,
            "group": "kalman",
            "name": "varPX",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "varPY": {
            "ident": 465,
            "group": "kalman",
            "name": "varPY",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "varPZ": {
            "ident": 466,
            "group": "kalman",
            "name": "varPZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "varD0": {
            "ident": 467,
            "group": "kalman",
            "name": "varD0",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "varD1": {
            "ident": 468,
            "group": "kalman",
            "name": "varD1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "varD2": {
            "ident": 469,
            "group": "kalman",
            "name": "varD2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "q0": {
            "ident": 470,
            "group": "kalman",
            "name": "q0",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "q1": {
            "ident": 471,
            "group": "kalman",
            "name": "q1",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "q2": {
            "ident": 472,
            "group": "kalman",
            "name": "q2",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "q3": {
            "ident": 473,
            "group": "kalman",
            "name": "q3",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rtUpdate": {
            "ident": 474,
            "group": "kalman",
            "name": "rtUpdate",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rtPred": {
            "ident": 475,
            "group": "kalman",
            "name": "rtPred",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rtFinal": {
            "ident": 476,
            "group": "kalman",
            "name": "rtFinal",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "outlierf": {
        "lhWin": {
            "ident": 477,
            "group": "outlierf",
            "name": "lhWin",
            "ctype": "int32_t",
            "pytype": "<i",
            "access": 0
        }
    },
    "estimator": {
        "rtApnd": {
            "ident": 478,
            "group": "estimator",
            "name": "rtApnd",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "rtRej": {
            "ident": 479,
            "group": "estimator",
            "name": "rtRej",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "posEstAlt": {
        "estimatedZ": {
            "ident": 480,
            "group": "posEstAlt",
            "name": "estimatedZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "estVZ": {
            "ident": 481,
            "group": "posEstAlt",
            "name": "estVZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        },
        "velocityZ": {
            "ident": 482,
            "group": "posEstAlt",
            "name": "velocityZ",
            "ctype": "float",
            "pytype": "<f",
            "access": 0
        }
    },
    "DTR_P2P": {
        "rx_state": {
            "ident": 483,
            "group": "DTR_P2P",
            "name": "rx_state",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        },
        "tx_state": {
            "ident": 484,
            "group": "DTR_P2P",
            "name": "tx_state",
            "ctype": "uint8_t",
            "pytype": "<B",
            "access": 0
        }
    }
}