import { CRTPPacket, CRTPPort, ERR_MAP, Toc, TocFetcher, unpackValue } from "./LCExtra";

// Channels used for the logging port
//const CHAN_TOC = 0
export const CHAN_SETTINGS = 1
export const CHAN_LOGDATA = 2


// Commands used when accessing the Log configurations
const CMD_CREATE_BLOCK = 0
const CMD_APPEND_BLOCK = 1
const CMD_DELETE_BLOCK = 2
const CMD_START_LOGGING = 3
const CMD_STOP_LOGGING = 4
export const CMD_RESET_LOGGING = 5
const CMD_CREATE_BLOCK_V2 = 6
const CMD_APPEND_BLOCK_V2 = 7

export class Log {
    
    static MAX_BLOCKS = 16
    static MAX_VARIABLES = 128


    constructor(crazyflie = null) {
        this.cf = crazyflie;
        this.log_blocks = [];
        this._config_id_counter = 1;
        this._useV2 = false;

        this.toc = null;
        this._toc_cache = null;

        this.variables = []
        this.default_fetch_as = []
    }

    add_variable = (name, fetch_as = null) => {
        /**
         * Add a new variable to the configuration.
         *
         * name - Complete name of the variable in the form group.name
         * fetch_as - String representation of the type the variable should be
         *           fetched as (i.e uint8_t, float, FP16, etc)
         *
         * If no fetch_as type is supplied, then the stored as type will be used
         * (i.e the type of the fetched variable is the same as it's stored in the
         * Crazyflie).
         */
        if (fetch_as) {
            this.variables.push(new LogVariable(name, fetch_as));
        } else {
            // We cannot determine the default type until we have connected. So
            // save the name and we will add these once we are connected.
            this.default_fetch_as.push(name);
        }
    }
    
    add_config = async (logconf) => {

        if (!this.cf.link) {
            console.error('Cannot add configs without being connected to a Crazyflie!');
            return;
        }

        // If the log configuration contains variables that we added without
        // type (i.e we want the stored as type for fetching as well) then
        // resolve this now and add them to the block again.
        for (const name of logconf.defaultfetch_as) {
            const varElement = this.toc.getElementByCompleteName(name);
            if (!varElement) {
                console.warn(`${name} not in TOC, this block cannot be used!`);
                logconf.valid = false;
                throw new Error(`Variable ${name} not in TOC`);
            }
            // Now that we know what type this variable has, add it to the log
            // config again with the correct type
            logconf.addVariable(name, varElement.ctype);
        }

        // Now check that all the added variables are in the TOC and that
        // the total size constraint of a data packet with logging data is
        // not exceeded
        let size = 0;
        for (const varElement of logconf.variables) {
            size += LogTocElement.getSizeFromId(varElement.fetch_as);
            // Check that we are able to find the variable in the TOC so
            // we can return error already now and not when the config is sent
            if (varElement.isTocVariable()) {
                if (this.toc.getElementByCompleteName(varElement.name) === null) {
                    console.warn(`Log: ${varElement.name} not in TOC, this block cannot be used!`);
                    logconf.valid = false;
                    throw new Error(`Variable ${varElement.name} not in TOC`);
                }
            }
        }

        if (size <= LogConfig.MAX_LEN && logconf.period > 0 && logconf.period < 0xFF) {
            logconf.valid = true;
            logconf.cf = this.cf;
            logconf.id = this._config_id_counter;
            logconf.useV2 = this._useV2;
            this._config_id_counter = (this._config_id_counter + 1) % 255;
            this.log_blocks.push(logconf);
        } else {
            logconf.valid = false;
            throw new Error('The log configuration is too large or has an invalid parameter');
        }
    }

    refresh_toc = async (protocolVersion, toc_cache) => {

        this._useV2 = protocolVersion >= 4;
        this._toc_cache = toc_cache;

        let pk = new CRTPPacket();
        pk.set_header(CRTPPort.LOGGING, CHAN_SETTINGS)
        pk.data = new Uint8Array([CMD_RESET_LOGGING]);
        let inPacket = null;
        inPacket = await this.cf.sendPacketAndWaitForResponse({
            pk: pk,
            port: CRTPPort.LOGGING,
            channel: CHAN_SETTINGS,
            timeout: 3000,
            resend: true
        });

        await this.handlePacket(inPacket);
    }

    _find_block = (id) => {
        for (let block of this.log_blocks) {
            if (block.id === id) {
                return block;
            }
        }
        return null;
    }

    handlePacket = async (inPacket) => {

        const cmd = inPacket.data[0]
        const channel = inPacket.channel
        const payload = inPacket.data.slice(1)
        //console.log("cmd: ", cmd)
        if (channel === CHAN_SETTINGS) {
            const id = payload[0]
            const error_status = payload[1]
            const block = this._find_block(id)
            //console.log("block: ", block)

            if (cmd === CMD_RESET_LOGGING) {

                this.toc = new Toc();
                let tocFetcher = new TocFetcher(this.cf, CRTPPort.LOGGING, this.toc, this._toc_cache, LogTocElement);
    
                await tocFetcher.start();

                return {};
    
            } else if ((cmd === CMD_CREATE_BLOCK) || (cmd === CMD_CREATE_BLOCK_V2)) {
                
                // if block is not null
                if (block) {
                    if ((error_status === 0) || (error_status === ERR_MAP.EEXIST)) {
                        if (!block.added) {
                           console.log('Have successfully added id=%d', id)
                           return {id: id};
                        }
                    } else {
                        console.error("error adding a block");
                        return {};
                    }
                } else {
                    console.error('No LogEntry to assign block to !!!')
                    return {};
                }
            } else if (cmd === CMD_START_LOGGING) {

                if (error_status === 0x00) {
                    console.log('Have successfully started logging for id=%d',id)
                    if (block) {
                        block.started = true
                    }
                } else {
                    const msg = ERR_MAP[error_status]
                    console.error('Error %d when starting id=%d (%s)',
                                    error_status, id, msg)
                    if (block) {
                        block.err_no = error_status
                    }
                }
                return {};

            } else if (cmd === CMD_STOP_LOGGING) {
                if (error_status === 0x00) {
                    console.log('Have successfully stopped logging for id=%d',id);
                    if (block) {
                        block.started = false
                    }
                }
                return {};

            } else if (cmd === CMD_DELETE_BLOCK) {

                return {};
            }

        } else if (channel === CHAN_LOGDATA) {
            const id = inPacket.data[0];
            const block = this._find_block(id);
            const timestamps = [
                inPacket.data[1],
                inPacket.data[2],
                inPacket.data[3]
            ];
            const timestamp = (
                timestamps[0] |
                (timestamps[1] << 8) |
                (timestamps[2] << 16)
            );
            const logdata = inPacket.data.slice(4);
            //console.log('got something')
            if (block !== null) {
                block.unpackLogData(logdata, timestamp);
            } else {
                console.warn(`Error no LogEntry to handle id=${id}`);
            }
            return {};
        }
    }

}


export class LogConfig {
    /**
     * Representation of one log configuration that enables logging
     * from the Crazyflie
     */

    // Maximum log payload length (4 bytes are used for block id and timestamp)
    static MAX_LEN = 26;

    constructor(name, periodInMs) {
        /**
         * Initialize the entry
         */
        //this.dataReceivedCb = new Caller();
        //this.errorCb = new Caller();
        //this.startedCb = new Caller();
        //this.addedCb = new Caller();
        this.errNo = 0;

        // These 3 variables are set by the log subsystem when the block is added
        this.id = 0;
        this.cf = null;
        this.useV2 = false;

        this.period = Math.floor(periodInMs / 10);
        this.periodInMs = periodInMs;
        this._added = false;
        this._started = false;
        this.pending = false;
        this.valid = false;
        this.variables = [];
        this.defaultfetch_as = [];
        this.name = name;
    }

    addVariable = (name, fetch_as = null) => {
        /**
         * Add a new variable to the configuration.
         *
         * name - Complete name of the variable in the form group.name
         * fetch_as - String representation of the type the variable should be
         *           fetched as (i.e uint8_t, float, FP16, etc)
         *
         * If no fetch_as type is supplied, then the stored as type will be used
         * (i.e the type of the fetched variable is the same as it's stored in the
         * Crazyflie).
         */
        if (fetch_as) {
            this.variables.push(new LogVariable(name, fetch_as));
        } else {
            // We cannot determine the default type until we have connected. So
            // save the name and we will add these once we are connected.
            this.defaultfetch_as.push(name);
        }
    }

    addMemory = (name, fetch_as, storedAs, address) => {
        /**
         * Add a raw memory position to log.
         *
         * name - Arbitrary name of the variable
         * fetch_as - String representation of the type of the data the memory
         *           should be fetched as (i.e uint8_t, float, FP16)
         * storedAs - String representation of the type the data is stored as
         *            in the Crazyflie
         * address - The address of the data
         */
        this.variables.push(new LogVariable(name, fetch_as, LogVariable.MEM_TYPE, storedAs, address));
    }

    _setAdded = (added) => {
        if (added !== this._added) {
            //this.addedCb.call(this, added);

        }
        this._added = added;
    }

    _getAdded = () => {
        return this._added;
    }

    _setStarted = (started) => {
        if (started !== this._started) {
            //this.startedCb.call(this, started);

        }
        this._started = started;
    }

    _getStarted = () => {
        return this._started;
    }

    get added() {
        return this._getAdded();
    }

    set added(value) {
        this._setAdded(value);
    }

    get started() {
        return this._getStarted();
    }

    set started(value) {
        this._setStarted(value);
    }

    _cmdCreateBlock = () => {
        return this.useV2 ? CMD_CREATE_BLOCK_V2 : CMD_CREATE_BLOCK;
    }

    _cmdAppendBlock = () => {
        return this.useV2 ? CMD_APPEND_BLOCK_V2 : CMD_APPEND_BLOCK;
    }

    _setupLogElements = (pk, nextToAdd) => {

        let i = nextToAdd;
        for (i = nextToAdd; i < this.variables.length; i++) {
            const varObj = this.variables[i];
            if (!varObj.isTocVariable()) {  // Memory location
                console.debug('Logging to raw memory %d, 0x%04X', varObj.getStorageAndFetchByte(), varObj.address);
                //pk.data.push(varObj.getStorageAndFetchByte());
                //pk.data.push(varObj.address);
                let newData = new Uint8Array([varObj.getStorageAndFetchByte(), varObj.address]);
                pk.data = new Uint8Array([...pk.data, ...newData]);
            } else {  // Item in TOC
                const elementId = this.cf.log.toc.getElementId(varObj.name);
                console.debug('Adding %s with id=%d and type=0x%02X', varObj.name, elementId, varObj.getStorageAndFetchByte());
                let tempData = [varObj.getStorageAndFetchByte()]
                //pk.data.push(varObj.getStorageAndFetchByte());
                if (this.useV2) {
                    const sizeToAdd = 2;
                    if (pk.available_data_size() >= sizeToAdd) {
                        //pk.data.push(elementId & 0x0ff);
                        //pk.data.push((elementId >> 8) & 0x0ff);
                        tempData.push(elementId & 0x0ff);
                        tempData.push((elementId >> 8) & 0x0ff);
                        // update pk.data with tempData
                        pk.data = new Uint8Array([...pk.data, ...tempData]);
                    } else {
                        // update pk.data with tempData
                        pk.data = new Uint8Array([...pk.data, ...tempData]);
                        // Packet is full
                        return [false, i];
                    }
                } else {
                    //pk.data.push(elementId);
                    tempData.push(elementId);
                    // update pk.data with tempData
                    pk.data = new Uint8Array([...pk.data, ...tempData]);
                }
            }
        }
        return [true, i];
    }

    create = async() => {
        /**
         * Save the log configuration in the Crazyflie
         */
        let command = this._cmdCreateBlock();
        let nextToAdd = 0;
        let isDone = false;

        let numVariables = 0;
        let pending = 0;
        for (const block of this.cf.log.log_blocks) {
            if (block.pending || block.added || block.started) {
                pending += 1;
                numVariables += block.variables.length;
            }
        }

        if (pending < Log.MAX_BLOCKS) {
            // The Crazyflie firmware can only handle 128 variables before
            // erroring out with ENOMEM.
            if (numVariables + this.variables.length > Log.MAX_VARIABLES) {
                throw new Error(`Adding this configuration would exceed max number of variables (${Log.MAX_VARIABLES})`);
            }
        } else {
            throw new Error(`Configuration has max number of blocks (${Log.MAX_BLOCKS})`);
        }

        this.pending += 1;
        while (!isDone) {
            const pk = new CRTPPacket();
            pk.set_header(5, CHAN_SETTINGS);
            pk.data = [command, this.id];
            [isDone, nextToAdd] = this._setupLogElements(pk, nextToAdd);
            pk.data = new Uint8Array(pk.data);

            //console.log(`Adding/appending log block id ${this.id}`);
            //this.cf.sendPacket(pk, { expectedReply: [command, this.id] });
            let inPacket = await this.cf.sendPacketAndWaitForResponse({
                pk: pk,
                port: 5,
                channel: CHAN_SETTINGS,
                timeout: 500,
                resend: true
            });
            //console.log(inPacket);

            const resp = await this.cf.log.handlePacket(inPacket);
            //console.log("resp: ", resp)
            // if length of resp keys is not 0
            if (Object.keys(resp).length !== 0) {
                const block = this.cf.log._find_block(resp.id)
                //console.log('start block', block)
                //console.log(resp.id, block.period)
                let pkB = new CRTPPacket()
                pkB.set_header(5, CHAN_SETTINGS)
                pkB.data = new Uint8Array([CMD_START_LOGGING, resp.id, block.period]);
                //self.cf.send_packet(pk, expected_reply=(
                //    CMD_START_LOGGING, id))
                let inPacketB = null;
                while ((inPacketB === null) || (inPacketB.data[0] !== CMD_START_LOGGING)) {
                    inPacketB = await this.cf.sendPacketAndWaitForResponse({
                        pk: pkB,
                        port: 5,
                        channel: CHAN_SETTINGS,
                        timeout: 500,
                        resend: true
                    });
                }
                await this.cf.log.handlePacket(inPacketB);

                block.added = true
                block.pending = false
            }
            
            // Use append if we have to add more variables
            command = this._cmdAppendBlock();
        }
    }

    start = async () => {
        /**
         * Start the logging for this entry
         */
        //console.log(this.cf.link)
        if (this.cf.link !== null) {
            if (!this._added) {
                await this.create();
                console.log('First time block is started, add block');
            } else {
                console.debug(`Block already registered, starting logging for id=${this.id}`);
                const pk = new CRTPPacket();
                pk.set_header(5, CHAN_SETTINGS);
                pk.data = new Uint8Array([CMD_START_LOGGING, this.id, this.period]);
                //this.cf.sendPacket(pk, { expectedReply: [CMD_START_LOGGING, this.id] });
                let inPacket = await this.cf.sendPacketAndWaitForResponse({
                    pk: pk,
                    port: 5,
                    channel: CHAN_SETTINGS,
                    timeout: 500,
                    resend: true
                });

                await this.cf.log.handlePacket(inPacket);

            }
        }
    }

    stop = async () => {
        /**
         * Stop the logging for this entry
         */
        if (this.cf.link !== null) {
            if (this.id === null) {
                console.warn('Stopping block, but no block registered');
            } else {
                console.log(`Sending stop logging for block id=${this.id}`);
                const pk = new CRTPPacket();
                pk.set_header(5, CHAN_SETTINGS);
                pk.data = new Uint8Array([CMD_STOP_LOGGING, this.id]);
                //this.cf.sendPacket(pk, { expectedReply: [CMD_STOP_LOGGING, this.id] });
                //for (let i = 0; i < 3; i++) {
                const inPacket = await this.cf.sendPacketAndWaitForResponse({
                    pk: pk,
                    port: 5,
                    channel: CHAN_SETTINGS,
                    timeout: 2000,
                    resend: true
                });
                // check if inPacket not undefined
                if (inPacket !== undefined) {
                    if (inPacket.data[0] === CMD_STOP_LOGGING) {
                        await this.cf.log.handlePacket(inPacket);
                        //break;
                    }   
                }
                //}
            }
        }
    }

    delete = async () => {
        /**
         * Delete this entry in the Crazyflie
         */
        if (this.cf.link !== null) {
            if (this.id === null) {
                console.warn('Delete block, but no block registered');
            } else {
                console.debug(`LogEntry: Sending delete logging for block id=${this.id}`);
                const pk = new CRTPPacket();
                pk.set_header(5, CHAN_SETTINGS);
                pk.data = new Uint8Array([CMD_DELETE_BLOCK, this.id]);
                //this.cf.sendPacket(pk, { expectedReply: [CMD_DELETE_BLOCK, this.id] });
                const inPacket = await this.cf.sendPacketAndWaitForResponse({
                    pk: pk,
                    port: 5,
                    channel: CHAN_SETTINGS,
                    timeout: 500,
                    resend: true
                });
                await this.cf.log.handlePacket(inPacket);
            }
        }
    }

    unpackLogData = (logData, timestamp) => {
        /**
         * Unpack received logging data so it represents real values according
         * to the configuration in the entry
         */
        const retData = {};
        let dataIndex = 0;
        
        const uint8Array = new Uint8Array(logData);
        const dataView = new DataView(uint8Array.buffer);

        for (const varObj of this.variables) {
            const size = LogTocElement.getSizeFromId(varObj.fetch_as);
            const name = varObj.name;
            const unpackString = LogTocElement.getUnpackStringFromId(varObj.fetch_as);
            //const value = struct.unpack(unpackString, logData.slice(dataIndex, dataIndex + size))[0];
            const value = unpackValue(dataView, dataIndex, unpackString, true);
            dataIndex += size;
            retData[name] = value;
        }
        //console.log(retData);
        
        // for each key in retData, remove property name up to the decimal point and
        // ref as the this.cf. variable name 
        for (const key in retData) {
            const ref = key.split('.')[1];
            //retData[ref] = retData[key];
            this.cf[ref] = retData[key];

        }

        this.calculateAccelerometerReadings();

    }

    // Convert degrees to radians
    toRadians(degrees) {
        return degrees * (Math.PI / 180);
    }

    // Calculate accelerometer readings based on roll and pitch angles
    calculateAccelerometerReadings() {
        const rollRad = this.toRadians(this.cf.roll);
        const pitchRad = this.toRadians(this.cf.pitch);

        const ax = this.cf.gravity * Math.cos(pitchRad) * Math.sin(rollRad);
        const ay = this.cf.gravity * Math.sin(pitchRad);
        const az = this.cf.gravity * Math.cos(pitchRad) * Math.cos(rollRad);

        // Convert accelerometer readings to units of 'g'
        const ax_g = ax / this.cf.gravity;
        const ay_g = ay / this.cf.gravity;
        const az_g = az / this.cf.gravity;

        this.cf.accx = ax_g;
        this.cf.accy = ay_g;
        this.cf.accz = az_g;
    }


}




export class LogVariable {
    /**
     * A logging variable
     */
    static TOC_TYPE = 0;
    static MEM_TYPE = 1;

    constructor(name = '', fetch_as = 'uint8_t', varType = LogVariable.TOC_TYPE, storedAs = '', address = 0) {
        this.name = name;
        this.fetch_as = LogTocElement.getIdFromCString(fetch_as);
        if (storedAs.length === 0) {
            this.stored_as = this.fetch_as;
        } else {
            this.stored_as = LogTocElement.getIdFromCString(storedAs);
        }
        this.address = address;
        this.type = varType;
        this.stored_as_string = storedAs;
        this.fetch_as_string = fetch_as;
    }

    isTocVariable() {
        /**
         * Return true if the variable should be in the TOC, false if raw memory variable
         */
        return this.type === LogVariable.TOC_TYPE;
    }

    getStorageAndFetchByte() {
        /**
         * Return what the variable is stored as and fetched as
         */
        return (this.fetch_as | (this.stored_as << 4));
    }

    toString() {
        return `LogVariable: name=${this.name}, store=${LogTocElement.getCStringFromId(this.stored_as)}, fetch=${LogTocElement.getCStringFromId(this.fetch_as)}`;
    }
}


export class LogTocElement {
    /**
     * An element in the Log TOC.
     */
    static types = {
        0x01: ['uint8_t', '<B', 1],
        0x02: ['uint16_t', '<H', 2],
        0x03: ['uint32_t', '<L', 4],
        0x04: ['int8_t', '<b', 1],
        0x05: ['int16_t', '<h', 2],
        0x06: ['int32_t', '<i', 4],
        0x08: ['FP16', '<e', 2],
        0x07: ['float', '<f', 4]
    };

    static getIdFromCString(name) {
        /**
         * Return variable type id given the C-storage name
         */
        for (const key of Object.keys(LogTocElement.types)) {
            if (LogTocElement.types[key][0] === name) {
                return parseInt(key);
            }
        }
        throw new Error(`Type [${name}] not found in LogTocElement.types!`);
    }

    static getCStringFromId(ident) {
        /**
         * Return the C-storage name given the variable type id
         */
        if (LogTocElement.types[ident]) {
            return LogTocElement.types[ident][0];
        } else {
            throw new Error(`Type [${ident}] not found in LogTocElement.types!`);
        }
    }

    static getSizeFromId(ident) {
        /**
         * Return the size in bytes given the variable type id
         */
        if (LogTocElement.types[ident]) {
            return LogTocElement.types[ident][2];
        } else {
            throw new Error(`Type [${ident}] not found in LogTocElement.types!`);
        }
    }

    static getUnpackStringFromId(ident) {
        /**
         * Return the Python unpack string given the variable type id
         */
        if (LogTocElement.types[ident]) {
            return LogTocElement.types[ident][1];
        } else {
            throw new Error(`Type [${ident}] not found in LogTocElement.types!`);
        }
    }

    constructor(ident = 0, data = null) {
        /**
         * TocElement creator. Data is the binary payload of the element.
         */
        this.ident = ident;

        if (data) {
            const naming = data.slice(1);
            const zt = new Uint8Array([0]);
            const ztIndex = naming.indexOf(zt[0]);

            this.group = new TextDecoder('ISO-8859-1').decode(naming.slice(0, ztIndex));
            this.name = new TextDecoder('ISO-8859-1').decode(naming.slice(ztIndex + 1, -1));

            this.ctype = LogTocElement.getCStringFromId(data[0]);
            this.pytype = LogTocElement.getUnpackStringFromId(data[0]);

            this.access = data[0] & 0x10;
        }
    }
}

